import React from 'react'
import { IconTriangle } from '../../icons/IconTriangle'
import { IIconProps } from '../../../interfaces/IconProps.interface'

export interface ITriangleButtonProps extends React.HTMLAttributes<HTMLElement>, IIconProps {
  position?: 'left' | 'top' | 'right' | 'down'
}

export const TriangleButton = ({ color, position = 'down' }: ITriangleButtonProps) => {
  return (
    <div
      className={`relative w-[8px] h-[4px]         
        ${position == 'left' && 'rotate-90'}
        ${position == 'top' && 'rotate-180'}
        ${position == 'right' && '-rotate-90'}
      `}
    >
      <span className='block absolute inset-0 m-auto'>
        <IconTriangle color={color} />
      </span>
    </div>
  )
}
