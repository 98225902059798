import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ILiveSearchFilters } from '../interfaces/LiveSearchFilters.interface'
import { RootState } from '../../../../store/Store'

type LiveSearchState = {
  isApplyFilters: boolean
  filters: ILiveSearchFilters
  type: 'all' | 'paid' | 'organic'
  counter: {
    paid: number
    organic: number
  }
}

const liveSearchSlice = createSlice({
  name: 'live-search',
  initialState: {
    isApplyFilters: false,
    filters: { country: undefined, device: undefined, searchEngine: undefined, keyword: undefined },
    type: 'all',
    counter: {
      paid: 0,
      organic: 0,
    },
  } as LiveSearchState,
  reducers: {
    setLiveSearchApplyFilters: (state: LiveSearchState, { payload }: PayloadAction<boolean>) => {
      state.isApplyFilters = payload
    },
    setLiveSearchFilter: (
      state: LiveSearchState,
      { payload }: PayloadAction<ILiveSearchFilters>,
    ) => {
      state.filters = { ...state.filters, ...payload }
    },
    setLiveSearchType: (
      state: LiveSearchState,
      { payload }: PayloadAction<'all' | 'paid' | 'organic'>,
    ) => {
      state.type = payload
    },
  },
})

export const { setLiveSearchApplyFilters, setLiveSearchFilter, setLiveSearchType } =
  liveSearchSlice.actions

export const selectLiveSearchApplyFilters = (state: RootState) =>
  state.root.liveSearch.isApplyFilters
export const selectLiveSearchFilters = (state: RootState) => state.root.liveSearch.filters
export const selectLiveSearchType = (state: RootState) => state.root.liveSearch.type

export default liveSearchSlice.reducer
