import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconSpy = ({ color = '#A059FF', className }: IIconProps) => {
  return (
    <svg
      className={`${className ? className : 'w-full'}`}
      viewBox='0 0 22 16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.502 11.3524C16.8672 11.171 14.4413 10.4909 11.6075 11.4884C11.5169 11.511 11.4261 11.5338 11.3355 11.5338H10.2019C10.1113 11.5338 10.0205 11.5112 9.92992 11.4884C7.11865 10.4683 4.67021 11.1484 4.03542 11.3524C3.9448 11.375 3.89941 11.443 3.89941 11.5338V12.3046C3.89941 12.3952 3.9448 12.4634 4.03542 12.486C4.0808 12.5086 4.10342 12.5086 4.1488 12.5314C4.23942 12.554 4.28481 12.622 4.28481 12.7128C4.30742 15.3653 5.21437 16 7.05069 16C8.9098 16 9.74859 15.2745 9.95259 14.4131C10.1114 13.8237 10.1794 13.2115 10.2246 12.8941C10.2472 12.7581 10.3606 12.6447 10.5194 12.6447H11.0408C11.1768 12.6447 11.3128 12.7581 11.3355 12.8941C11.3809 13.2341 11.4715 13.8463 11.6075 14.4131C11.8343 15.2746 12.6505 16 14.5095 16C16.3458 16 17.23 15.3652 17.2753 12.7128C17.2753 12.6221 17.3207 12.554 17.4113 12.5314C17.4567 12.5088 17.4793 12.5088 17.5247 12.486C17.5927 12.4634 17.6607 12.3726 17.6607 12.3046V11.5565C17.6381 11.4432 17.5701 11.3752 17.5021 11.3524L17.502 11.3524Z'
        fill={color}
      />
      <path
        d='M20.3587 6.75015H17.933L17.1394 2.10256C16.89 0.674237 15.5071 -0.255158 14.1015 0.0622377L12.6959 0.379626C11.4264 0.651631 10.1114 0.651631 8.84187 0.379626L7.4363 0.0622377C6.03073 -0.25515 4.64779 0.69703 4.39841 2.10256L3.60483 6.75015H1.17894C0.521558 6.75015 0 7.27154 0 7.92909C0 8.58647 0.521396 9.10803 1.17894 9.10803H20.3361C20.9934 9.10803 21.515 8.58663 21.515 7.92909C21.5376 7.29429 21.0162 6.75015 20.3587 6.75015Z'
        fill={color}
      />
    </svg>
  )
}
