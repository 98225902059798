import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconCrossBold = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.6543 1.40634C7.9472 1.11344 7.9472 0.63857 7.6543 0.345677C7.36141 0.0527838 6.88654 0.0527838 6.59364 0.345677L3.99997 2.93935L1.40631 0.345677C1.11341 0.0527838 0.63854 0.0527838 0.345646 0.345677C0.0527533 0.63857 0.0527533 1.11344 0.345646 1.40634L2.93931 4.00001L0.345646 6.59367C0.0527533 6.88657 0.0527533 7.36144 0.345646 7.65433C0.63854 7.94723 1.11341 7.94723 1.40631 7.65433L3.99997 5.06067L6.59364 7.65433C6.88654 7.94723 7.36141 7.94723 7.6543 7.65433C7.9472 7.36144 7.9472 6.88657 7.6543 6.59367L5.06064 4.00001L7.6543 1.40634Z'
        fill={color}
      />
    </svg>
  )
}
