import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconPc = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.33301 13H10.6663M7.99967 10.3333V13M4.53301 10.3333H11.4663C12.5865 10.3333 13.1465 10.3333 13.5743 10.1153C13.9507 9.9236 14.2566 9.61767 14.4483 9.24133C14.6663 8.81347 14.6663 8.25347 14.6663 7.13333V4.2C14.6663 3.07989 14.6663 2.51984 14.4483 2.09202C14.2566 1.71569 13.9507 1.40973 13.5743 1.21799C13.1465 1 12.5865 1 11.4663 1H4.53301C3.4129 1 2.85285 1 2.42503 1.21799C2.0487 1.40973 1.74274 1.71569 1.55099 2.09202C1.33301 2.51984 1.33301 3.07989 1.33301 4.2V7.13333C1.33301 8.25347 1.33301 8.81347 1.55099 9.24133C1.74274 9.61767 2.0487 9.9236 2.42503 10.1153C2.85285 10.3333 3.4129 10.3333 4.53301 10.3333Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
