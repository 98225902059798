import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { IOverviewStatsModel } from './models/OverviewStats.model'
import { IStatsParams } from './interfaces/StatsParams.interface'
import { IVisibilityParams } from './interfaces/VisibilityParams.interface'
import { IVisibilityByCountryParams } from './interfaces/VisibilityByCountryParams.interface'
import { IVisibilityByCountryModel } from './models/VisibilityByCountry.model'
import { SeriesColumnOptions } from 'highcharts'
import { IStatsChart } from './interfaces/StatsChart.interface'

export const overviewApi = createApi({
  reducerPath: 'overview/api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/overview/',
  }),
  endpoints: (build) => ({
    getStats: build.query<IStatsChart | undefined, IStatsParams>({
      query: (params: IStatsParams) => ({
        url: 'stats',
        params,
      }),
      transformResponse: (
        response: ICommonResponse<IOverviewStatsModel>,
      ): IStatsChart | undefined => {
        if (!response.data) return undefined
        const { keywords, advertisers, ads, infringements, affiliates } = response.data
        return {
          keywords: {
            ...keywords,
            type: 'areaspline',
          },
          ads: {
            ...ads,
            type: 'areaspline',
          },
          affiliates: {
            ...affiliates,
            type: 'areaspline',
          },
          infringements: {
            ...infringements,
            type: 'areaspline',
          },
          advertisers: {
            ...advertisers,
            type: 'areaspline',
          },
        }
      },
    }),
    getVisibility: build.query<ICommonResponse<number[][]>, IVisibilityParams>({
      query: (params: IVisibilityParams) => ({
        url: 'visibility',
        params,
      }),
    }),
    getVisibilityByCountry: build.query<Array<SeriesColumnOptions>, IVisibilityByCountryParams>({
      query: (params: IVisibilityByCountryParams) => ({
        url: 'visibility-by-country',
        params,
      }),
      transformResponse: (
        response: ICommonResponse<IVisibilityByCountryModel[]>,
      ): Array<SeriesColumnOptions> => {
        return [
          {
            type: 'column',
            name: 'Browsers',
            colorByPoint: true,
            data: response.data?.map((item) => ({
              name: item.country_name,
              y: item.percent,
              drilldown: item.country_name,
            })),
          },
        ]
      },
    }),
  }),
})

export const { useGetStatsQuery, useGetVisibilityQuery, useGetVisibilityByCountryQuery } =
  overviewApi
