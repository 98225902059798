import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store/Store'
import { ICompetitorsFilter } from '../interfaces/CompetitorsFilter.interface'
import { EDateRangeType } from '../enums/DateRangeType'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import { IDateRange } from '../../../../shared/interfaces/DateRange.interface'
import { useDateRange } from '../../../../shared/hooks/UseDateRange'

type CompetitorsState = {
  filters: ICompetitorsFilter
  pagination: IPagination
}

const dateRange = useDateRange(EDateRangeType.MONTH)

const competitorsSlice = createSlice({
  name: 'competitors',
  initialState: {
    filters: {
      sites: undefined,
      dateRange,
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 1,
    },
  } as CompetitorsState,
  reducers: {
    setCompetitorsSites: (state: CompetitorsState, action: PayloadAction<string[] | undefined>) => {
      state.filters.sites = action.payload
    },
    setCompetitorsPagination: (state: CompetitorsState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload
    },
    changeCompetitorsDateRange: (state: CompetitorsState, action: PayloadAction<IDateRange>) => {
      state.filters.dateRange = action.payload
    },
  },
})

export const { setCompetitorsSites, changeCompetitorsDateRange, setCompetitorsPagination } =
  competitorsSlice.actions

export const selectCompetitorsFilter = (state: RootState) => state.root.competitors.filters
export const selectCompetitorsPagination = (state: RootState) => state.root.competitors.pagination

export default competitorsSlice.reducer
