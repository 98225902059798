import { CommonMainPage } from '../../utility/CommonMainPage'
import { PpcComplianceFilters } from './PpcComplianceFilters'
import { PpcComplianceTable } from './PpcComplianceTable'

export const PpcCompliancePage = () => {
  return (
    <CommonMainPage>
      <PpcComplianceFilters />
      <PpcComplianceTable />
    </CommonMainPage>
  )
}
