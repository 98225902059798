import { ReactNode } from 'react'

export interface IBpListItemProps {
  title: string
  children: ReactNode
}

export const BpListItem = ({ title, children }: IBpListItemProps) => {
  return (
    <li className='flex justify-between text-[15px] leading-[24px] gap-[15px]'>
      <span className='text-aqua-300'>{title}</span>
      <span className='text-focus-600'>{children}</span>
    </li>
  )
}
