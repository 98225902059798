import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IDecloakParams } from './interfaces/DecloakParams.interface'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { IDecloakModel } from './models/Decloak.model'

export const featuresApi = createApi({
  reducerPath: 'features/api',
  baseQuery: fetchBaseQuery({ baseUrl: '/features/' }),
  endpoints: (build) => ({
    decloak: build.query<ICommonResponse<IDecloakModel>, IDecloakParams>({
      query: (params: IDecloakParams) => ({ url: 'decloak', params }),
    }),
  }),
})

export const { useDecloakQuery } = featuresApi
