import { ByAdvertisersGraph } from './by-advertisers/ByAdvertisersGraph'
import { KeywordsReportTable } from './report/KeywordsReportTable'

export const Keywords = () => {
  return (
    <section className='flex flex-col gap-[50px]'>
      <ByAdvertisersGraph />
      <KeywordsReportTable />
    </section>
  )
}
