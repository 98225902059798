import React, { LiHTMLAttributes, PropsWithChildren, ReactElement } from 'react'
import { IIconProps } from '../../../../../shared/interfaces/IconProps.interface'

interface IProfileDropItem extends LiHTMLAttributes<HTMLLIElement> {
  icon?: ReactElement<PropsWithChildren<IIconProps>>
}

export const ProfileDropItem = (props: IProfileDropItem) => {
  const { icon, children } = props
  return (
    <li {...props} className='flex items-center group cursor-pointer '>
      <span className='mr-[18px] group-hover:opacity-50 transition-opacity duration-150'>
        <span className='w-[15px] block'>{icon}</span>
      </span>
      <span className='text-focus text-[14px] group-hover:opacity-50 transition-opacity duration-150 truncate'>
        {children}
      </span>
    </li>
  )
}
