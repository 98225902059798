import React from 'react'
import { useDispatch } from 'react-redux'
import {
  changeCompetitorsDateRange,
  selectOverviewCompetitorsFilter,
  selectOverviewFilters,
} from '../../store/OverviewSlice'
import { IDateRange } from '../../../../../shared/interfaces/DateRange.interface'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { IOverviewFilter } from '../../interfaces/OverviewFilter.interface'
import { useGetCompetitorsChartQuery } from '../../../../../api/research/Research.api'
import { GraphReport } from '../../../../../shared/components/cards/graph-report/GraphReport'
import { ICompetitorsChartParams } from '../../../../../api/research/interfaces/CompetitorsChartParams.interface'
import { useTranslation } from 'react-i18next'

export const TopCompetitorsGraph = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { country, project } = useAppSelector<IOverviewFilter>(selectOverviewFilters)
  const filter = useAppSelector<IDateRange>(selectOverviewCompetitorsFilter)
  const { date, group: date_group } = filter

  const { data } = useGetCompetitorsChartQuery(
    {
      date_group,
      date,
      chart_site_ids: [],
      project_ids: project ? [project] : [],
      country_ids: country ? country : [],
    } as ICompetitorsChartParams,
    { skip: !project },
  )

  return (
    <GraphReport
      title={t('pages.overview.topAdvertisersByAds')}
      filter={filter}
      series={data ? data.slice(0, 5) : []}
      onChange={(e) => dispatch(changeCompetitorsDateRange(e))}
    />
  )
}
