import { Navigate, Outlet } from 'react-router-dom'
import { useCurrentUserQuery } from '../api/site/Site.api'
import { useAppSelector } from '../store/hooks/Redux'
import { Preloader } from '../shared/components/cards/preloader/Preloader'
import { selectAuthenticated } from '../store/reducers/AuthSlice'

export const MainGuard = () => {
  const { isLoading, isFetching } = useCurrentUserQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
  })

  const authenticated = useAppSelector(selectAuthenticated)
  const loading = isLoading || isFetching

  if (loading || authenticated == null) {
    return <Preloader />
  }

  return authenticated ? <Outlet /> : <Navigate to='/auth' />
}
