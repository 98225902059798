import { useAppSelector } from '../../store/hooks/Redux'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useLazyLogoutQuery } from '../../api/site/Site.api'

export const useAuth = () => {
  const [triggerLogout] = useLazyLogoutQuery()
  const navigate = useNavigate()

  const authenticated = useAppSelector((state) => state.root.auth.authenticated)

  useEffect(() => {
    if (authenticated !== null && !authenticated) {
      navigate('/auth')
    }
  }, [authenticated])

  const logout = () => {
    triggerLogout()
  }

  return { logout }
}
