import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconCountry = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 8.33337C9.1046 8.33337 10 7.43797 10 6.33337C10 5.22881 9.1046 4.33337 8 4.33337C6.8954 4.33337 6 5.22881 6 6.33337C6 7.43797 6.8954 8.33337 8 8.33337Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.99984 14.6667C9.33317 12 13.3332 10.2789 13.3332 6.66671C13.3332 3.72119 10.9454 1.33337 7.99984 1.33337C5.05432 1.33337 2.6665 3.72119 2.6665 6.66671C2.6665 10.2789 6.6665 12 7.99984 14.6667Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
