import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { IInfoModel } from './models/Info.model'
import { IEditInfoParams } from './interfaces/EditInfoParams.interface'
import { IEditEmailParams } from './interfaces/EditEmailParams.interface'
import { IEditPasswordParams } from './interfaces/EditPasswordParams.interface'
import { IEditModel } from './models/Edit.model'
import { handleCommonResponse } from '../../shared/helpers/ErrorHandlingHelplers'

export const profileApi = createApi({
  reducerPath: 'profile/api',
  tagTypes: ['User'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/profile/',
  }),
  endpoints: (build) => ({
    getProfileInfo: build.query<ICommonResponse<IInfoModel>, void>({
      query: () => ({
        url: 'info',
      }),
      providesTags: ['User'],
    }),
    editProfileInfo: build.mutation<ICommonResponse<IInfoModel>, IEditInfoParams>({
      query: ({ fio, username, company }: IEditInfoParams) => ({
        url: 'info',
        method: 'POST',
        body: (() => {
          const formData = new FormData()
          formData.append('username', `${username}`)
          formData.append('fio', `${fio}`)
          formData.append('company', `${company}`)
          return formData
        })(),
      }),
      invalidatesTags: ['User'],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled
        handleCommonResponse(data, dispatch, 'Account info changed')
      },
    }),
    editEmail: build.mutation<ICommonResponse<IEditModel>, IEditEmailParams>({
      query: ({ new_email, password }: IEditEmailParams) => ({
        url: 'change-email',
        method: 'POST',
        body: (() => {
          const formData = new FormData()
          formData.append('password', `${password}`)
          formData.append('new_email', `${new_email}`)
          return formData
        })(),
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled
        handleCommonResponse(data, dispatch, 'Email changed')
      },
    }),
    editPassword: build.mutation<ICommonResponse<IEditModel>, IEditPasswordParams>({
      query: ({ new_password, password }: IEditPasswordParams) => ({
        url: 'change-password',
        method: 'POST',
        body: (() => {
          const formData = new FormData()
          formData.append('password', `${password}`)
          formData.append('new_password', `${new_password}`)
          return formData
        })(),
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled
        handleCommonResponse(data, dispatch, 'Password changed')
      },
    }),
  }),
})

export const {
  useGetProfileInfoQuery,
  useEditProfileInfoMutation,
  useEditEmailMutation,
  useEditPasswordMutation,
} = profileApi
