import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IAdModel } from './models/Ad.model'
import { IAdsParams } from './interfaces/AdsParams.interface'
import { TTableResponse } from '../../shared/types/TableResponse.type'
import { IAffiliateModel } from './models/Affiliate.model'
import { IAffiliatesParams } from './interfaces/AffiliatesParams.interface'
import { IPublisherModel } from './models/Publisher.model'
import { IGetPublisherParams } from './interfaces/GetPublisherParams.interface'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { IEditPublisherParams } from './interfaces/EditPublisherParams.interface'
import { IAffiliatesAdsParams } from './interfaces/AffiliatesAdsParams.interface'
import { TExportAdsParams } from './types/ExportAdsParams.type'
import { ISingleAdModel } from './models/SingleAd.model'
import { TExportAffiliatesAdsParams } from './types/ExportAffiliatesAdsParams.type'
import { IEditAffiliateParams } from './interfaces/EditAffiliateParams.interface'
import queryString from 'query-string'

export const adOverviewApi = createApi({
  reducerPath: 'adOverview/api',
  tagTypes: ['Publisher', 'Affiliate'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/ad-overview/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    getAds: build.query<TTableResponse<IAdModel>, IAdsParams>({
      query: (params: IAdsParams) => ({ url: 'ads', params }),
    }),
    exportAds: build.mutation<Blob, TExportAdsParams>({
      query(params: TExportAdsParams) {
        return {
          url: 'ads',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    getAffiliatesAds: build.query<TTableResponse<ISingleAdModel>, IAffiliatesAdsParams>({
      query: (params: IAffiliatesAdsParams) => ({ url: 'affiliates-ads', params }),
    }),
    exportAffiliatesAds: build.mutation<Blob, TExportAffiliatesAdsParams>({
      query(params: TExportAffiliatesAdsParams) {
        return {
          url: 'affiliates-ads',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    getAffiliates: build.query<TTableResponse<IAffiliateModel>, IAffiliatesParams>({
      query: (params: IAffiliatesParams) => ({ url: 'affiliates', params }),
      providesTags: ['Affiliate']
    }),

    editAffiliate: build.mutation<ICommonResponse<IAffiliateModel>, IEditAffiliateParams>({
      query: ({affiliate_id, is_hidden }: IEditAffiliateParams) => ({
        url: 'hide-affiliate',
        method: 'POST',
        body: (() => {
          const formData = new FormData();
          formData.append('id', `${affiliate_id}`);
          formData.append('is_hidden', `${is_hidden}`);
          return formData;
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Affiliate'],
    }),


    getPublisher: build.query<ICommonResponse<IPublisherModel>, IGetPublisherParams>({
      query: (params: IGetPublisherParams) => ({ url: 'publisher', params }),
      providesTags: ['Publisher'],
    }),
    editPublisher: build.mutation<ICommonResponse<IPublisherModel>, IEditPublisherParams>({
      query: ({ id, name, comment,email }: IEditPublisherParams) => ({
        url: 'publisher',
        method: 'POST',
        body: (() => {
          const formData = new FormData()
          formData.append('id', `${id}`)
          formData.append('name', `${name}`)
          formData.append('comment', `${comment}`)
          formData.append('email', `${email}`)
          return formData
        })(),
      }),
      invalidatesTags: ['Publisher'],
    }),
  }),
})

export const {
  useGetAdsQuery,
  useGetAffiliatesQuery,
  useGetPublisherQuery,
  useEditPublisherMutation,
  useExportAdsMutation,
  useGetAffiliatesAdsQuery,
  useExportAffiliatesAdsMutation,
  useEditAffiliateMutation,
} = adOverviewApi;
