import { IBrandPresenceParams } from '../../../../../api/research/interfaces/BrandPresenceParams.interface'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { ISerpPresenceFilter } from '../../interfaces/SerpPresenceFilter'
import { selectSerpPresenceFilters } from '../../store/SerpPresenceSlice'
import React, { useEffect, useState } from 'react'
import { useGetBrandPresencePagesQuery } from '../../../../../api/research/Research.api'
import { IAdvertisingKeywordParams } from '../../../../../api/research/interfaces/AdvertisingKeywordParams'
import { Square } from '../../../../../shared/components/utility/square/Square'
import { ColumnsType } from 'antd/lib/table'
import { IBrandPresenceModel } from '../../../../../api/research/models/BrandPresence.model'
import { BpAnchor } from '../../../../../shared/components/text/anchor/BpAnchor'
import { IBrandPresencePagesModel } from '../../../../../api/research/models/BrandPresencePages.model'
import { BpTable } from '../../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../../shared/types/TableResponse.type'
import { IBpPaginationProps } from '../../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../../shared/hooks/UseSorter'
import { PositionCell } from '../../../../../shared/components/cells/PositionCell'
import { useTranslation } from 'react-i18next'

interface SerpPresenceExpandRowProps {
  record: IBrandPresenceModel
}

export const SerpPresenceExpandRow = ({ record }: SerpPresenceExpandRowProps) => {
  const { t } = useTranslation()
  const detailedInfoFilter = useAppSelector<ISerpPresenceFilter>(selectSerpPresenceFilters)
  const {
    project,
    country,
    engine_id,
    dateRange: date,
    no_serp_presence,
    is_seo,
    page_url,
  } = detailedInfoFilter

  const [queryParams, setQueryParams] = useState<IBrandPresenceParams>()
  const { data, isLoading, isFetching } = useGetBrandPresencePagesQuery(
    queryParams as IAdvertisingKeywordParams,
    { skip: !project || queryParams == undefined },
  )

  useEffect(() => {
    if (!project) return

    setQueryParams({
      page: 1,
      'per-page': 10,
      project_id: +project,
      country_id: country ? +country : undefined,
      engine_id: +engine_id,
      date,
      keyword: record.keyword,
      keyword_id: record.keyword_id,
      no_serp_presence,
      is_seo,
      landing: page_url,
    })
  }, [detailedInfoFilter])

  const columns: ColumnsType<IBrandPresencePagesModel> = [
    {
      title: t('table.columns.page'),
      dataIndex: 'page',
      key: 'page',
      render: (_: string, { page }: IBrandPresencePagesModel) => {
        return (
          <BpAnchor
            className='block leading-5 w-96 overflow-hidden text-ellipsis whitespace-nowrap'
            target='_blank'
            href={page}
          >
            {page.split('?')[0]}
          </BpAnchor>
        )
      },
    },
    {
      title: t('table.columns.avgPosition'),
      dataIndex: 'position',
      key: 'position',
      render: (_: string, { position, previous_position }: IBrandPresencePagesModel) => {
        const current = +position.toFixed(1)
        const previous = previous_position !== null && +previous_position.toFixed(1)

        return <PositionCell current={current} previous={previous} />
      },
      sorter: true,
    },
    {
      title: t('table.columns.landingPage'),
      dataIndex: 'link_screen_url',
      key: 'link_screen_url',
      render: (_: string, { link_screen_url }: IBrandPresencePagesModel) => (
        <BpAnchor href={link_screen_url} target='_blank'>
          {t('buttons.viewScreenshot')}
        </BpAnchor>
      ),
    },
    {
      title: t('table.columns.SERP'),
      dataIndex: 'search_screen_url',
      key: 'search_screen_url',
      render: (_: string, { search_screen_url }: IBrandPresencePagesModel) => (
        <BpAnchor href={search_screen_url} target='_blank'>
          {t('buttons.viewScreenshot')}
        </BpAnchor>
      ),
    },
    {
      title: t('table.columns.lastFound'),
      dataIndex: 'last_found',
      key: 'last_found',
      width: 150,
      sorter: true,
    },
  ]

  const handleTableChange = (
    { current, pageSize }: IBpPaginationProps,
    sorter?: SorterResult<IBrandPresencePagesModel> | SorterResult<IBrandPresencePagesModel>[],
  ) => {
    if (queryParams) {
      setQueryParams({
        ...queryParams,
        page: current,
        'per-page': pageSize,
        sort: useSorter(sorter),
      })
    }
  }

  return (
    <Square className='p-4 w-10/12 m-auto'>
      <BpTable
        className='transparent-header'
        dataSource={data?.data?.items}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<IBrandPresencePagesModel>}
        scroll={{ x: 0 }}
        hidePagination={!data?.data || data.data.total <= 10}
      ></BpTable>
    </Square>
  )
}
