import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store/Store'
import { IAdOverviewFilter } from '../interfaces/AdOverviewFilter.interface'
import { useDateRanges } from '../../../../shared/hooks/UseDateRanges'
import { IAdsParams } from '../../../../api/ad-overview/interfaces/AdsParams.interface'
import { IAffiliatesParams } from '../../../../api/ad-overview/interfaces/AffiliatesParams.interface'

export type TAdOverviewTabs = 'Ads' | 'Affiliates'

type AdOverviewState = {
  filters: IAdOverviewFilter
  adsQueryParams: IAdsParams
  affQueryParams: IAffiliatesParams
  activeTab: TAdOverviewTabs
  adsExpanded: boolean
  allowDecloak: boolean
}

const dateRanges = useDateRanges()

export const initialAdOverviewFilters = {
  project: '',
  adDomain: '',
  country: '',
  search: '',
  dateRange: dateRanges.month,
  engine: '',
  city: '',
  browser: '',
  policy: '',
  show_hidden: false,
  affiliate: '',
  is_decloak: false,
  group: false,
}

const adOverviewSlice = createSlice({
  name: 'overview',
  initialState: {
    filters: initialAdOverviewFilters,
    activeTab: 'Ads',
    adsExpanded: false,
    allowDecloak: false,
  } as AdOverviewState,
  reducers: {
    setAdOverviewFilter: (
      state: AdOverviewState,
      { payload }: PayloadAction<IAdOverviewFilter>,
    ) => {
      state.filters = { ...state.filters, ...payload }
    },
    setAdOverviewActiveTab: (
      state: AdOverviewState,
      { payload }: PayloadAction<TAdOverviewTabs>,
    ) => {
      state.activeTab = payload
    },
    setAdsQueryParams: (state: AdOverviewState, { payload }: PayloadAction<IAdsParams>) => {
      state.adsQueryParams = payload
    },
    setAffQueryParams: (state: AdOverviewState, { payload }: PayloadAction<IAffiliatesParams>) => {
      state.affQueryParams = payload
    },
    toggleAdsExpand: (state: AdOverviewState) => {
      state.adsExpanded = !state.adsExpanded
    },
    setAllowDecloak: (state: AdOverviewState, action: PayloadAction<boolean>) => {
      state.allowDecloak = action.payload
    },
  },
})

export const {
  setAdOverviewFilter,
  setAdOverviewActiveTab,
  setAdsQueryParams,
  setAffQueryParams,
  toggleAdsExpand,
  setAllowDecloak,
} = adOverviewSlice.actions

export const selectAdOverviewFilters = (state: RootState) => state.root.adOverview.filters
export const selectAdOverviewActiveTab = (state: RootState) => state.root.adOverview.activeTab
export const selectAdOverviewAdsParams = (state: RootState) => state.root.adOverview.adsQueryParams
export const selectAdOverviewAffParams = (state: RootState) => state.root.adOverview.affQueryParams
export const selectAdOverviewAdsExpanded = (state: RootState) => state.root.adOverview.adsExpanded
export const selectAllowDecloak = (state: RootState) => state.root.adOverview.allowDecloak

export default adOverviewSlice.reducer
