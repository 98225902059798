import { BaseInput, IInputProps } from '../input/BaseInput'
import { IconSearch } from '../../../icons/IconSearch'
import React, {
  ChangeEvent,
  ComponentType,
  ForwardedRef,
  forwardRef,
  useEffect,
  useState,
} from 'react'
import { IconButton } from '../../../buttons/icon-button/IconButton'
import { IconCrossBold } from '../../../icons/IconCrossBold'
import { useDebounce } from '../../../../hooks/UseDebounce'
import { IIconProps } from '../../../../interfaces/IconProps.interface'

export interface ISearchFieldProps extends IInputProps {
  Icon?: ComponentType<IIconProps>
  onClear?: () => void
}

export const SearchField = forwardRef(
  (props: ISearchFieldProps, ref: ForwardedRef<IInputProps>) => {
    const { Icon = IconSearch, onClear, value = '', onChange, ...inputProps } = props
    const { defaultValue = '' } = inputProps

    const [currentValue, setCurrentValue] = useState<string>(defaultValue as string)
    const [changeEvent, setChangeEvent] = useState<ChangeEvent<HTMLInputElement>>()
    const debounceCurrentValue = useDebounce(currentValue, 500)

    useEffect(() => {
      if (onChange && changeEvent) onChange(changeEvent)
    }, [debounceCurrentValue])

    useEffect(() => {
      setCurrentValue(`${value}`)
    }, [value])

    function handleClear() {
      setCurrentValue('')
      if (onClear) onClear()
    }

    return (
      <div className='flex items-center pl-[15px] pr-[12px]'>
        <div className='w-[16px]'>
          <Icon color='#00326D'></Icon>
        </div>
        <div className='flex-1'>
          <BaseInput
            {...inputProps}
            ref={ref}
            value={currentValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setCurrentValue(e.target.value)
              setChangeEvent(e)
            }}
          />
        </div>
        {currentValue && !inputProps.disabled && (
          <IconButton onClick={handleClear}>
            <IconCrossBold color='#6684A7' />
          </IconButton>
        )}
      </div>
    )
  },
)
