import React, { useEffect } from 'react'
import { TTableResponse } from '../../../../../shared/types/TableResponse.type'
import { BpTable } from '../../../../../shared/components/cards/table/BpTable'
import { IAffiliateModel } from '../../../../../api/ad-overview/models/Affiliate.model'
import {
  useEditAffiliateMutation,
  useGetAffiliatesQuery,
} from '../../../../../api/ad-overview/AdOverview.api'
import { IAffiliatesParams } from '../../../../../api/ad-overview/interfaces/AffiliatesParams.interface'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { IAdOverviewFilter } from '../../interfaces/AdOverviewFilter.interface'
import {
  selectAdOverviewActiveTab,
  selectAdOverviewAffParams,
  selectAdOverviewFilters,
  setAffQueryParams,
  TAdOverviewTabs,
} from '../../store/AdOverviewSlice'
import { ColumnsType } from 'antd/lib/table'
import { IBpPaginationProps } from '../../../../../shared/components/cards/pagination/BpPagination'
import { BpButton } from '../../../../../shared/components/buttons/bp-button/BpButton'
import { setModal } from '../../../../../store/reducers/AppSlice'
import { EditAffiliateModal } from './edit-affiliate-modal/EditAffiliateModal'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../../shared/hooks/UseSorter'
import { BpLink } from '../../../../../shared/components/text/link/BpLink'
import { BpTooltip } from '../../../../../shared/components/cards/tooltip/BpTooltip'
import { useLocation } from 'react-router-dom'
import { BpAnchor } from '../../../../../shared/components/text/anchor/BpAnchor'
import { useTranslation } from 'react-i18next'

export const AffiliatesReportTable = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { search: searchParams } = useLocation()
  const adOverviewFilter = useAppSelector<IAdOverviewFilter>(selectAdOverviewFilters)
  const {
    project,
    adDomain,
    search,
    country,
    browser,
    engine,
    dateRange,
    policy,
    city,
    show_hidden,
    affiliate,
  } = adOverviewFilter

  const queryParams = useAppSelector(selectAdOverviewAffParams)
  const { data, isLoading, isFetching } = useGetAffiliatesQuery(queryParams as IAffiliatesParams, {
    skip: !project || queryParams == undefined,
  })

  useEffect(() => {
    if (!project) return

    dispatch(
      setAffQueryParams({
        page: 1,
        'per-page': 10,
        project_id: +project,
        site: adDomain,
        search,
        country_id: country ? +country : undefined,
        browser_id: browser ? +browser : undefined,
        engine_id: engine ? +engine : undefined,
        date: dateRange,
        policy: policy === 'non_compliant_affiliates' ? undefined : policy,
        city_id: city ? +city : undefined,
        is_hidden: show_hidden,
        affiliate,
      }),
    )
  }, [adOverviewFilter])

  const columns: ColumnsType<IAffiliateModel> = [
    {
      title: t('table.columns.affiliateId'),
      dataIndex: 'affiliate_id',
      key: 'affiliate_id',
      render: (_: string, { affiliate_id }: IAffiliateModel) => {
        return (
          <div className='w-96 overflow-hidden text-ellipsis whitespace-nowrap'>{affiliate_id}</div>
        )
      },
      width: 250,
    },
    {
      title: t('table.columns.advertiser'),
      dataIndex: 'domain',
      key: 'domain',
      render: (_: string, { domain }: IAffiliateModel) => {
        return (
          <div>
            <BpAnchor className='flex-1' target='_blank' href={`https://${domain}`}>
              {domain}
            </BpAnchor>
          </div>
        )
      },
      width: 250,
    },
    {
      title: () => (
        <span className='flex items-center gap-[8px]'>
          {t('table.columns.adsAnalyzed')}
          <BpTooltip title={t('pages.adOverview.adsAnalyzedTooltip')} />
        </span>
      ),
      key: 'count',
      render: (_: number, { publisher_id, affiliate_id, domain, count }: IAffiliateModel) => {
        return (
          <BpLink
            to={`${publisher_id}/${affiliate_id}${searchParams}${
              !adDomain ? '&adDomain=' + domain : ''
            }`}
          >
            {count}
          </BpLink>
        )
      },
      sorter: true,
      width: 175,
      showSorterTooltip: false,
    },
    {
      title: t('table.columns.lastUpdated'),
      key: 'last_updated',
      dataIndex: 'last_updated',
      sorter: true,
      width: 200,
    },
    {
      title: t('table.columns.firstUpdated'),
      key: 'first_updated',
      dataIndex: 'first_updated',
      sorter: true,
      width: 200,
    },
    {
      title: t('table.columns.comment'),
      key: 'comment',
      dataIndex: 'comment',
      sorter: false,
      width: 250,
    },
    {
      key: 'edit',
      render: (_: IAffiliateModel, record: IAffiliateModel) => {
        return (
          <div className='flex w-full justify-end whitespace-nowrap'>
            <BpButton
              theme='outline'
              size='sm'
              onClick={() =>
                dispatch(
                  setModal({ children: <EditAffiliateModal publisherId={+record.publisher_id} /> }),
                )
              }
            >
              {t('buttons.editAffiliate')}
            </BpButton>
          </div>
        )
      },
      className: 'action',
      width: 120,
    },
  ]

  const handleTableChange = (
    { current, pageSize }: IBpPaginationProps,
    sorter?: SorterResult<IAffiliateModel> | SorterResult<IAffiliateModel>[],
  ) => {
    if (queryParams) {
      dispatch(
        setAffQueryParams({
          ...queryParams,
          page: current,
          'per-page': pageSize,
          sort: useSorter(sorter),
        }),
      )
    }
  }

  return (
    <BpTable
      dataSource={data?.data?.items}
      rowKey={(record) => record.publisher_id}
      columns={columns}
      loading={isLoading || isFetching}
      onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
      data={data as TTableResponse<IAffiliateModel>}
      rowClassName={(e) => (e.is_hidden ? 'disabled' : '')}
      scroll={{ x: '896px' }}
    ></BpTable>
  )
}
