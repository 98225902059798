import {
  useGetReportQuery,
  useUpdateStateMutation,
} from '../../../../api/language-compliance/LanguageCompliance.api'
import { Preloader } from '../../../../shared/components/cards/preloader/Preloader'
import React from 'react'
import { BpAnchor } from '../../../../shared/components/text/anchor/BpAnchor'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import {
  selectLanguageComplianceFilter,
  selectSelectedLanguageComplianceLastCheck,
  setSelectedLanguageComplianceLastCheck,
} from '../store/LanguageComplianceSlice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks/Redux'
import { ILanguageComplianceFilter } from '../interface/LanguageComplianceFilter.interface'
import { hideModal, showAlert } from '../../../../store/reducers/AppSlice'

interface LanguageCompilianceReportProps {
  id: string
  state: string
  url: string
}

export const LanguageComplianceReport = ({ id, state, url }: LanguageCompilianceReportProps) => {
  const dispatch = useDispatch()
  const selectedKeys = useAppSelector(selectSelectedLanguageComplianceLastCheck)
  const { project, country } = useAppSelector<ILanguageComplianceFilter>(
    selectLanguageComplianceFilter,
  )

  const { data, isLoading, isFetching } = useGetReportQuery({ id })

  const [updateState] = useUpdateStateMutation()

  if (isLoading || isFetching) {
    return (
      <div className='relative min-h-[200px]'>
        <Preloader />
      </div>
    )
  }

  return (
    <div className={'flex flex-col overflow-hidden pb-[32px]'}>
      <div
        className={
          'px-[32px] flex flex-col py-[10px] w-full border-b-[3px] border-b-[#00326D]/[.02]'
        }
      >
        <h3 className={'text-[20px] flex items-center'}>
          <span className={'truncate max-w-[90%] font-medium'}>Report {data.data.created_at}</span>
        </h3>
        <BpAnchor className={'text-[13px] w-fit'} href={data.data.url} target={'_blank'}>
          {data.data.url.replace(/^(http:\/\/|https:\/\/)?(www\.)?/, '')}
        </BpAnchor>
        <span className={'text-[13px] text-aqua-300'}>
          {data.data.country} | {data.data.device}
        </span>
        <div className={'flex justify-between pt-[16px]'}>
          {
            {
              last_check: (
                <BpButton
                  size={'sm'}
                  onClick={() => {
                    updateState({
                      project_id: Number(project),
                      country_id: Number(country),
                      urls: [url],
                      state: 'in_progress',
                    }).then(() => {
                      dispatch(
                        showAlert({
                          message: 'Report was moved successfully',
                          type: 'success',
                          timeout: 2000,
                        }),
                      )
                      dispatch(
                        setSelectedLanguageComplianceLastCheck(
                          selectedKeys.filter((key) => key !== url),
                        ),
                      )
                    })
                    dispatch(hideModal())
                  }}
                >
                  Move to In progress
                </BpButton>
              ),
              in_progress: (
                <BpButton
                  size={'sm'}
                  onClick={() => {
                    updateState({
                      project_id: Number(project),
                      country_id: Number(country),
                      urls: [url],
                      state: 'resolved',
                    }).then(() => {
                      dispatch(
                        showAlert({
                          message: 'Report was moved successfully',
                          type: 'success',
                          timeout: 2000,
                        }),
                      )
                      dispatch(
                        setSelectedLanguageComplianceLastCheck(
                          selectedKeys.filter((key) => key !== url),
                        ),
                      )
                    })
                    dispatch(hideModal())
                  }}
                >
                  Resolve
                </BpButton>
              ),
            }[state]
          }
          <BpAnchor
            target='_blank'
            className={'bp-btn outline sm'}
            href={data.data.link_screen_url}
          >
            Page screenshot
          </BpAnchor>
        </div>
      </div>
      <div className={'overflow-y-auto px-[32px] pt-[16px] max-h-[440px]'}>
        {data.data.items.map((row: string, index: number) => (
          <p
            className={'pb-[12px] mark-yellow'}
            key={index}
            dangerouslySetInnerHTML={{ __html: row }}
          />
        ))}
      </div>
    </div>
  )
}
