import { ReactNode } from 'react'

export interface IBpTabProps {
  title: string
  counter?: string
  panel?: ReactNode
  children?: ReactNode
}

export const BpTab = ({ children }: IBpTabProps) => {
  return <>{children}</>
}
