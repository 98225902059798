import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconArrowRight = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`${className ? className : 'w-full'}`}
      viewBox='0 0 12 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.666992 5H11.3337M11.3337 5L7.33366 1M11.3337 5L7.33366 9'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
