import { BpCard } from '../../../../shared/components/cards/card/BpCard'
import { Square } from '../../../../shared/components/utility/square/Square'
import { Controller, useForm } from 'react-hook-form'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { useEditEmailMutation } from '../../../../api/profile/Profile.api'
import { FormControls } from '../../../../shared/components/forms/form-controls/FormControls'
import { EMAIL_REGEX } from '../../../../shared/consts/RegularExpressions.const'
import { useTranslation } from 'react-i18next'

export interface IChangeEmailFormData {
  password: string
  new_email: string
}

export const ChangeEmailForm = ({ className }: { className?: string }) => {
  const { t } = useTranslation()
  const [editEmail] = useEditEmailMutation()

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
    register,
  } = useForm<IChangeEmailFormData>({ mode: 'onChange' })

  return (
    <Square className={className}>
      <form onSubmit={handleSubmit((data: IChangeEmailFormData) => editEmail(data))}>
        <BpCard
          title={t('pages.profile.changeEmail')}
          footer={<FormControls disabled={!isDirty || !isValid} onCancel={() => reset()} />}
        >
          <div className='flex flex-col gap-[16px]'>
            <Controller
              name='password'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <BpInput
                  {...field}
                  {...register('password', { required: t('fields.validate.required') })}
                  label={t('pages.profile.fields.password')}
                  type='password'
                  placeholder={t('pages.profile.fields.enterPassword')}
                  required
                  error={error?.message}
                />
              )}
            />
            <Controller
              name='new_email'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <BpInput
                  {...field}
                  {...register('new_email', {
                    required: t('fields.validate.required'),
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t('pages.profile.fields.invalidEmail'),
                    },
                  })}
                  label={t('pages.profile.fields.newEmail')}
                  type='email'
                  placeholder={t('pages.profile.fields.enterNewEmail')}
                  required
                  error={error?.message}
                />
              )}
            />
          </div>
        </BpCard>
      </form>
    </Square>
  )
}
