import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconBarChart = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.9987 2.00004H10.7987C11.9188 2.00004 12.4788 2.00004 12.9067 2.21803C13.283 2.40977 13.589 2.71573 13.7807 3.09206C13.9987 3.51988 13.9987 4.07993 13.9987 5.20004V10.8C13.9987 11.9202 13.9987 12.4802 13.7807 12.908C13.589 13.2844 13.283 13.5903 12.9067 13.782C12.4788 14 11.9188 14 10.7987 14H5.1987C4.07859 14 3.51854 14 3.09072 13.782C2.71439 13.5903 2.40843 13.2844 2.21668 12.908C1.9987 12.4802 1.9987 11.9202 1.9987 10.8V8.00004M5.33203 8.66671V11.3334M10.6654 7.33337V11.3334M7.9987 4.66671V11.3334M1.33203 3.33337L3.33203 1.33337M3.33203 1.33337L5.33203 3.33337M3.33203 1.33337V5.33337'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
