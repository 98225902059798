import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDateRanges } from '../../../../shared/hooks/UseDateRanges'
import { ILanguageComplianceFilter } from '../interface/LanguageComplianceFilter.interface'
import { RootState } from '../../../../store/Store'
import { ILanguageComplianceCounter } from '../interface/LanguageComplianceCounter.interface'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'

type LanguageComplianceState = {
  filters: ILanguageComplianceFilter
  pagination: IPagination
  selectedLastCheck: string[]
  selectedInProgress: string[]
}

const dateRanges = useDateRanges()

const languageComplianceSlice = createSlice({
  name: 'languageCompliance',
  initialState: {
    filters: {
      dateRange: dateRanges.month,
      hidden: false,
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 1,
    },
  } as LanguageComplianceState,
  reducers: {
    setLanguageComplianceFilter: (
      state: LanguageComplianceState,
      { payload }: PayloadAction<ILanguageComplianceFilter>,
    ) => {
      state.filters = { ...state.filters, ...payload }
    },
    setLanguageCompliancePagination: (
      state: LanguageComplianceState,
      action: PayloadAction<IPagination>,
    ) => {
      state.pagination = action.payload
    },
    setSelectedLanguageComplianceLastCheck: (
      state: LanguageComplianceState,
      action: PayloadAction<string[]>,
    ) => {
      state.selectedLastCheck = action.payload
    },
    setSelectedLanguageComplianceInProgress: (
      state: LanguageComplianceState,
      action: PayloadAction<string[]>,
    ) => {
      state.selectedInProgress = action.payload
    },
  },
})

export const {
  setLanguageComplianceFilter,
  setLanguageCompliancePagination,
  setSelectedLanguageComplianceLastCheck,
  setSelectedLanguageComplianceInProgress,
} = languageComplianceSlice.actions

export const selectLanguageComplianceFilter = (state: RootState) =>
  state.root.languageCompliance.filters

export const selectLanguageCompliancePagination = (state: RootState) =>
  state.root.languageCompliance.pagination

export const selectSelectedLanguageComplianceLastCheck = (state: RootState) =>
  state.root.languageCompliance.selectedLastCheck

export const selectSelectedLanguageComplianceInProgress = (state: RootState) =>
  state.root.languageCompliance.selectedInProgress

export default languageComplianceSlice.reducer
