import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconFilter = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 18 12' fill={color} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 6H14M1.5 1H16.5M6.5 11H11.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
