import { IWithExpandedAllProps, withExpanded } from '../../../../../shared/hoc/WithExpanded'
import { ColumnsType } from 'antd/lib/table'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { ISerpPresenceFilter } from '../../interfaces/SerpPresenceFilter'
import { selectSerpPresenceFilters, setIsSeo, setSerpPresence } from '../../store/SerpPresenceSlice'
import {
  useExportBrandPresenceMutation,
  useGetBrandPresenceQuery,
} from '../../../../../api/research/Research.api'
import { BpTable } from '../../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../../shared/types/TableResponse.type'
import { IBpPaginationProps } from '../../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../../shared/hooks/UseSorter'
import { SerpPresenceExpandRow } from './SerpPresenceExpandRow'
import { BpTooltip } from '../../../../../shared/components/cards/tooltip/BpTooltip'
import { ExportDrop } from '../../../../../shared/components/cards/export-drop/ExportDrop'
import { IBrandPresenceModel } from '../../../../../api/research/models/BrandPresence.model'
import { IBrandPresenceParams } from '../../../../../api/research/interfaces/BrandPresenceParams.interface'
import { Switch } from 'antd'
import { useTranslation } from 'react-i18next'

const Table = ({ onExpandRow, expandedRowKeys }: IWithExpandedAllProps) => {
  const { t } = useTranslation()
  const [countRows, setCountRows] = useState<number>(0)
  const detailedInfoFilter = useAppSelector<ISerpPresenceFilter>(selectSerpPresenceFilters)
  const {
    project,
    country,
    engine_id,
    dateRange: date,
    keyword,
    page_url,
    no_serp_presence,
    is_seo,
  } = detailedInfoFilter

  const [queryParams, setQueryParams] = useState<IBrandPresenceParams>()
  const { data, isLoading, isFetching } = useGetBrandPresenceQuery(
    queryParams as IBrandPresenceParams,
    { skip: !project || queryParams == undefined },
  )
  const [exportBrandPresence, { isLoading: exportLoading }] = useExportBrandPresenceMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!project) return

    setQueryParams({
      page: 1,
      'per-page': 10,
      project_id: +project,
      country_id: country ? +country : undefined,
      engine_id: +engine_id,
      date,
      keyword,
      no_serp_presence,
      landing: page_url,
    })
  }, [detailedInfoFilter])

  useEffect(() => {
    if (data?.data) setCountRows(data.data.total)
  }, [data])

  const columns: ColumnsType<IBrandPresenceModel> = [
    {
      title: t('table.columns.keyword'),
      dataIndex: 'keyword',
      key: 'keyword',
    },
    {
      title: t('table.columns.paidResults'),
      dataIndex: 'paid_count',
      key: 'paid_count',
      sorter: true,
      render: (_: string, { keyword_id, paid_count }: IBrandPresenceModel) => {
        if (!_)
          return (
            <div className='flex h-6 items-center'>
              <span className='block w-2 h-2 bg-[#D9D9D9] rounded-full'></span>
            </div>
          )

        return (
          <div className='flex items-center'>
            <span className='w-7'>{paid_count}</span>

            {expandedRowKeys?.includes(`${keyword_id}`) && !is_seo ? (
              <span
                className={`text-focus
                  text-[13px]
                  ml-4
                  border-dotted
                  border-b
                  cursor-pointer`}
                onClick={() => {
                  onExpandRow && onExpandRow(false, `${keyword_id}`)
                }}
              >
                {t('buttons.close')}
              </span>
            ) : (
              <span
                className={`text-focus
                  text-[13px]
                  ml-4
                  border-dotted
                  border-b
                  cursor-pointer`}
                onClick={() => {
                  onExpandRow && onExpandRow(true, `${keyword_id}`)
                  dispatch(setIsSeo(false))
                }}
              >
                {t('buttons.show')}
              </span>
            )}
          </div>
        )
      },
    },
    {
      title: t('table.columns.organicResults'),
      dataIndex: 'organic_count',
      key: 'organic_count',
      sorter: true,
      render: (_: string, { keyword_id, organic_count }: IBrandPresenceModel) => {
        if (!_)
          return (
            <div className='flex h-6 items-center'>
              <span className='block w-2 h-2 bg-[#D9D9D9] rounded-full'></span>
            </div>
          )

        return (
          <div className='flex items-center'>
            <span className='w-7'>{organic_count}</span>

            {expandedRowKeys?.includes(`${keyword_id}`) && is_seo ? (
              <span
                className={`text-focus
                  text-[13px]
                  ml-4
                  border-dotted
                  border-b
                  cursor-pointer`}
                onClick={() => {
                  onExpandRow && onExpandRow(false, `${keyword_id}`)
                }}
              >
                {t('buttons.close')}
              </span>
            ) : (
              <span
                className={`text-focus
                  text-[13px]
                  ml-4
                  border-dotted
                  border-b
                  cursor-pointer`}
                onClick={() => {
                  onExpandRow && onExpandRow(true, `${keyword_id}`)
                  dispatch(setIsSeo(true))
                }}
              >
                {t('buttons.show')}
              </span>
            )}
          </div>
        )
      },
    },
    {
      title: t('table.columns.firstUpdated'),
      dataIndex: 'first_updated',
      key: 'first_updated',
      width: '150px',
      sorter: true,
    },
    {
      title: t('table.columns.lastUpdated'),
      dataIndex: 'last_updated',
      key: 'last_updated',
      width: '150px',
      sorter: true,
    },
  ]

  const handleTableChange = (
    { current, pageSize }: IBpPaginationProps,
    sorter?: SorterResult<IBrandPresenceModel> | SorterResult<IBrandPresenceModel>[],
  ) => {
    if (queryParams) {
      setQueryParams({
        ...queryParams,
        page: current,
        'per-page': pageSize,
        sort: useSorter(sorter),
      })
    }
  }

  return (
    <section>
      <div className='mb-[16px] flex flex-col md:flex-row justify-between md:items-center gap-[16px]'>
        <h3 className='flex gap-3 items-center font-medium'>
          {t('pages.onSiteBrandMentions.onSiteBrandMentions')}: {countRows}
          <BpTooltip title={t('pages.onSiteBrandMentions.onSiteBrandMentionsTooltip')} />
        </h3>
        <span className='flex items-center gap-[9px] ml-auto mr-6 text-focus-500 text-[15px] leading-[24px]'>
          {t('fields.noSERPPresence')}
          <Switch
            checked={queryParams?.no_serp_presence}
            onClick={() => dispatch(setSerpPresence(!queryParams?.no_serp_presence))}
          ></Switch>
        </span>
        <span className='flex gap-[9px] items-center'>
          <ExportDrop
            onExport={(exportParams) => exportBrandPresence({ ...queryParams, ...exportParams })}
            loading={exportLoading}
            columns={[
              { keys: ['keyword'], label: t('table.columns.keyword') },
              { keys: ['paid_count'], label: t('table.columns.paidResults') },
              { keys: ['organic_count'], label: t('table.columns.organicResults') },
              { keys: ['first_updated'], label: t('table.columns.firstUpdated') },
              { keys: ['last_updated'], label: t('table.columns.lastUpdated') },
            ]}
          />
        </span>
      </div>
      <BpTable
        dataSource={data?.data?.items}
        rowKey={(record) => `${record.keyword_id}`}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<IBrandPresenceModel>}
        expandable={{
          expandedRowRender: (record: IBrandPresenceModel) => (
            <SerpPresenceExpandRow record={record} />
          ),
          expandedRowKeys: expandedRowKeys,
          showExpandColumn: false,
        }}
      ></BpTable>
    </section>
  )
}

export const SerpPresenceTable = withExpanded(Table)
