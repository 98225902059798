import { useAppSelector } from '../../../../../store/hooks/Redux'
import { ExportDrop } from '../../../../../shared/components/cards/export-drop/ExportDrop'
import React, { useEffect, useState } from 'react'
import { useExportAdvertisingMutation } from '../../../../../api/research/Research.api'
import { IDetailedInfoFilter } from '../../interfaces/DetailedInfoFilter.interface'
import { selectDetailedInfoFilters } from '../../store/DetailedInfoSlice'
import { IAdvertisingParams } from '../../../../../api/research/interfaces/AdvertisingParams.interface'
import { useTranslation } from 'react-i18next'

export const DetailedInfoPagesPanel = () => {
  const { t } = useTranslation()
  const detailedInfoFilter = useAppSelector<IDetailedInfoFilter>(selectDetailedInfoFilters)
  const {
    project,
    site,
    country,
    engine_id,
    dateRange: date,
    keyword,
    has_brand,
  } = detailedInfoFilter
  const [queryParams, setQueryParams] = useState<IAdvertisingParams>()
  const [exportFile, { isLoading }] = useExportAdvertisingMutation()

  useEffect(() => {
    if (!project) return

    setQueryParams({
      page: 1,
      'per-page': 10,
      project_id: +project,
      site,
      country_id: country ? +country : undefined,
      engine_id: +engine_id,
      date,
      keyword,
      has_brand,
    })
  }, [detailedInfoFilter])

  return (
    <span className='flex gap-[9px]'>
      <ExportDrop
        onExport={(exportParams) => exportFile({ ...queryParams, ...exportParams })}
        loading={isLoading}
        columns={[
          { keys: ['title'], label: t('table.columns.page') },
          { keys: ['keywords_count'], label: t('table.columns.keyword(s)') },
          { keys: ['position'], label: t('table.columns.avgPosition') },
          { keys: ['previous_position'], label: t('table.columns.previousPosition') },
          { keys: ['top_keyword'], label: t('table.columns.topKeyword') },
          { keys: ['landing_page'], label: t('table.columns.landingPage') },
          { keys: ['last_found'], label: t('table.columns.lastFound') },
        ]}
      />
    </span>
  )
}
