import { CommonAuthSuccess, ICommonAuthSuccessPageProps } from '../../utility/CommonAuthSuccess'
import { BpAnchor } from '../../../../shared/components/text/anchor/BpAnchor'

export const ResetPasswordSuccess = () => {
  const props: ICommonAuthSuccessPageProps = {
    title: <>The reset link has been sent!</>,
    paragraph: {
      bluepear: (
        <>
          If you don’t receive an email in the next 24 hours, please contact our support team by
          email at <BpAnchor href='mailto:sales@bluepear.co'>sales@bluepear.co</BpAnchor>
        </>
      ),
      robuscan: null,
      default: null,
    }[process.env.REACT_APP_PROJECT ?? 'default'],
  }
  return (
    <>
      <CommonAuthSuccess {...props} />
    </>
  )
}
