import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store/Store'
import { EDateRangeType } from '../enums/DateRangeType'
import { IKeywordsFilter } from '../interfaces/KeywordsFilter.interface'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'
import { IDateRange } from '../../../../shared/interfaces/DateRange.interface'
import { useDateRange } from '../../../../shared/hooks/UseDateRange'

type KeywordsState = {
  filters: IKeywordsFilter
  pagination: IPagination
}

const dateRange = useDateRange(EDateRangeType.MONTH)

const keywordsSlice = createSlice({
  name: 'keywords',
  initialState: {
    filters: {
      keywords: undefined,
      dateRange,
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 1,
    },
  } as KeywordsState,
  reducers: {
    setKeywords: (state: KeywordsState, action: PayloadAction<string[] | undefined>) => {
      state.filters.keywords = action.payload
    },
    setKeywordsPagination: (state: KeywordsState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload
    },
    changeKeywordsDateRange: (state: KeywordsState, action: PayloadAction<IDateRange>) => {
      state.filters.dateRange = action.payload
    },
  },
})

export const { setKeywords, setKeywordsPagination, changeKeywordsDateRange } = keywordsSlice.actions

export const selectKeywordsFilter = (state: RootState) => state.root.keywords.filters
export const selectKeywordsPagination = (state: RootState) => state.root.keywords.pagination

export default keywordsSlice.reducer
