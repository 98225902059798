import { IBlackhatMonitoringFilters } from '../interfaces/BlackhatMonitoringFilters.interface'
import {
  selectBlackhatMonitoringFilters,
  setBlackhatMonitoringCountry,
  setBlackhatMonitoringDate,
  setBlackhatMonitoringDomain,
  setBlackhatMonitoringFilter,
  setBlackhatMonitoringTime,
} from '../store/BlackhatMonitoringSlice'
import { IWithSearchParamsProps, withSearchParams } from '../../../../shared/hoc/WithSearchParams'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { Controller, useForm } from 'react-hook-form'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { useFiltersQuery } from '../../../../api/decloak/Decloak.api'
import { useEffect } from 'react'
import { IconCalendar } from '../../../../shared/components/icons/IconCalendar'
import { IconGlobe } from '../../../../shared/components/icons/IconGlobe'
import { IconCountry } from '../../../../shared/components/icons/IconCountry'
import { IconClock } from '../../../../shared/components/icons/IconClock'
import { IDecloakFilterTimeModel } from '../../../../api/decloak/models/DecloakFilter.model'
import { useGetCountryQuery } from '../../../../api/filter/Filter.api'
import { useTranslation } from 'react-i18next'

export const Filters = ({
  searchParams,
  setSearchParams,
}: IWithSearchParamsProps<IBlackhatMonitoringFilters>) => {
  const { t } = useTranslation()
  const { domain, country, date, time } = useAppSelector(selectBlackhatMonitoringFilters)
  const { getValues, control } = useForm<IBlackhatMonitoringFilters>({
    mode: 'onChange',
    defaultValues: { domain, country, date, time, ...searchParams },
  })
  const dispatch = useAppDispatch()

  const { domains, countries, dates, times, isLoading } = useFiltersQuery(
    { domain, country_id: country, date },
    {
      selectFromResult: (state) => ({
        ...state,
        ...state.data?.data,
      }),
      refetchOnMountOrArgChange: true,
    },
  )

  const { data: countryNames, isLoading: countryNamesLoading } = useGetCountryQuery()

  useEffect(() => {
    if (searchParams) dispatch(setBlackhatMonitoringFilter(searchParams))
  }, [])

  useEffect(() => {
    if (setSearchParams) {
      setSearchParams({ domain, country, date, time })
    }
  }, [domain, country, date, time])

  return (
    <>
      <form className='flex flex-col max-w-full gap-y-[12px] md:items-center md:flex-row md:flex-wrap md:gap-x-[8px] xl:flex-nowrap'>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='domain'
            control={control}
            render={({ field: { value, onChange, ...fieldProps } }) => {
              // useEffect(() => {
              //   dispatch(
              //     setBlackhatMonitoringCountry(
              //       countries?.includes(country)
              //         ? country
              //         : countries?.length
              //         ? countries[0]
              //         : searchParams
              //         ? searchParams?.country
              //         : '',
              //     ),
              //   )
              // }, [value])

              return (
                <BpSelect
                  {...fieldProps}
                  options={domains?.map((value: string) => ({
                    text: value,
                    value: value,
                  }))}
                  Icon={IconGlobe}
                  fieldSize='lg'
                  title={t('fields.domain')}
                  value={domain}
                  defaultValue={
                    domains?.includes(domain) ? domain : domains?.length ? domains[0] : ''
                  }
                  loading={isLoading}
                  disabled={!domains?.length}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    dispatch(setBlackhatMonitoringDomain(getValues('domain') || domain || ''))
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='country'
            control={control}
            render={({ field: { value, onChange, ...fieldProps } }) => {
              useEffect(() => {
                dispatch(
                  setBlackhatMonitoringCountry(
                    countries?.includes(country)
                      ? country
                      : countries?.length
                      ? countries[0]
                      : searchParams
                      ? searchParams?.country
                      : '',
                  ),
                )
              }, [countries])
              return (
                <BpSelect
                  {...fieldProps}
                  options={
                    countryNames &&
                    countries?.map((value: number) => {
                      const text = countryNames.find(
                        (country) => country.value === String(value),
                      )?.text

                      return {
                        text: text,
                        value: value,
                      }
                    })
                  }
                  Icon={IconCountry}
                  fieldSize='lg'
                  title={t('fields.country')}
                  value={country}
                  defaultValue={
                    countries?.includes(country) ? country : countries?.length ? countries[0] : ''
                  }
                  loading={isLoading || countryNamesLoading}
                  disabled={!countries?.length}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    dispatch(setBlackhatMonitoringCountry(getValues('country') || country || ''))
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='date'
            control={control}
            render={({ field: { value, onChange, ...fieldProps } }) => {
              useEffect(() => {
                dispatch(
                  setBlackhatMonitoringDate(
                    dates?.includes(date)
                      ? date
                      : dates?.length
                      ? dates[0]
                      : searchParams
                      ? searchParams?.date
                      : '',
                  ),
                )
              }, [dates])

              return (
                <BpSelect
                  {...fieldProps}
                  options={dates?.map((value: string) => ({
                    text: value,
                    value: value,
                  }))}
                  Icon={IconCalendar}
                  fieldSize='lg'
                  title={t('fields.date')}
                  value={date}
                  defaultValue={dates?.includes(date) ? date : dates?.length ? dates[0] : ''}
                  loading={isLoading}
                  disabled={!dates?.length}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    dispatch(setBlackhatMonitoringDate(getValues('date') || date || ''))
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='time'
            control={control}
            render={({ field: { value, onChange, ...fieldProps } }) => {
              useEffect(() => {
                setSearchParams && setSearchParams({ ...searchParams, time: value })
                dispatch(
                  setBlackhatMonitoringTime(
                    times?.some((value: IDecloakFilterTimeModel) => value.id === time)
                      ? time
                      : times?.length
                      ? times[0].id
                      : searchParams
                      ? searchParams?.time
                      : '',
                  ),
                )
              }, [times])

              return (
                <BpSelect
                  {...fieldProps}
                  options={times?.map((value: IDecloakFilterTimeModel) => ({
                    text: value.time,
                    value: value.id,
                  }))}
                  Icon={IconClock}
                  fieldSize='lg'
                  title={t('fields.time')}
                  value={time}
                  defaultValue={
                    times?.some((value: IDecloakFilterTimeModel) => value.id === time)
                      ? time
                      : times?.length
                      ? times[0].id
                      : ''
                  }
                  loading={isLoading}
                  disabled={!times?.length}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    dispatch(setBlackhatMonitoringTime(getValues('time') || time || ''))
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
      </form>
    </>
  )
}

export const BlackhatMonitoringFilters = withSearchParams<
  IWithSearchParamsProps<IBlackhatMonitoringFilters>,
  IBlackhatMonitoringFilters
>(Filters, selectBlackhatMonitoringFilters)
