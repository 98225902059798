import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import queryString from 'query-string'
import { ICommonFilterModel, mapToOptions } from '../../shared/helpers/MapToOptions'
import { IOption } from '../../shared/components/forms/search-input/BpSearchInput'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { objectToFormData } from '../../shared/helpers/ObjectToFormData'
import { INonCompliantAffiliatesParams } from '../compliance/interfaces/NonCompliantAffiliatesParams.interface'
import { ICommonExport } from '../../shared/interfaces/CommonExport.interface'

export const languageComplianceApi = createApi({
  reducerPath: 'language-compliance/api',
  tagTypes: ['LanguageCompliance'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/language-compliance/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    getEnabled: build.query<boolean, void>({
      query: () => ({
        url: 'filter-project',
      }),
      transformResponse: (response: ICommonResponse<ICommonFilterModel[]>): boolean => {
        return !!response?.data?.length
      },
    }),
    getFilterProjects: build.query<IOption[], void>({
      query: () => ({
        url: 'filter-project',
      }),
      transformResponse: mapToOptions,
    }),
    getFilterCountry: build.query<IOption[], any>({
      query: (params: any) => ({
        url: 'filter-country',
        params,
      }),
      transformResponse: mapToOptions,
    }),
    getFilterPage: build.query<IOption[], any>({
      query: (params: any) => ({
        url: 'filter-page',
        params,
      }),
      transformResponse: mapToOptions,
    }),
    getFilterAffiliate: build.query<IOption[], any>({
      query: (params: any) => ({
        url: 'filter-affiliate',
        params,
      }),
      transformResponse: mapToOptions,
    }),
    getFilterWord: build.query<IOption[], any>({
      query: (params: any) => ({
        url: 'filter-word',
        params,
      }),
      transformResponse: mapToOptions,
    }),
    getLastCheck: build.query<any, any>({
      query: (params: any) => ({
        url: 'last-check',
        params,
      }),
      providesTags: ['LanguageCompliance'],
    }),
    getInProgress: build.query<any, any>({
      query: (params: any) => ({
        url: 'in-progress',
        params,
      }),
      providesTags: ['LanguageCompliance'],
    }),
    getHistory: build.query<any, any>({
      query: (params: any) => ({
        url: 'history',
        params,
      }),
      providesTags: ['LanguageCompliance'],
    }),
    getHistoryDetails: build.query<any, any>({
      query: (params: any) => ({
        url: 'history-detail',
        params,
      }),
    }),
    getCounter: build.query<any, any>({
      query: (params: any) => ({
        url: 'counter',
        params,
      }),
      providesTags: ['LanguageCompliance'],
    }),
    getReport: build.query<any, any>({
      query: (params: any) => ({
        url: 'report',
        params,
      }),
    }),
    updateState: build.mutation<ICommonResponse<any>, any>({
      query: (params: any) => ({
        url: 'update-state',
        method: 'POST',
        body: objectToFormData(params),
        cache: 'no-cache',
      }),
      invalidatesTags: ['LanguageCompliance'],
    }),
    exportHistory: build.mutation<Blob, any & ICommonExport>({
      query(params: any & ICommonExport) {
        return {
          url: 'export',
          params: params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
  }),
})

export const {
  useGetEnabledQuery,
  useGetFilterProjectsQuery,
  useGetFilterCountryQuery,
  useGetFilterPageQuery,
  useGetFilterAffiliateQuery,
  useGetFilterWordQuery,
  useGetLastCheckQuery,
  useGetInProgressQuery,
  useGetHistoryQuery,
  useGetHistoryDetailsQuery,
  useGetCounterQuery,
  useGetReportQuery,
  useUpdateStateMutation,
  useExportHistoryMutation,
} = languageComplianceApi
