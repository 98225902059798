import { CommonMainPage } from '../utility/CommonMainPage'
import { OrganicResultsFilters } from './components/filters/OrganicResultsFilters'
import { OrganicResultsTable } from './components/report/OrganicResultsTable'

export const OrganicResultsPage = () => {
  return (
    <CommonMainPage>
      <section className='flex flex-col gap-[50px]'>
        <OrganicResultsFilters />
        <OrganicResultsTable />
      </section>
    </CommonMainPage>
  )
}
