import { AnchorHTMLAttributes } from 'react'

export const BpAnchor = (props: AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const { children, className } = props
  return (
    <a
      {...props}
      className={`text-focus hover:opacity-80 transition-opacity duration-150 cursor-pointer ${className}`}
    >
      {children}
    </a>
  )
}
