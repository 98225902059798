import { EDateRangeType } from '../../../research-tools/enums/DateRangeType'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import {
  selectOverviewFilters,
  selectOverviewVisibilityFilter,
  setVisibilityFilter,
} from '../../store/OverviewSlice'
import { DateRanges } from '../../../../../shared/components/forms/date-ranges/DateRanges'
import { useGetVisibilityQuery } from '../../../../../api/overview/Overview.api'
import { IOverviewFilter } from '../../interfaces/OverviewFilter.interface'
import { IVisibilityParams } from '../../../../../api/overview/interfaces/VisibilityParams.interface'
import { IDateRange } from '../../../../../shared/interfaces/DateRange.interface'
import { useDateRange } from '../../../../../shared/hooks/UseDateRange'
import HighchartsReact from 'highcharts-react-official'
import Highcharts, { Options } from 'highcharts'
import { useMakeWritable } from '../../../../../shared/hooks/UseMakeWritable'
import { BpTooltip } from '../../../../../shared/components/cards/tooltip/BpTooltip'
import { BpSelect } from '../../../../../shared/components/forms/select/BpSelect'
import { GroupByOptions } from '../../../../../shared/consts/GroupByOptions.const'
import { EDateGroup } from '../../../../../api/research/enums/DateGroup.enum'
import { useTranslation } from 'react-i18next'

export const VisibilityReport = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { country, project } = useAppSelector<IOverviewFilter>(selectOverviewFilters)
  const filter = useAppSelector<IDateRange>(selectOverviewVisibilityFilter)
  const { date, group: date_group } = filter

  const { data } = useGetVisibilityQuery(
    {
      project_id: +project,
      country_ids: country?.length ? country : undefined,
      date,
      date_group,
    } as IVisibilityParams,
    { skip: !project },
  )

  return (
    <section className='flex flex-col w-full'>
      <h3 className='mb-[16px] gap-[16px] flex flex-col justify-between lg:flex-row lg:items-center'>
        <span className='flex gap-[8px] items-center'>
          <span className='font-medium'>{t('pages.overview.visibility')}</span>
          <BpTooltip title={t('pages.overview.visibilityTooltip')} />
        </span>
        <span className='flex flex-col md:flex-row gap-[27px] md:items-center'>
          <BpSelect
            hasTranslate
            title={t('fields.groupBy')}
            fieldSize='sm'
            options={GroupByOptions}
            defaultValue={date_group}
            onChange={(e) =>
              dispatch(
                setVisibilityFilter({
                  ...filter,
                  group: e.target.value as EDateGroup,
                }),
              )
            }
          />
          <DateRanges
            dateRange={date}
            name='visibility-filter'
            onChange={(e) =>
              dispatch(
                setVisibilityFilter({
                  ...filter,
                  date: useDateRange(EDateRangeType[e.target.value as EDateRangeType]).date,
                }),
              )
            }
          />
        </span>
      </h3>
      <HighchartsReact
        highcharts={Highcharts}
        options={
          {
            title: {
              text: '',
            },
            tooltip: {
              backgroundColor: 'white',
              borderRadius: 15,
              style: {
                fontSize: '14px',
                color: '#00326D',
              },
              pointFormat: '<span style="color:#0E63D8;">●</span> {point.y}%',
            },
            xAxis: {
              type: 'datetime',
              labels: {
                style: {
                  color: 'rgba(0,50,109,0.3)',
                  fontSize: '13px',
                  fontFamily: 'Jost, sans-serif',
                },
              },
              tickColor: 'transparent',
            },
            yAxis: {
              title: undefined,
              gridLineColor: 'rgba(0,50,109,0.03)',
              labels: {
                style: {
                  color: 'rgba(0,50,109,0.3)',
                  fontSize: '13px',
                  fontFamily: 'Jost, sans-serif',
                },
              },
            },
            chart: {
              type: 'areaspline',
              height: '300px',
            },
            series: [{ name: 'Visibility', data: data?.data ? useMakeWritable(data.data) : [] }],
            credits: {
              enabled: false,
            },
            legend: {
              enabled: false,
            },
            plotOptions: {
              areaspline: {
                color: '#0E63D8',
                fillOpacity: 1,
              },
            },
          } as Options
        }
      />
    </section>
  )
}
