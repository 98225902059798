import React, { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'
import { IWithFieldSizeProps, withFieldSize } from '../../../../hoc/WithFieldSize'

export interface IInputProps extends IWithFieldSizeProps, InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef((props: IInputProps, ref: ForwardedRef<HTMLInputElement>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { classSize, fieldSize, ...inputProps } = props

  return (
    <input
      {...inputProps}
      ref={ref}
      className={`
        outline-none
        border-none
        rounded-[6px]        
        text-focus-500
        focus:text-focus-500
        w-full
        px-[12px]
        disabled:bg-transparent
        placeholder:text-aqua-300
        ${classSize}
      `}
    />
  )
})

export const BaseInput = withFieldSize(Input)
