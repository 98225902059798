import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconPPCCompliance = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.3307 5.99967V4.53301C13.3307 3.4129 13.3307 2.85285 13.1127 2.42503C12.921 2.0487 12.6151 1.74274 12.2387 1.55099C11.8109 1.33301 11.2509 1.33301 10.1307 1.33301H5.86406C4.74396 1.33301 4.1839 1.33301 3.75608 1.55099C3.37976 1.74274 3.0738 2.0487 2.88205 2.42503C2.66406 2.85285 2.66406 3.4129 2.66406 4.53301V11.4663C2.66406 12.5865 2.66406 13.1465 2.88205 13.5743C3.0738 13.9507 3.37976 14.2566 3.75608 14.4483C4.1839 14.6663 4.74396 14.6663 5.86406 14.6663H6.66406'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect x='3' y='5' width='10' height='1' fill={color} />
      <rect x='10' y='3' width='1' height='1' rx='0.5' fill={color} />
      <rect x='8' y='3' width='1' height='1' rx='0.5' fill={color} />
      <rect x='6' y='3' width='1' height='1' rx='0.5' fill={color} />
      <path
        d='M11.7925 9.71146V9M13.7333 10.2145L14.2559 9.71146M14.261 12.083H15M10.0682 11.8458H12.0389M12.0389 11.8458V13.7431M12.0389 11.8458C10.6363 13.3636 9 15 9 15'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
