import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store/Store'
import { ILiveUncloakingFilter } from '../interfaces/LiveUncloakingFilter.interface'
import { ILiveUncloakingExclude } from '../interfaces/LiveUncloakingExclude.interface'

export type TLiveUncloakingTabs = 'Screenshots' | 'Html' | 'Links'

type LiveUncloakingState = {
  isApplyFilters: boolean
  filters: ILiveUncloakingFilter
  exclude: ILiveUncloakingExclude
  activeTab: TLiveUncloakingTabs
}

const liveUncloakingSlice = createSlice({
  name: 'liveUncloaking',
  initialState: {
    isApplyFilters: false,
    filters: {
      device: '',
      country: '',
      site: '',
    },
    exclude: {
      excludeProxy: false,
      excludeIds: '',
    },
    activeTab: 'Screenshots',
  } as LiveUncloakingState,
  reducers: {
    setLiveUncloakingApplyFilters: (
      state: LiveUncloakingState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isApplyFilters = payload
    },
    setLiveUncloakingFilter: (
      state: LiveUncloakingState,
      { payload }: PayloadAction<ILiveUncloakingFilter>,
    ) => {
      state.filters = { ...state.filters, ...payload }
    },
    setLiveUncloakingExcludeIds: (
      state: LiveUncloakingState,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.exclude = { ...state.exclude, excludeIds: payload }
    },
    setLiveUncloakingExcludeProxy: (
      state: LiveUncloakingState,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.exclude = { ...state.exclude, excludeProxy: payload }
    },
    setLiveUncloakingTab: (
      state: LiveUncloakingState,
      { payload }: PayloadAction<TLiveUncloakingTabs>,
    ) => {
      state.activeTab = payload
    },
  },
})

export const {
  setLiveUncloakingApplyFilters,
  setLiveUncloakingFilter,
  setLiveUncloakingExcludeIds,
  setLiveUncloakingExcludeProxy,
  setLiveUncloakingTab,
} = liveUncloakingSlice.actions

export const selectLiveUncloakingApplyFilters = (state: RootState) =>
  state.root.liveUncloaking.isApplyFilters

export const selectLiveUncloakingFilters = (state: RootState) => state.root.liveUncloaking.filters

export const selectLiveUncloakingExclude = (state: RootState) => state.root.liveUncloaking.exclude

export const selectLiveUncloakingTab = (state: RootState) => state.root.liveUncloaking.activeTab

export default liveUncloakingSlice.reducer
