import { Tooltip } from 'antd'

export interface ISimpleListItemParams {
  children?: string
}

export const SimpleListItem = ({ children }: ISimpleListItemParams) => {
  return (
    <div className='flex items-center gap-[8px]'>
      <span className='w-[4px] h-[4px] rounded-full bg-default'></span>
      <Tooltip
        title={children}
        className='text-focus-500 hover:text-default transition-colors duration-150 truncate flex-1 leading-[24px] text-[14px]'
      >
        {children}
      </Tooltip>
    </div>
  )
}
