import React from 'react'
import { useAppSelector } from '../../../../../../store/hooks/Redux'
import { useGetKeywordsChartQuery } from '../../../../../../api/research/Research.api'
import { IResearchFilter } from '../../../interfaces/ResearchFilter.interface'
import { selectResearchFilters } from '../../../store/ResearchSlice'
import { useDispatch } from 'react-redux'
import { changeKeywordsDateRange, selectKeywordsFilter } from '../../../store/KeywordsSlice'
import { IKeywordsFilter } from '../../../interfaces/KeywordsFilter.interface'
import { IKeywordsChartParams } from '../../../../../../api/research/interfaces/KeywordsChartParams.interface'
import { GraphReport } from '../../../../../../shared/components/cards/graph-report/GraphReport'
import { useTranslation } from 'react-i18next'

export const ByAdvertisersGraph = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { country, project } = useAppSelector<IResearchFilter>(selectResearchFilters)
  const { keywords, dateRange: filter } = useAppSelector<IKeywordsFilter>(selectKeywordsFilter)
  const { date, group: date_group } = filter

  const { data } = useGetKeywordsChartQuery(
    {
      date_group,
      date,
      chart_keywords: keywords,
      project_ids: project ? [project] : [],
      country_ids: country ? country : [],
    } as IKeywordsChartParams,
    { skip: keywords === undefined || !keywords.length },
  )

  return (
    <GraphReport
      title={t('pages.advertisingResearch.keywordsByAdvertisers')}
      filter={filter}
      series={data}
      onChange={(e) => dispatch(changeKeywordsDateRange(e))}
    />
  )
}
