import { useDateRanges } from '../../../../shared/hooks/UseDateRanges'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IDetailedInfoFilter } from '../interfaces/DetailedInfoFilter.interface'
import { RootState } from '../../../../store/Store'

export type TDetailedInfoTabs = 'Pages' | 'Affiliates'

type DetailedInfoState = {
  filters: IDetailedInfoFilter
  activeTab: TDetailedInfoTabs
  pagesExpanded: boolean
}

const dateRanges = useDateRanges()

const detailedInfoSlice = createSlice({
  name: 'detailedInfo',
  initialState: {
    filters: { dateRange: dateRanges.month },
    activeTab: 'Pages',
    pagesExpanded: false,
  } as DetailedInfoState,
  reducers: {
    setDetailedInfoFilter: (
      state: DetailedInfoState,
      action: PayloadAction<IDetailedInfoFilter>,
    ) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    setDetailedInfoActiveTab: (
      state: DetailedInfoState,
      { payload }: PayloadAction<TDetailedInfoTabs>,
    ) => {
      state.activeTab = payload
    },
  },
})

export const { setDetailedInfoFilter, setDetailedInfoActiveTab } = detailedInfoSlice.actions

export const selectDetailedInfoFilters = (state: RootState) => state.root.detailedInfo.filters

export const selectDetailedInfoActiveTab = (state: RootState) => state.root.detailedInfo.activeTab

export default detailedInfoSlice.reducer
