import { AnchorHTMLAttributes } from 'react'
import { Link, LinkProps } from 'react-router-dom'

export const BpLink = (props: LinkProps & AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const { children } = props
  return (
    <Link
      {...props}
      className='text-focus hover:opacity-80 transition-opacity duration-150 cursor-pointer'
    >
      {children}
    </Link>
  )
}
