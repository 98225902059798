import { BpCard } from '../../../../shared/components/cards/card/BpCard'
import { Square } from '../../../../shared/components/utility/square/Square'
import { Controller, useForm } from 'react-hook-form'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { useEditPasswordMutation } from '../../../../api/profile/Profile.api'
import { FormControls } from '../../../../shared/components/forms/form-controls/FormControls'
import { useTranslation } from 'react-i18next'

export interface IChangePasswordFormData {
  password: string
  new_password: string
}

export const ChangePasswordForm = ({ className }: { className?: string }) => {
  const { t } = useTranslation()
  const [editPassword] = useEditPasswordMutation()

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
    register,
  } = useForm<IChangePasswordFormData>({ mode: 'onChange' })

  return (
    <Square className={className}>
      <form onSubmit={handleSubmit((data: IChangePasswordFormData) => editPassword(data))}>
        <BpCard
          title={t('pages.profile.changePassword')}
          footer={<FormControls disabled={!isDirty || !isValid} onCancel={() => reset()} />}
        >
          <div className='flex flex-col gap-[16px]'>
            <Controller
              name='password'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <BpInput
                  {...field}
                  {...register('password', { required: t('fields.validate.required') })}
                  label={t('pages.profile.fields.currentPassword')}
                  type='password'
                  placeholder={t('pages.profile.fields.enterPassword')}
                  required
                  error={error?.message}
                />
              )}
            />
            <Controller
              name='new_password'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <BpInput
                  {...field}
                  {...register('new_password', {
                    required: t('fields.validate.required'),
                  })}
                  label={t('pages.profile.fields.newPassword')}
                  type='password'
                  placeholder={t('pages.profile.fields.enterNewPassword')}
                  required
                  error={error?.message}
                />
              )}
            />
          </div>
        </BpCard>
      </form>
    </Square>
  )
}
