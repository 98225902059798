import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <div className='flex py-[22px] flex-col items-center md:flex-row md:justify-center'>
      <img
        alt='logo'
        src={'/logo.svg'}
        className='cursor-pointer w-[145px] mb-[16px] md:mb-0 md:mr-[37px]'
      />
      <p className='text-white/[0.75] text-[14px] md:mt-[17px]'>
        © <Moment format='YYYY'>{new Date()}</Moment> {t('project.name')}.{' '}
        {t('other.allRightsReserved')}.
      </p>
    </div>
  )
}
