import { IOption } from '../components/forms/search-input/BpSearchInput'

interface ICommonResponse<T> {
  success?: boolean
  errors?: { [k: string]: string[] }
  data?: T
  dt?: string
}

export interface ICommonFilterModel {
  id: number
  name: string
}

export const mapToOptions = (response: ICommonResponse<ICommonFilterModel[]>): IOption[] => {
  if (response?.data) {
    return response.data.map((o) => ({
      text: o.name,
      value: `${o.id}`,
    }))
  }

  return []
}
