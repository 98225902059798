import { BpButton } from '../../../shared/components/buttons/bp-button/BpButton'
import { Square } from '../../../shared/components/utility/square/Square'
import { useWindowSize } from '../../../shared/hooks/UseWindowSize'
import { useTranslation } from 'react-i18next'

export interface ICommonAuthSuccessPageProps {
  title: JSX.Element
  paragraph?: JSX.Element | null
}

export const CommonAuthSuccess = ({ title, paragraph }: ICommonAuthSuccessPageProps) => {
  const { t } = useTranslation()
  const { xs, sm } = useWindowSize()

  const section = (
    <div className='flex flex-col md:w-[406px] gap-[24px] md:gap-[40px] items-start md:pt-[182px]'>
      <h1 className='text-focus-500'>{title}</h1>
      {paragraph && (
        <p className='text-[15px] leading-[24px] md:leading-[36px] md:text-[24px] text-focus-500'>
          {paragraph}
        </p>
      )}
      <a href={t('project.landing')}>
        <BpButton theme='outline' type='button'>
          GO TO HOMEPAGE
        </BpButton>
      </a>
    </div>
  )
  return (
    <>
      {(xs || sm) && (
        <Square depth='100' className='flex flex-col p-[24px]'>
          {section}
        </Square>
      )}
      {!xs && !sm && section}
      <svg
        className='absolute top-[100px] left-[540px] hidden lg:block'
        width='340'
        height='758'
        viewBox='0 0 340 758'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M306.643 280.158C306.643 280.158 263.859 253.584 213.75 228.49C213.75 228.49 202.918 129.519 190.605 125.779C178.214 122.038 161.615 119.311 161.615 119.311C161.615 119.311 175.019 225.763 190.761 242.05C206.503 258.415 301.889 290.445 301.889 290.445L306.643 280.158Z'
          fill='#F26D6D'
        />
        <path
          d='M296.045 288.652C296.98 288.886 297.837 289.198 298.772 289.432C299.786 289.743 300.799 289.977 301.734 290.445C303.292 293.874 306.721 296.523 310.384 297.536C311.865 297.926 313.423 298.082 314.904 298.238C315.527 298.316 316.073 298.238 317.008 298.238C318.723 298.238 320.125 298.16 320.593 298.082C323.242 297.926 328.464 297.614 330.412 294.341C330.568 294.029 331.893 292.705 331.113 292.159C325.814 288.808 318.255 285.301 313.112 283.665C313.345 282.262 313.969 281.015 315.216 280.392C315.995 280.002 316.852 279.846 317.709 279.612C318.567 279.379 319.424 278.989 319.969 278.288C320.515 277.586 320.593 276.495 319.969 275.872C319.268 275.17 318.099 275.482 317.164 275.794C315.605 276.261 313.969 276.807 312.41 277.274C311.007 277.742 309.527 278.21 308.358 279.067C307.734 279.456 307.189 280.002 306.488 280.158C305.864 280.314 305.241 280.08 304.617 279.846C302.981 279.301 301.422 278.755 299.786 278.21C298.617 281.716 297.37 285.223 296.045 288.652Z'
          fill='#0E63D8'
        />
        <path
          d='M6.223 116.349C-0.167252 102.4 -2.42722 81.9043 8.3271 72.4748C18.6138 63.4349 31.9398 72.3968 41.8369 63.2011C51.6561 54.0833 41.6811 40.4456 48.7727 25.7169C59.6049 3.35099 103.635 -9.58536 123.04 8.41643C131.924 16.677 136.756 32.4188 131.846 43.9524C126.079 57.5122 111.428 54.5509 102.622 70.1369C94.5175 84.476 103.246 93.4379 95.063 103.023C84.8542 114.947 67.7097 105.439 57.1112 117.362C46.8245 128.896 56.8774 146.196 48.7727 149.781C39.499 153.989 15.7304 137.234 6.223 116.349Z'
          fill='#A059FF'
        />
        <path
          d='M188.89 254.831C191.462 254.909 198.865 282.184 199.8 285.145C203.853 298.393 209.074 321.928 210.009 327.072C214.997 354.347 210.009 499.686 198.086 733.086L185.539 732.463C168.55 606.996 147.821 483.555 127.092 361.127C128.572 325.045 130.131 289.042 131.612 252.96C131.612 253.038 188.89 254.831 188.89 254.831Z'
          fill='#A059FF'
        />
        <path d='M184.94 744.198H200.76V740.379H184.94V744.198Z' fill='#001C44' />
        <path
          d='M184.993 740.412C184.993 740.412 178.135 747.036 184.993 753.894H225.828C226.062 753.114 226.919 749.14 224.504 745.165C224.504 745.165 224.504 745.087 224.426 745.087C222.555 742.126 219.204 740.412 215.698 740.412H199.176C199.254 740.412 192.163 744.542 184.993 740.412Z'
          fill='#001C44'
        />
        <path d='M226.997 753.816H183.746V758.18H226.997V753.816Z' fill='#001C44' />
        <path
          d='M185.539 732.541L186.24 740.412H197.306L197.618 733.164L185.539 732.541Z'
          fill='#001C44'
        />
        <path
          d='M77.8404 266.364C77.8404 266.364 25.9391 325.981 44.3306 439.758C62.722 553.536 89.452 733.242 89.452 733.242L101.999 732.619L127.716 366.816C127.716 366.816 143.847 335.176 144.86 270.728L77.8404 266.364Z'
          fill='#A059FF'
        />
        <path
          d='M89.4528 733.164C89.4528 733.164 89.7645 738.308 88.4397 741.892C87.1149 745.477 98.025 746.101 98.1809 746.023C98.3368 745.945 103.324 741.892 103.324 741.892C103.324 741.892 101.844 737.372 101.999 732.463'
          fill='#001C44'
        />
        <path
          d='M106.908 749.14C106.518 745.087 104.882 742.204 103.947 740.723C102.388 740.178 100.44 739.632 98.024 739.399C93.6599 739.009 90.0751 739.944 87.971 740.723C87.0358 741.892 84.8538 745.01 84.62 749.452C84.4642 752.257 85.0876 754.517 85.6331 755.842C92.5689 755.842 99.4267 755.842 106.362 755.842C106.752 754.283 107.22 751.945 106.908 749.14Z'
          fill='#001C44'
        />
        <path
          d='M98.3354 745.165H93.5037C88.75 745.165 84.8535 749.062 84.8535 753.816V755.686H106.908V753.816C106.908 749.062 103.089 745.165 98.3354 745.165Z'
          fill='#001C44'
        />
        <path
          d='M142.051 61.2743C147.864 55.4615 147.864 46.0372 142.051 40.2245C136.239 34.4117 126.814 34.4117 121.001 40.2245C115.189 46.0372 115.189 55.4615 121.001 61.2743C126.814 67.087 136.239 67.087 142.051 61.2743Z'
          fill='#001C44'
        />
        <path
          d='M196.45 271.975C198.866 241.193 198.944 205.189 193.956 165.367C191.93 149.625 189.358 134.741 186.397 120.869C169.564 115.336 152.809 109.725 135.976 104.192L94.5176 115.726C89.2963 138.248 84.6205 163.809 81.5812 192.019C78.6199 219.061 77.6847 244.076 77.8406 266.364C92.0238 270.261 109.48 273.768 129.508 275.326C156.082 277.274 178.994 275.014 196.45 271.975Z'
          fill='#001C44'
        />
        <path
          d='M196.45 271.975C198.866 241.193 198.944 205.189 193.957 165.367C192.009 149.625 189.359 134.741 186.398 120.869C175.643 117.284 164.889 113.778 154.057 110.193C147.666 115.102 145.406 121.726 150.628 130.844C165.902 157.574 157.797 234.179 151.953 276.105C169.019 275.872 184.138 274.079 196.45 271.975Z'
          fill='#001C44'
        />
        <path
          d='M122.571 99.4385L122.338 76.0596L145.561 74.9685L145.872 101.075C146.418 105.829 152.185 109.569 156.627 111.05L164.809 113.778C163.952 121.025 156.549 137.468 149.535 138.481C136.131 140.508 119.61 117.908 112.051 110.972C116.96 109.491 122.26 104.582 122.571 99.4385Z'
          fill='#0E63D8'
        />
        <path
          d='M146.108 51.0441C146.965 51.6675 153.277 56.3433 154.914 64.6039C157.018 75.4361 150.316 90.0869 139.328 91.3338C128.807 92.5027 119.066 80.8912 118.209 69.8252C117.43 60.006 123.586 48.6282 132.548 46.9917C138.782 45.7448 144.316 49.7972 146.108 51.0441Z'
          fill='#0E63D8'
        />
        <path
          d='M112.52 67.8769C112.286 65.0714 114.39 62.5777 117.195 62.3439C120.001 62.1101 122.495 64.2142 122.729 67.0197C122.962 69.8252 120.858 72.3189 118.053 72.5527C115.247 72.7865 112.753 70.6824 112.52 67.8769Z'
          fill='#001C44'
        />
        <path
          d='M152.731 43.2511C154.835 47.771 150.549 54.395 146.731 57.746C137.145 66.1625 119.455 64.2142 116.65 57.5902C114.624 52.8364 120.157 45.6669 125.222 42.1601C134.652 35.6139 149.77 36.8608 152.731 43.2511Z'
          fill='#A059FF'
        />
        <path
          d='M183.045 119.7L187.409 121.181C192.942 123.051 197.228 127.415 199.021 133.026L211.879 172.926H184.604L183.045 119.7Z'
          fill='#001C44'
        />
        <path
          d='M306.679 314.096C349.833 270.942 349.833 200.975 306.679 157.821C263.525 114.666 193.558 114.666 150.403 157.821C107.249 200.975 107.249 270.942 150.403 314.096C193.558 357.251 263.525 357.251 306.679 314.096Z'
          fill='#3BFFBF'
        />
        <path
          d='M175.33 246.103L208.45 280.859L281.782 191.084'
          stroke='white'
          strokeWidth='34.2578'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M112.988 125.389C106.987 114.479 92.0245 112.531 83.4523 121.649C64.2815 141.832 29.6027 180.797 25.3945 201.371C19.5498 229.971 118.131 280.859 118.131 280.859L123.274 272.988L57.5796 208.462C57.5796 208.462 119.923 138.248 113.299 125.857C113.144 125.623 113.066 125.467 112.988 125.389Z'
          fill='#0E63D8'
        />
        <path
          d='M118.053 280.781L120.702 283.587C120.702 283.587 121.793 286.704 125.066 289.432C125.69 289.977 130.444 293.874 136.99 293.328C139.951 293.094 144.081 291.769 144.237 289.977C144.393 288.029 139.951 285.301 140.107 285.379L123.274 272.988L118.053 280.781Z'
          fill='#0E63D8'
        />
        <path
          d='M127.482 276.339C136.6 275.015 138.938 275.638 139.25 276.573C139.639 277.82 136.678 281.015 133.015 280.781C130.444 280.625 128.184 278.833 127.482 276.339Z'
          fill='#0E63D8'
        />
        <path
          d='M102.233 113.544L93.0374 115.804C87.8161 117.051 83.1403 119.856 79.5555 123.753L54.9297 150.483L90.6995 175.03L120.157 144.248L116.65 122.506L102.233 113.544Z'
          fill='#001C44'
        />
      </svg>
    </>
  )
}
