export function ObjectToArray(obj: { [key: string]: string }): string[] {
  const result = []

  for (const key in obj) {
    if (obj[key]) {
      result.push(obj[key])
    }
  }

  return result
}
