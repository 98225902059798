import React from 'react'
import { IconCross } from '../../../../../shared/components/icons/IconCross'
import { IconHamburger } from '../../../../../shared/components/icons/IconHamburger'

export interface IToggleBtnProps extends React.HTMLAttributes<HTMLButtonElement> {
  active?: boolean
}

export const ToggleBtn = ({ active, onClick }: IToggleBtnProps) => {
  return (
    <button
      className='
        relative
        bg-default
        w-[36px]
        h-[36px]
        flex
        justify-center
        items-center
        rounded-[3px]
      '
      onClick={onClick}
    >
      <div className='w-[20px]'>{active ? <IconCross /> : <IconHamburger />}</div>
    </button>
  )
}
