export const Preloader = () => {
  return (
    <div className='absolute flex w-full h-full justify-center items-center animate-appear-hand left-0 top-0 bottom-0 right-0 m-auto'>
      <svg
        width='73'
        height='73'
        viewBox='0 0 73 73'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='animate-spin'
      >
        <path
          d='M36.7548 10.3182C39.466 10.3182 41.6639 8.12031 41.6639 5.40909C41.6639 2.69787 39.466 0.5 36.7548 0.5C34.0436 0.5 31.8457 2.69787 31.8457 5.40909C31.8457 8.12031 34.0436 10.3182 36.7548 10.3182Z'
          fill='#A059FF'
        />
        <path
          opacity='0.92'
          d='M52.3004 14.4746C55.0117 14.4746 57.2095 12.2767 57.2095 9.56552C57.2095 6.85431 55.0117 4.65643 52.3004 4.65643C49.5892 4.65643 47.3914 6.85431 47.3914 9.56552C47.3914 12.2767 49.5892 14.4746 52.3004 14.4746Z'
          fill='#A059FF'
        />
        <path
          opacity='0.84'
          d='M63.6894 25.8637C66.4006 25.8637 68.5985 23.6658 68.5985 20.9546C68.5985 18.2433 66.4006 16.0455 63.6894 16.0455C60.9781 16.0455 58.7803 18.2433 58.7803 20.9546C58.7803 23.6658 60.9781 25.8637 63.6894 25.8637Z'
          fill='#A059FF'
        />
        <path
          opacity='0.76'
          d='M67.8459 41.4091C70.5571 41.4091 72.7549 39.2112 72.7549 36.5C72.7549 33.7888 70.5571 31.5909 67.8459 31.5909C65.1346 31.5909 62.9368 33.7888 62.9368 36.5C62.9368 39.2112 65.1346 41.4091 67.8459 41.4091Z'
          fill='#A059FF'
        />
        <path
          opacity='0.68'
          d='M63.6894 56.9545C66.4006 56.9545 68.5985 54.7567 68.5985 52.0454C68.5985 49.3342 66.4006 47.1364 63.6894 47.1364C60.9781 47.1364 58.7803 49.3342 58.7803 52.0454C58.7803 54.7567 60.9781 56.9545 63.6894 56.9545Z'
          fill='#A059FF'
        />
        <path
          opacity='0.6'
          d='M52.3004 68.3436C55.0117 68.3436 57.2095 66.1457 57.2095 63.4345C57.2095 60.7233 55.0117 58.5254 52.3004 58.5254C49.5892 58.5254 47.3914 60.7233 47.3914 63.4345C47.3914 66.1457 49.5892 68.3436 52.3004 68.3436Z'
          fill='#A059FF'
        />
        <path
          opacity='0.52'
          d='M36.7548 72.5C39.466 72.5 41.6639 70.3021 41.6639 67.5909C41.6639 64.8797 39.466 62.6818 36.7548 62.6818C34.0436 62.6818 31.8457 64.8797 31.8457 67.5909C31.8457 70.3021 34.0436 72.5 36.7548 72.5Z'
          fill='#A059FF'
        />
        <path
          opacity='0.12'
          d='M21.1988 14.4746C18.4876 14.4746 16.2897 12.2767 16.2897 9.56552C16.2897 6.85431 18.4876 4.65643 21.1988 4.65643C23.91 4.65643 26.1079 6.85431 26.1079 9.56552C26.1079 12.2767 23.91 14.4746 21.1988 14.4746Z'
          fill='#A059FF'
        />
        <path
          opacity='0.2'
          d='M9.80966 25.8637C7.09844 25.8637 4.90057 23.6658 4.90057 20.9546C4.90057 18.2433 7.09844 16.0455 9.80966 16.0455C12.5209 16.0455 14.7188 18.2433 14.7188 20.9546C14.7188 23.6658 12.5209 25.8637 9.80966 25.8637Z'
          fill='#A059FF'
        />
        <path
          opacity='0.28'
          d='M5.65341 41.4091C2.94219 41.4091 0.744318 39.2112 0.744318 36.5C0.744318 33.7888 2.94219 31.5909 5.65341 31.5909C8.36463 31.5909 10.5625 33.7888 10.5625 36.5C10.5625 39.2112 8.36463 41.4091 5.65341 41.4091Z'
          fill='#A059FF'
        />
        <path
          opacity='0.36'
          d='M9.80966 56.9545C7.09844 56.9545 4.90057 54.7567 4.90057 52.0454C4.90057 49.3342 7.09844 47.1364 9.80966 47.1364C12.5209 47.1364 14.7188 49.3342 14.7188 52.0454C14.7188 54.7567 12.5209 56.9545 9.80966 56.9545Z'
          fill='#A059FF'
        />
        <path
          opacity='0.44'
          d='M21.1988 68.3437C18.4876 68.3437 16.2897 66.1458 16.2897 63.4346C16.2897 60.7234 18.4876 58.5255 21.1988 58.5255C23.91 58.5255 26.1079 60.7234 26.1079 63.4346C26.1079 66.1458 23.91 68.3437 21.1988 68.3437Z'
          fill='#A059FF'
        />
      </svg>
    </div>
  )
}
