import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconEyeOff = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.16193 3.39488C7.43293 3.35482 7.7124 3.33333 8.00026 3.33333C11.4036 3.33333 13.6369 6.33656 14.3871 7.52453C14.4779 7.66833 14.5233 7.7402 14.5487 7.85113C14.5679 7.9344 14.5678 8.0658 14.5487 8.14907C14.5233 8.25993 14.4776 8.33233 14.3861 8.47707C14.1862 8.7934 13.8815 9.23807 13.4777 9.72033M4.48288 4.47669C3.0415 5.45447 2.06296 6.81293 1.61407 7.52353C1.52285 7.66793 1.47725 7.74013 1.45183 7.851C1.43273 7.93427 1.43272 8.0656 1.4518 8.14893C1.47722 8.2598 1.52262 8.33167 1.61342 8.47547C2.36369 9.66347 4.59694 12.6667 8.00026 12.6667C9.37253 12.6667 10.5546 12.1784 11.5259 11.5177M2.00028 2L14.0003 14M6.58607 6.58579C6.22413 6.94773 6.00028 7.44773 6.00028 8C6.00028 9.1046 6.89573 10 8.00026 10C8.55253 10 9.05253 9.77613 9.41447 9.4142'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
