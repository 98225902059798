import { ExportDrop } from '../../../../shared/components/cards/export-drop/ExportDrop'
import React, { useEffect, useState } from 'react'
import { Switch } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { IPpcComplianceFilter } from '../interfaces/PpcComplianceFilter.interface'
import { selectPpcComplianceFilter, setPpcComplianceFilter } from '../store/PpcComplianceSlice'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import { INonCompliantAffiliatesParams } from '../../../../api/compliance/interfaces/NonCompliantAffiliatesParams.interface'
import { useExportNonCompliantAffiliatesMutation } from '../../../../api/export-reports/ExportReports.api'

export const PpcCompliancePanel = () => {
  const dispatch = useAppDispatch()
  const filters = useAppSelector<IPpcComplianceFilter>(selectPpcComplianceFilter)
  const {
    project,
    country,
    affiliate,
    network,
    dateRange,
    site,
    engine,
    browser,
    city,
    hidden,
    adHijackers,
    status,
  } = filters

  const [queryParams, setQueryParams] = useState<INonCompliantAffiliatesParams>()

  const [exportNonCompliantAffiliates, { isLoading: exportLoading }] =
    useExportNonCompliantAffiliatesMutation()

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      project_id: project ? Number(project) : undefined,
      country_id: country ? Number(country) : undefined,
      affiliate,
      network: network,
      date: dateRange,
      site: site,
      engine_id: engine ? Number(engine) : undefined,
      browser_id: browser ? Number(browser) : undefined,
      city_id: city ? Number(city) : undefined,
      is_hidden: hidden,
      is_hijack: adHijackers,
      status_id: status ? Number(status) : undefined,
    })
  }, [filters])

  return (
    <div className='flex gap-[16px] items-center'>
      <span className='flex items-center gap-[9px]'>
        <Switch
          defaultChecked={adHijackers}
          onClick={(e) => {
            dispatch(setPpcComplianceFilter({ ...filters, adHijackers: e }))
          }}
        ></Switch>
        Ad Hijackers
        <BpTooltip title='Ad hijacking occurs when affiliates run ads that mimic the brand&#39;s ads with similar headlines, descriptions, and display URLs, diverting clicks and earning unearned commissions. This filter shows such ad-hijackers' />
      </span>
      <ExportDrop
        onExport={(exportParams) =>
          exportNonCompliantAffiliates({ ...queryParams, ...exportParams })
        }
        loading={exportLoading}
        columns={[
          {
            label: 'Affiliates',
            bold: true,
            child: [
              { keys: ['affiliate'], label: 'Affiliate ID' },
              { keys: ['ads_count'], label: 'Ads Analyzed' },
              {
                keys: ['network_name'],
                label: 'Network',
                tooltip:
                  'The affiliate network identified from your tracking link. Unspecified means network tracking is not set up. Contact your manager for help.',
              },
              { keys: ['status_id'], label: 'Status' },
              { keys: ['first_found'], label: 'First found' },
              { keys: ['last_found'], label: 'Last found' },
            ],
          },
          {
            keys: ['latest_examples'],
            label: 'Latest examples',
            tooltip:
              'Check this box, and you’ll be able to generate a report on the latest discovered ads and affiliates for each advertiser. Please note that this report is not available in CSV export',
            bold: true,
          },
        ]}
      />
    </div>
  )
}
