import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconArrowLeft = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.3337 5H0.666992M0.666992 5L4.66699 9M0.666992 5L4.66699 1'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
