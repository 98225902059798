import React, { useEffect, useState } from 'react'
import { ICompetitorModel } from '../../../../../../api/research/models/Competitor.model'
import { ColumnsType } from 'antd/lib/table'
import {
  useExportCompetitorsMutation,
  useGetCompetitorsQuery,
} from '../../../../../../api/research/Research.api'
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface'
import { ICompetitorsParams } from '../../../../../../api/research/interfaces/CompetitorsParams.interface'
import { useAppSelector } from '../../../../../../store/hooks/Redux'
import { IResearchFilter } from '../../../interfaces/ResearchFilter.interface'
import { selectResearchFilters } from '../../../store/ResearchSlice'
import { useDispatch } from 'react-redux'
import {
  selectCompetitorsFilter,
  selectCompetitorsPagination,
  setCompetitorsPagination,
  setCompetitorsSites,
} from '../../../store/CompetitorsSlice'
import { ICompetitorsFilter } from '../../../interfaces/CompetitorsFilter.interface'
import { BpAnchor } from '../../../../../../shared/components/text/anchor/BpAnchor'
import { ProgressBar } from '../../../../../../shared/components/cards/progress-bar/ProgressBar'
import { BpLink } from '../../../../../../shared/components/text/link/BpLink'
import { BpTable } from '../../../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../../../shared/types/TableResponse.type'
import { IBpPaginationProps } from '../../../../../../shared/components/cards/pagination/BpPagination'
import { IPagination } from '../../../../../../shared/interfaces/Pagination.interface'
import { usePreviousValue } from '../../../../../../shared/hooks/UsePreviousValue'
import { useSorter } from '../../../../../../shared/hooks/UseSorter'
import { ExportDrop } from '../../../../../../shared/components/cards/export-drop/ExportDrop'
import { BpTooltip } from '../../../../../../shared/components/cards/tooltip/BpTooltip'
import { useTranslation } from 'react-i18next'

const MAX_CHECKED_ROWS = 5

export const CompetitorsReportTable = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const researchFilters = useAppSelector<IResearchFilter>(selectResearchFilters)
  const competitorsFilter = useAppSelector<ICompetitorsFilter>(selectCompetitorsFilter)
  const pagination = useAppSelector<IPagination>(selectCompetitorsPagination)
  const { current, pageSize, total } = pagination
  const [countRows, setCountRows] = useState<number>(0)
  const [queryParams, setQueryParams] = useState<ICompetitorsParams>({
    page: current,
    'per-page': pageSize,
  })
  const {
    sites,
    dateRange: { date },
  } = competitorsFilter
  const { project, country, domain } = researchFilters
  const { data, isLoading, isFetching } = useGetCompetitorsQuery(queryParams, {
    skip: !queryParams?.project_ids?.length,
  })
  const previousProject = usePreviousValue(project)
  const [exportFile, { isLoading: exportCompetitorsLoading }] = useExportCompetitorsMutation()

  useEffect(() => {
    if (!data?.data) return
    setCountRows(data.data.total)

    if (!sites) {
      dispatch(setCompetitorsSites(data.data.items.slice(0, 5).map((item) => item.id)))
    }
  }, [data])

  useEffect(() => {
    const project_ids = project ? [project] : []

    setQueryParams({
      ...queryParams,
      project_ids,
      'country_ids[]': country,
      domain,
      date,
    })
  }, [researchFilters, competitorsFilter])

  useEffect(() => {
    if (previousProject && project && previousProject !== project) {
      dispatch(setCompetitorsSites(undefined))
      dispatch(
        setCompetitorsPagination({
          ...pagination,
          current: 1,
        }),
      )
    }
  }, [project])

  const columns: ColumnsType<ICompetitorModel> = [
    {
      title: t('table.columns.domain'),
      dataIndex: 'domain',
      key: 'domain',
      render: (_: string, record: ICompetitorModel) => {
        return (
          <BpAnchor target='_blank' href={`https://${record.domain}`}>
            {record.domain}
          </BpAnchor>
        )
      },
    },
    {
      title: () => (
        <span className='flex items-center gap-[8px]'>
          {t('table.columns.visibility')}{' '}
          <BpTooltip title={t('pages.advertisingResearch.visibilityTooltip')} />
        </span>
      ),
      dataIndex: 'visibility',
      key: 'visibility',
      render: (_: number, record: ICompetitorModel) => {
        return <ProgressBar className='w-[115px]' percent={record.visibility} />
      },
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: () => (
        <span className='flex items-center gap-[8px]'>
          {t('table.columns.ads')} <BpTooltip title={t('pages.advertisingResearch.adsTooltip')} />
        </span>
      ),
      dataIndex: 'ads_count',
      key: 'ads_count',
      render: (_: string, record: ICompetitorModel) => {
        return (
          <BpLink to={`ads-domain/${record.project_id}/${record.domain}`}>
            {record.ads_count}
          </BpLink>
        )
      },
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: t('table.columns.topKeyword'),
      dataIndex: 'top_keyword',
      key: 'top_keyword',
      render: (_: string, record: ICompetitorModel) => {
        return (
          <BpLink to={`ads-keyword/${record.project_id}/${record.top_keyword}`}>
            {record.top_keyword}
          </BpLink>
        )
      },
    },
    {
      title: t('table.columns.lastUpdate'),
      key: 'last_update',
      dataIndex: 'last_update',
    },
  ]

  const rowSelection: TableRowSelection<ICompetitorModel> = {
    selectedRowKeys: sites,
    hideSelectAll: true,
    onSelect: (record, selected) => {
      if (selected) {
        !sites?.includes(record.id) &&
          dispatch(setCompetitorsSites(sites ? [...sites, record.id] : [record.id]))
      } else {
        dispatch(setCompetitorsSites(sites?.filter((row) => row !== record.id)))
      }
    },
    getCheckboxProps: (record) => {
      return {
        disabled: sites && !sites?.includes(record.id) && sites?.length >= MAX_CHECKED_ROWS,
      }
    },
  }

  const handleTableChange = (
    { pageSize, current, total }: IBpPaginationProps,
    sorter?: SorterResult<ICompetitorModel> | SorterResult<ICompetitorModel>[],
  ) => {
    dispatch(
      setCompetitorsPagination({
        pageSize: pageSize as number,
        current: current as number,
        total: total as number,
      }),
    )
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': pageSize,
      sort: useSorter(sorter),
    })
  }

  return (
    <section>
      <div className='mb-[16px] flex flex-col md:flex-row justify-between md:items-center gap-[16px]'>
        <h3 className='font-medium'>
          {t('pages.advertisingResearch.allAdvertisers')}: {countRows}
        </h3>
        <ExportDrop
          onExport={(exportParams) => exportFile({ ...queryParams, ...exportParams })}
          loading={exportCompetitorsLoading}
          columns={[
            { label: t('table.columns.domain'), keys: ['domain'] },
            { label: t('table.columns.visibility'), keys: ['visibility'] },
            { label: t('table.columns.ads'), keys: ['ads_count'] },
            { label: t('table.columns.topKeyword'), keys: ['top_keyword'] },
            { label: t('table.columns.lastUpdate'), keys: ['last_update'] },
          ]}
        />
      </div>
      <BpTable
        rowSelection={rowSelection}
        dataSource={data?.data?.items}
        rowKey={(record) => record.id}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<ICompetitorModel>}
        pagination={{
          current,
          pageSize,
          total,
        }}
      />
    </section>
  )
}
