import { CommonAuthSuccess, ICommonAuthSuccessPageProps } from '../utility/CommonAuthSuccess'

export const ContactSuccess = () => {
  const props: ICommonAuthSuccessPageProps = {
    title: <>Thank you for your request</>,
    paragraph: <>We’ll be in touch shortly.</>,
  }
  return (
    <>
      <CommonAuthSuccess {...props} />
    </>
  )
}
