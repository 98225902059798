import React, { useEffect } from 'react'
import { useGetAdsQuery } from '../../../../../api/ad-overview/AdOverview.api'
import { IAdsParams } from '../../../../../api/ad-overview/interfaces/AdsParams.interface'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import {
  selectAdOverviewAdsExpanded,
  selectAdOverviewAdsParams,
  selectAdOverviewFilters,
  setAdsQueryParams,
  setAllowDecloak,
} from '../../store/AdOverviewSlice'
import { IAdOverviewFilter } from '../../interfaces/AdOverviewFilter.interface'
import { BpTable } from '../../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../../shared/types/TableResponse.type'
import { IAdModel } from '../../../../../api/ad-overview/models/Ad.model'
import { ColumnsType } from 'antd/lib/table'
import { BpArticle } from '../../../../../shared/components/cards/article/BpArticle'
import { IBpPaginationProps } from '../../../../../shared/components/cards/pagination/BpPagination'
import { DetailsCard } from '../../../../../shared/components/cards/details/DetailsCard'
import { ToggleList } from '../../../../../shared/components/cards/toggle-list/ToggleList'
import { LinksList } from '../LinksList'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../../shared/hooks/UseSorter'
import { IWithExpandedAllProps, withExpanded } from '../../../../../shared/hoc/WithExpanded'
import { useTranslation } from 'react-i18next'

const Table = ({
  onToggleExpand,
  isExpandAll,
  expandedRowKeys,
  onToggleExpandAll,
}: IWithExpandedAllProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isExpanded = useAppSelector(selectAdOverviewAdsExpanded)
  const adOverviewFilter = useAppSelector<IAdOverviewFilter>(selectAdOverviewFilters)
  const {
    project,
    dateRange: date,
    country,
    adDomain: site,
    search,
    engine,
    browser,
    city,
    policy,
    affiliate,
    is_decloak,
    group,
  } = adOverviewFilter

  const queryParams = useAppSelector(selectAdOverviewAdsParams)
  const { data, isLoading, isFetching } = useGetAdsQuery(queryParams as IAdsParams, {
    skip: !project || queryParams == undefined,
  })

  useEffect(() => {
    if (!project) return

    dispatch(
      setAdsQueryParams({
        page: 1,
        'per-page': 10,
        project_id: +project,
        country_id: country ? +country : undefined,
        date,
        search,
        site,
        engine_id: engine ? +engine : undefined,
        browser_id: browser ? +browser : undefined,
        city_id: city ? +city : undefined,
        policy,
        affiliate,
        is_decloak,
        group,
      }),
    )
  }, [adOverviewFilter])

  useEffect(() => {
    if (onToggleExpandAll) onToggleExpandAll(isExpanded)
  }, [isExpanded])

  useEffect(() => {
    if (data?.data?.allow_decloak !== undefined) {
      dispatch(setAllowDecloak(data.data.allow_decloak))
    }
  }, [data])

  const columns: ColumnsType<IAdModel> = [
    {
      title: t('table.columns.ad'),
      key: 'text',
      render: (_: string, { title, text, finish_domain }: IAdModel) => {
        return <BpArticle title={title} text={text} link={finish_domain} />
      },
      width: '400px',
    },
    {
      title: t('table.columns.keyword(s)'),
      dataIndex: 'keywords',
      key: 'keywords',
      render: (keywords: string) => {
        return keywords.split(', ').slice(-10).join(', ')
      },
    },
    {
      title: t('table.columns.redirectLinks'),
      dataIndex: 'redirects',
      key: 'redirects',
      width: '150px',
      render: (redirects: string[], record: IAdModel) => {
        const allRedirects = [
          ...redirects,
          ...(record.additionalRedirects ? record.additionalRedirects.flat() : []),
        ]
        return (
          <ToggleList
            list={allRedirects}
            title={t('pages.adOverview.links')}
            onToggle={(isShow) => {
              if (onToggleExpand) onToggleExpand(isShow, `${record.id}`)
            }}
          />
        )
      },
    },
    {
      title: t('table.columns.position'),
      dataIndex: 'position_global',
      key: 'position_global',
      width: '100px',
      sorter: true,
    },
    {
      title: t('table.columns.details'),
      key: 'details',
      width: '320px',
      render: (_: IAdModel, record: IAdModel) => {
        return <DetailsCard {...record} />
      },
    },
    {
      title: t('table.columns.lastFound'),
      dataIndex: 'last_found',
      key: 'last_found',
      width: '150px',
      sorter: true,
    },
  ]

  const handleTableChange = (
    { current, pageSize }: IBpPaginationProps,
    sorter?: SorterResult<IAdModel> | SorterResult<IAdModel>[],
  ) => {
    if (queryParams) {
      dispatch(
        setAdsQueryParams({
          ...queryParams,
          page: current,
          'per-page': pageSize,
          sort: useSorter(sorter),
        }),
      )
    }
  }

  return (
    <BpTable
      dataSource={data?.data?.items}
      rowKey={(record) => `${record.id}`}
      columns={columns}
      loading={isLoading || isFetching}
      onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
      data={data as TTableResponse<IAdModel>}
      rowClassName={(e) => (e.is_decloak ? 'cloaca' : '')}
      expandable={{
        expandedRowRender: (record) => <LinksList {...record} className='max-w-[980px]' />,
        expandedRowKeys: isExpandAll
          ? data?.data?.items.map((item) => `${item.id}`)
          : expandedRowKeys,
        showExpandColumn: false,
        expandedRowClassName: () => 'links-list',
      }}
    ></BpTable>
  )
}

export const AdsReportTable = withExpanded(Table)
