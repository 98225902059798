import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPpcComplianceFilter } from '../interfaces/PpcComplianceFilter.interface'
import { RootState } from '../../../../store/Store'
import { useDateRanges } from '../../../../shared/hooks/UseDateRanges'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'

type PpcComplianceState = {
  filters: IPpcComplianceFilter
  pagination: IPagination
  counter?: { [key: number]: string }
}

const dateRanges = useDateRanges()

const ppcComplianceSlice = createSlice({
  name: 'ppcCompliance',
  initialState: {
    filters: {
      dateRange: dateRanges.month,
      adHijackers: false,
      hidden: false,
      site: '',
      status: 1,
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 1,
    },
  } as PpcComplianceState,
  reducers: {
    setPpcComplianceFilter: (
      state: PpcComplianceState,
      { payload }: PayloadAction<IPpcComplianceFilter>,
    ) => {
      state.filters = { ...state.filters, ...payload }
    },
    setPpcComplianceStatus: (state: PpcComplianceState, { payload }: PayloadAction<number>) => {
      state.filters = { ...state.filters, status: payload }
    },
    setPpcCompliancePagination: (state: PpcComplianceState, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload
    },
    setPpcComplianceCounter: (
      state: PpcComplianceState,
      action: PayloadAction<{ [key: number]: string } | undefined>,
    ) => {
      state.counter = action.payload
    },
  },
})

export const {
  setPpcComplianceFilter,
  setPpcCompliancePagination,
  setPpcComplianceStatus,
  setPpcComplianceCounter,
} = ppcComplianceSlice.actions

export const selectPpcComplianceFilter = (state: RootState) => state.root.ppcCompliance.filters
export const selectPpcCompliancePagination = (state: RootState) =>
  state.root.ppcCompliance.pagination

export const selectPpcComplianceCounter = (state: RootState) => state.root.ppcCompliance.counter

export default ppcComplianceSlice.reducer
