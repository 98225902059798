import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconZap = ({ color = '#A059FF', className }: IIconProps) => {
  return (
    <svg
      className={`${className ? className : 'w-full'}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.0026 11.6667H2.33594M4.33594 8H1.33594M6.0026 4.33333H2.66927M11.3359 2L6.93834 8.15667C6.74367 8.4292 6.64635 8.5654 6.65056 8.679C6.65423 8.77793 6.70167 8.87007 6.78 8.93053C6.87 9 7.0374 9 7.37234 9H10.6693L10.0026 14L14.4002 7.84333C14.5949 7.5708 14.6922 7.4346 14.688 7.321C14.6843 7.22207 14.6369 7.12993 14.5585 7.06947C14.4685 7 14.3011 7 13.9662 7H10.6693L11.3359 2Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
