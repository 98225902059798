import { IOption } from '../../../../shared/components/forms/search-input/BpSearchInput'

export const StatusOptions: IOption[] = [
  {
    text: 'Unprocessed',
    value: 'unprocessed',
  },
  {
    text: 'In process',
    value: 'in_process',
  },
  {
    text: 'Completed',
    value: 'completed',
  },
]
