import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconCross = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15 5L5 15M5 5L15 15'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
