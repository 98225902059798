import Highcharts, { Options, SeriesAreasplineOptions } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import { IStatsModel } from '../../../../api/overview/models/OverviewStats.model'
import { BpTooltip } from '../tooltip/BpTooltip'
import { useTranslation } from 'react-i18next'

export interface IStatPreviewParams {
  theme?: 'orange' | 'green' | 'blue' | 'red' | 'aqua'
  title: string
  value: number
  series: SeriesAreasplineOptions & IStatsModel
  className?: string
  tooltip?: string
}

export const StatPreview = (props: IStatPreviewParams) => {
  const { t } = useTranslation()
  const { theme = 'orange', title, value, series, className, tooltip } = props

  return (
    <div
      className={`      
      rounded-[13px]
      overflow-hidden
      flex
      flex-col
      justify-between
      ${theme == 'orange' && 'bg-orange/[0.07] text-orange'}
      ${theme == 'green' && 'bg-success/[0.07] text-success'}
      ${theme == 'blue' && 'bg-focus-500/[0.07] text-focus-500'}
      ${theme == 'red' && 'bg-danger/[0.07] text-danger'}
      ${theme == 'aqua' && 'bg-aqua/[0.07] text-aqua'}
      ${className}
    `}
    >
      <div className='p-[10px] md:p-[16px] pb-[0] md:pb-[0]'>
        <div className='text-[16px] leading-[24px] font-medium truncate flex items-center gap-[8px]'>
          {title}
          {tooltip && <BpTooltip title={tooltip} />}
        </div>
        <div className='flex flex-col md:flex-row md:gap-[6px]'>
          <span className='text-[30px] leading-[26px] md:text-[36px] md:leading-[32px]'>
            {value}
          </span>{' '}
          <span className='text-[14px] font-light'>{t('other.for30Days')}</span>
        </div>
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={
          {
            chart: {
              type: 'areaspline',
              spacing: [0, 0, 0, 0],
              backgroundColor: 'transparent',
              height: 48,
              marginBottom: 0,
            },
            title: {
              text: '',
            },
            tooltip: {
              xDateFormat: '%A, %e %b',
            },
            subtitle: {
              text: '',
            },
            series: [series],
            legend: { enabled: false, padding: 0 },
            xAxis: {
              labels: {
                enabled: false,
              },
              gridLineColor: 'transparent',
              tickColor: 'transparent',
              lineColor: 'transparent',
              maxPadding: 0,
              minPadding: 0,
              type: 'datetime',
              startOfWeek: 0,
            },
            yAxis: {
              labels: {
                enabled: false,
              },
              title: undefined,
              gridLineColor: 'transparent',
              tickColor: 'transparent',
              lineColor: 'transparent',
              maxPadding: 0,
              minPadding: 0,
            },
            credits: {
              enabled: false,
            },
            plotOptions: {
              areaspline: {
                lineColor: (() => {
                  switch (theme) {
                    case 'orange':
                      return 'rgba(244, 140, 6, 0.3)'
                    case 'green':
                      return 'rgba(48, 151, 0, 0.3)'
                    case 'blue':
                      return 'rgba(0, 50, 109, 0.3)'
                    case 'red':
                      return 'rgba(220, 47, 2, 0.3)'
                    case 'aqua':
                      return 'rgba(44, 125, 160, 0.3)'
                  }
                })(),
                fillColor: (() => {
                  switch (theme) {
                    case 'orange':
                      return 'rgba(244, 140, 6, 0.1)'
                    case 'green':
                      return 'rgba(48, 151, 0, 0.1)'
                    case 'blue':
                      return 'rgba(0, 50, 109, 0.1)'
                    case 'red':
                      return 'rgba(220, 47, 2, 0.1)'
                    case 'aqua':
                      return 'rgba(44, 125, 160, 0.1)'
                  }
                })(),
              },
              series: {
                marker: {
                  enabled: false,
                  states: {
                    hover: {
                      enabled: false,
                    },
                  },
                },
              },
            },
            time: {
              useUTC: true,
            },
          } as Options
        }
      />
    </div>
  )
}
