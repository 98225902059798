import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconHorn = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.33268 10.6663L4.64489 15.9152C4.68179 16.0628 4.70024 16.1366 4.72207 16.201C4.93532 16.8306 5.50228 17.2733 6.16478 17.3275C6.2326 17.333 6.30868 17.333 6.46084 17.333C6.65138 17.333 6.74666 17.333 6.82692 17.3253C7.62018 17.2483 8.24797 16.6205 8.3249 15.8273C8.33268 15.747 8.33268 15.6517 8.33268 15.4612V3.58301M15.416 10.2497C17.0268 10.2497 18.3327 8.94384 18.3327 7.33302C18.3327 5.72218 17.0268 4.41635 15.416 4.41635M8.54102 3.58301H5.41602C3.34495 3.58301 1.66602 5.26195 1.66602 7.33302C1.66602 9.40409 3.34495 11.083 5.41602 11.083H8.54102C10.013 11.083 11.8137 11.8721 13.2029 12.6294C14.0133 13.0712 14.4186 13.2921 14.684 13.2596C14.9301 13.2294 15.1162 13.1189 15.2604 12.9173C15.416 12.6998 15.416 12.2647 15.416 11.3944V3.27159C15.416 2.40135 15.416 1.96622 15.2604 1.74876C15.1162 1.5471 14.9301 1.4366 14.684 1.40646C14.4186 1.37395 14.0133 1.59485 13.2029 2.03665C11.8137 2.79394 10.013 3.58301 8.54102 3.58301Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
