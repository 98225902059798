import { ExportDrop } from '../../../../../shared/components/cards/export-drop/ExportDrop'
import React from 'react'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { selectAdOverviewAffParams } from '../../store/AdOverviewSlice'
import { useExportAffiliatesMutation } from '../../../../../api/export-reports/ExportReports.api'
import { useTranslation } from 'react-i18next'

export const AffControlPanel = () => {
  const { t } = useTranslation()
  const queryParams = useAppSelector(selectAdOverviewAffParams)
  const [exportFile, { isLoading }] = useExportAffiliatesMutation()

  return (
    <>
      <ExportDrop
        onExport={(exportParams) => exportFile({ ...queryParams, ...exportParams })}
        loading={isLoading}
        columns={[
          {
            label: t('table.columns.affiliates'),
            bold: true,
            child: [
              { label: t('table.columns.affiliateId'), keys: ['affiliate_id'] },
              { label: t('table.columns.adsAnalyzed'), keys: ['count'] },
              { label: t('table.columns.lastUpdated'), keys: ['last_updated'] },
              { label: t('table.columns.firstUpdated'), keys: ['first_updated'] },
              { label: t('table.columns.comment'), keys: ['comment'] },
            ],
          },
          {
            keys: ['latest_examples'],
            label: t('buttons.latestExamples'),
            tooltip: t('buttons.other.latestExamplesTooltip'),
            bold: true,
          },
        ]}
      />
    </>
  )
}
