import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconCheckBold = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.05131 0.434632C7.36373 0.122213 7.87027 0.122213 8.18268 0.434632C8.4951 0.747051 8.4951 1.25358 8.18268 1.566L3.38269 6.366C3.07027 6.67842 2.56373 6.67842 2.25131 6.366L0.234315 4.349C-0.0781049 4.03658 -0.0781049 3.53005 0.234315 3.21763C0.546734 2.90521 1.05327 2.90521 1.36569 3.21763L2.817 4.66895L7.05131 0.434632Z'
        fill={color}
      />
    </svg>
  )
}
