import { CommonAuthPage } from '../utility/CommonAuthPage'
import { IResetPasswordFormData, ResetPasswordForm } from './form/ResetPasswordFrom'
import { useLazyRequestPasswordResetQuery } from '../../../api/site/Site.api'
import { SubmitHandler } from 'react-hook-form'
import { ResetPasswordSuccess } from './success/ResetPasswordSuccess'

export const ResetPasswordPage = () => {
  const [triggerRequestResetPassword, { isFetching, isLoading, isSuccess }] =
    useLazyRequestPasswordResetQuery()

  const handleSubmit: SubmitHandler<IResetPasswordFormData> = (formData: IResetPasswordFormData) =>
    triggerRequestResetPassword(formData)

  return (
    <>
      {!isSuccess && (
        <>
          <CommonAuthPage
            form={
              <ResetPasswordForm
                submitHandler={handleSubmit}
                isFetching={isFetching || isLoading}
              ></ResetPasswordForm>
            }
          />
          <svg
            className='absolute hidden lg:block xl:right-[110px] xl:top-[35px] lg:-right-[50px] lg:top-[35px]'
            width='299'
            height='843'
            viewBox='0 0 299 843'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M181.47 30.2199L172.9 27.7392C172.9 27.7392 164.481 17.741 149.446 23.6798C134.336 29.6185 149.446 34.4297 149.446 34.4297L175.231 41.8719L181.47 30.2199Z'
              fill='#0E63D8'
            />
            <path
              d='M192.373 154.482C216.579 145.01 302.878 109.829 298.368 80.8871C296.413 68.2579 277.62 59.6881 253.865 48.8631C253.865 48.8631 236.8 41.045 179.969 27.0626C179.969 27.0626 170.572 27.5888 170.121 42.9243L248.076 79.4588L182.6 104.492L192.373 154.482Z'
              fill='#001C44'
            />
            <path
              d='M21.8012 360.534L20.6736 371.209C20.6736 371.209 10.6003 383.237 20.7488 399.925C30.8972 416.614 33.5283 397.67 33.5283 397.67L37.2118 363.691L21.8012 360.534Z'
              fill='#0E63D8'
            />
            <path
              d='M35.0322 372.111C45.4814 381.583 46.9097 384.89 46.1579 386.168C45.1055 387.897 38.4902 387.521 35.1825 382.936C32.8521 379.779 32.777 375.418 35.0322 372.111Z'
              fill='#0E63D8'
            />
            <path
              d='M68.8615 103.139C61.3441 105.244 55.3302 110.806 52.624 118.098C42.0996 146.589 16.3902 220.034 16.1646 257.546C15.8639 304.68 19.8482 364.067 19.8482 364.067H39.9196C39.9196 364.067 48.7901 269.724 49.5418 245.593C50.3688 221.538 87.655 123.511 87.655 123.511L85.7005 98.4779L68.8615 103.139Z'
              fill='#001C44'
            />
            <path
              d='M158.844 22.477C158.844 22.477 176.284 12.7044 175.081 1.8042'
              stroke='#3BFFBF'
              strokeWidth='2.632'
              strokeMiterlimit='10'
              strokeLinecap='round'
            />
            <path
              d='M158.992 21.8757C159.443 21.4246 164.63 16.5383 171.847 17.8914C178.086 19.019 181.168 23.9053 181.62 24.6571'
              stroke='#3BFFBF'
              strokeWidth='2.632'
              strokeMiterlimit='10'
              strokeLinecap='round'
            />
            <path
              d='M170.046 39.3912C160.8 46.5327 157.868 47.2845 156.966 46.4576C155.688 45.33 156.966 39.9926 161.175 37.9629C164.107 36.5346 167.64 37.0608 170.046 39.3912Z'
              fill='#FB9C98'
            />
            <path d='M59.6154 820.974H39.4688V825.86H59.6154V820.974Z' fill='#F6A19E' />
            <path
              d='M59.54 820.974C59.54 820.974 68.2601 829.393 59.54 838.189H7.44445C7.21893 837.136 6.09133 832.1 9.09828 827.063C11.5038 823.004 15.1874 821.425 16.3901 820.974C24.7344 820.974 33.0036 820.974 41.3479 820.974C41.3479 820.974 50.3687 826.311 59.54 820.974Z'
              fill='#2E3192'
            />
            <path d='M-0.0343399 842.955H61.082V838.143H-0.0343399V842.955Z' fill='#4463CD' />
            <path
              d='M16.3874 820.974C8.19343 820.974 1.57812 827.589 1.57812 835.783V838.189H9.54656C9.39621 836.385 9.39621 833.603 10.2983 830.446C11.8769 825.409 14.8839 822.327 16.3874 820.974Z'
              fill='#2E3192'
            />
            <path
              d='M43.4531 811.803L44.0545 821.726L57.5106 821.5L58.3376 811.201L43.4531 811.803Z'
              fill='#F6A19E'
            />
            <path
              d='M42.0963 815.562H60.0628L135.913 412.855L133.357 307.762L57.5069 300.169C57.5069 300.169 35.481 349.032 36.3079 423.906C36.6086 456.982 41.0438 747.304 42.0963 815.562Z'
              fill='#A059FF'
            />
            <path d='M233.983 842.932H273.75V838.12H233.983V842.932Z' fill='#4463CD' />
            <path
              d='M241.309 811.803L243.413 821.726L256.87 821.5L256.268 811.201L241.309 811.803Z'
              fill='#F6A19E'
            />
            <path
              d='M259.653 820.974C264.088 820.974 268.373 823.154 270.704 826.913C270.704 826.988 270.779 826.988 270.779 827.063C273.786 832.1 272.658 837.136 272.433 838.189H263.487H238.379C233.869 833.678 234.019 829.168 235.222 825.935C236.349 822.929 239.206 820.974 242.438 820.974H259.653Z'
              fill='#2E3192'
            />
            <path
              d='M258.823 815.562C256.944 749.709 248.148 436.084 246.57 396.091C244.765 347.83 218.83 300.094 218.83 300.094C201.616 301.748 142.379 301.598 124.036 300.094C122.533 319.339 128.471 355.046 128.998 377.899C129.449 397.895 133.884 395.64 135.763 412.855L240.18 815.562H258.823Z'
              fill='#A059FF'
            />
            <path
              d='M162.754 19.6205C162.754 19.6205 170.347 25.9351 170.572 44.2775C170.798 62.6199 156.665 61.4172 156.665 61.4172L153.508 32.4752L162.754 19.6205Z'
              fill='#3BFFBF'
            />
            <path
              d='M57.5084 300.169C55.7794 276.565 55.2531 249.352 57.5084 219.433C60.6657 176.659 69.01 130.051 77.5046 100.357C99.6809 96.2978 121.632 95.7716 144.109 90.0583L201.542 104.116C208.833 135.388 215.148 172.073 218.08 213.419C220.335 245.067 220.26 274.159 218.907 300.169C202.97 303.778 184.778 306.785 164.706 308.213C122.008 311.295 85.2475 306.409 57.5084 300.169Z'
              fill='#001C44'
            />
            <path
              d='M201.615 104.116L151.023 90.0583C151.775 98.8537 159.368 111.107 165.231 131.705C168.84 144.334 169.967 153.731 170.042 216.05C170.042 243.338 169.892 274.159 169.291 307.912C187.558 306.334 204.171 303.552 218.905 300.245C220.259 274.31 220.334 245.217 218.079 213.494C215.147 172.148 208.907 135.388 201.615 104.116Z'
              fill='#001C44'
            />
            <path
              d='M156.289 100.357L136.594 100.282C132.459 100.282 129.227 96.7488 129.527 92.6894L132.384 50.0658H160.349L163.431 92.7646C163.731 96.824 160.424 100.357 156.289 100.357Z'
              fill='#0E63D8'
            />
            <path
              d='M132.383 49.9907L130.504 77.6547C138.773 76.3768 147.193 70.5132 151.327 62.9958C153.507 59.1619 154.635 54.5763 154.86 49.9907H132.383Z'
              fill='#0E63D8'
            />
            <path
              d='M139.071 20.2219C137.868 20.4474 128.772 22.4019 123.059 30.3703C115.466 40.9699 115.391 59.9137 126.517 66.6041C137.192 73.0691 153.279 65.4765 159.669 54.0501C165.307 43.9768 164.405 28.7917 155.684 22.5523C149.52 18.1922 141.552 19.7708 139.071 20.2219Z'
              fill='#0E63D8'
            />
            <path
              d='M113.363 4.81118C109.529 11.8023 124.865 26.3109 126.368 27.7392C139.449 39.9174 160.948 47.8858 167.413 41.045C170.871 37.3615 169.668 29.8441 166.662 23.0784C165.534 20.5225 164.181 18.1921 162.677 16.1624C149.296 -1.95447 117.874 -3.45795 113.363 4.81118Z'
              fill='#3BFFBF'
            />
            <path
              d='M164.747 56.5515C167.288 54.4116 167.612 50.6175 165.472 48.0773C163.332 45.5371 159.538 45.2127 156.998 47.3527C154.458 49.4926 154.133 53.2867 156.273 55.8269C158.413 58.3671 162.207 58.6915 164.747 56.5515Z'
              fill='#001C44'
            />
            <path
              d='M162.603 16.1625C149.297 -1.87924 117.799 -3.45789 113.289 4.81124C109.455 11.8024 124.791 26.311 126.294 27.7393C126.369 27.8144 126.444 27.8896 126.52 27.8896C129.301 27.2131 132.909 26.2358 137.946 24.9578C151.703 21.6502 160.047 20.5226 164.934 19.6205C164.182 18.4177 163.43 17.2149 162.603 16.1625Z'
              fill='#3BFFBF'
            />
            <path
              d='M29.9185 823.079L24.957 828.04'
              stroke='#E1EEF7'
              strokeWidth='2.6317'
              strokeMiterlimit='10'
            />
            <path
              d='M36.9107 823.079L31.9492 828.04'
              stroke='#E1EEF7'
              strokeWidth='2.6317'
              strokeMiterlimit='10'
            />
            <path
              d='M45.7812 804.285V813.231'
              stroke='#653087'
              strokeWidth='2.6317'
              strokeMiterlimit='10'
            />
            <path
              d='M49.7656 804.285V813.231'
              stroke='#653087'
              strokeWidth='2.6317'
              strokeMiterlimit='10'
            />
            <path
              d='M53.2227 804.285V813.231'
              stroke='#653087'
              strokeWidth='2.6317'
              strokeMiterlimit='10'
            />
            <path
              d='M57.207 804.285V813.231'
              stroke='#653087'
              strokeWidth='2.6317'
              strokeMiterlimit='10'
            />
            <path
              d='M243.34 804.285V813.231'
              stroke='#653087'
              strokeWidth='2.6317'
              strokeMiterlimit='10'
            />
            <path
              d='M247.324 804.285V813.231'
              stroke='#653087'
              strokeWidth='2.6317'
              strokeMiterlimit='10'
            />
            <path
              d='M250.781 804.285V813.231'
              stroke='#653087'
              strokeWidth='2.6317'
              strokeMiterlimit='10'
            />
            <path
              d='M254.766 804.285V813.231'
              stroke='#653087'
              strokeWidth='2.6317'
              strokeMiterlimit='10'
            />
          </svg>
        </>
      )}
      {isSuccess && <ResetPasswordSuccess />}
    </>
  )
}
