import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { useGetSeoPositionChartQuery } from '../../../../../api/research/Research.api'
import { GraphReport } from '../../../../../shared/components/cards/graph-report/GraphReport'
import React from 'react'
import { BpTooltip } from '../../../../../shared/components/cards/tooltip/BpTooltip'
import {
  changeChartDataRange,
  selectPositionTrackerChart,
  selectPositionTrackerFilters,
} from '../../store/PositionTrackerSlice'
import { IPositionTrackerFilter } from '../../interfaces/IPositionTrackerFilter'
import { ISeoPositionChartParams } from '../../../../../api/research/interfaces/SeoPositionChartParams'
import { IPositionTrackerChart } from '../../interfaces/IPositionTrackerChart'
import { EDateRangeType } from '../../../research-tools/enums/DateRangeType'
import { useTranslation } from 'react-i18next'

export const PositionTrackerChart = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { project, site, country, engine_id, keyword } = useAppSelector<IPositionTrackerFilter>(
    selectPositionTrackerFilters,
  )
  const { dateRange: filter, chartIds } = useAppSelector<IPositionTrackerChart>(
    selectPositionTrackerChart,
  )
  const { date, group: date_group } = filter

  const { data } = useGetSeoPositionChartQuery(
    {
      date_group,
      date,
      project_id: +project,
      site: site,
      country_id: +country,
      engine_id: engine_id,
      keyword: keyword,
      keyword_ids: chartIds,
    } as ISeoPositionChartParams,
    { skip: project === undefined || !project.length },
  )

  return (
    <GraphReport
      title={t('pages.positionTracker.seoPositionTracker')}
      header={
        <h3 className='flex gap-3 items-center font-medium'>
          {t('pages.positionTracker.seoPositionTracker')}
          <BpTooltip title={t('pages.positionTracker.seoPositionTrackerTooltip')} />
        </h3>
      }
      filter={filter}
      series={data}
      onChange={(e) => dispatch(changeChartDataRange(e))}
      groupSelector={false}
      exceptionsDates={[EDateRangeType.WEEK]}
      reversed
      yTickInterval={1}
      yMin={1}
    />
  )
}
