import { RangePickerDateProps } from 'antd/es/date-picker/generatePicker'
import type { Moment } from 'moment'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'
import { IconAlert } from '../../icons/IconAlert'
import { IconCheck } from '../../icons/IconCheck'
import { Field, IFieldProps, TFieldTheme } from '../utility/field/Field'
import React, { useEffect, useState } from 'react'
import { IWithFieldSizeProps, withFieldSize } from '../../../hoc/WithFieldSize'
import generateRangePicker from 'antd/es/date-picker/generatePicker/generateRangePicker'
import { IconArrowRight } from '../../icons/IconArrowRight'

const RangePicker = generateRangePicker<Moment>(momentGenerateConfig)

export interface IBpDatePickerProps
  extends IWithFieldSizeProps,
    IFieldProps,
    RangePickerDateProps<Moment> {
  error?: string
  success?: string
  label?: string
  required?: boolean
}

const Picker = (props: IBpDatePickerProps) => {
  const {
    error,
    success,
    label,
    fieldSize,
    classSize,
    required = false,
    onBlur,
    onFocus,
    ...pickerProps
  } = props
  const { disabled } = pickerProps
  const [theme, setTheme] = useState<TFieldTheme>('default')
  const [message, setMessage] = useState<string>()
  const [showIcon, setShowIcon] = useState<boolean>(false)
  const [isFocus, setIsFocus] = useState<boolean>(false)

  useEffect(() => {
    if (disabled) {
      setTheme('disabled')
      setShowIcon(false)
    } else if (error) {
      setTheme('error')
      setMessage(error)
      setShowIcon(true)
    } else if (success) {
      setTheme('success')
      setMessage(success)
      setShowIcon(true)
    } else {
      setMessage('')
      setShowIcon(false)
      isFocus ? setTheme('focus') : setTheme('default')
    }
  })

  return (
    <Field theme={theme} message={message} label={label} fieldSize={fieldSize} required={required}>
      <div className='flex items-center'>
        <RangePicker
          {...pickerProps}
          className={`w-full ${classSize}`}
          bordered={false}
          separator={<IconArrowRight className={'w-[12px]'} color={'#6684a7'} />}
          onBlur={(e) => {
            setIsFocus(false)
            if (onBlur) onBlur(e)
          }}
          onFocus={(e) => {
            setIsFocus(true)
            if (onFocus) onFocus(e)
          }}
        />
        {showIcon && (
          <div className='mr-[14px] w-[17px]'>
            {theme == 'error' && <IconAlert color='#DC2F02'></IconAlert>}
            {theme == 'success' && <IconCheck color='#309700'></IconCheck>}
          </div>
        )}
      </div>
    </Field>
  )
}

export const BpDatePicker = withFieldSize(Picker)
