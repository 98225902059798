import {
  useGetCountryQuery,
  useGetDeviceTypesQuery,
  useGetLiveSearchEnginesQuery,
} from '../../../../api/filter/Filter.api'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { Controller, useForm } from 'react-hook-form'
import { IWithSearchParamsProps, withSearchParams } from '../../../../shared/hoc/WithSearchParams'
import { ILiveSearchFilters } from '../interfaces/LiveSearchFilters.interface'
import {
  selectLiveSearchApplyFilters,
  selectLiveSearchFilters,
  setLiveSearchApplyFilters,
  setLiveSearchFilter,
} from '../store/LiveSearchSlice'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { IconCountry } from '../../../../shared/components/icons/IconCountry'
import { IconSearchEngine } from '../../../../shared/components/icons/IconSearchEngine'
import { BpSearchInput } from '../../../../shared/components/forms/search-input/BpSearchInput'
import { IconPc } from '../../../../shared/components/icons/IconPc'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { IconRoundedCheck } from '../../../../shared/components/icons/IconRoundedCheck'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Filter = ({ searchParams, setSearchParams }: IWithSearchParamsProps<ILiveSearchFilters>) => {
  const { t } = useTranslation()
  const applyFilters = useAppSelector(selectLiveSearchApplyFilters)
  const { country, searchEngine, device, keyword } =
    useAppSelector<ILiveSearchFilters>(selectLiveSearchFilters)
  const dispatch = useAppDispatch()

  const {
    getValues,
    control,
    handleSubmit,
    formState: { defaultValues, isValid },
  } = useForm<ILiveSearchFilters>({
    mode: 'onChange',
    defaultValues: {
      country,
      searchEngine,
      device,
      keyword,
      ...searchParams,
    },
  })

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery({ projects_countries_priority: 1 })

  const {
    data: devices,
    isFetching: devicesFetching,
    isLoading: devicesLoading,
  } = useGetDeviceTypesQuery()

  const {
    data: engines,
    isFetching: enginesFetching,
    isLoading: enginesLoading,
  } = useGetLiveSearchEnginesQuery()

  const applyFilter = () => {
    const values = getValues()
    dispatch(setLiveSearchFilter(values))
    dispatch(setLiveSearchApplyFilters(true))
    if (setSearchParams) setSearchParams(values)
  }

  const handleChange = () => {
    const { country, searchEngine, device, keyword } = getValues()
    if (setSearchParams) setSearchParams({ country, searchEngine, device, keyword })
  }

  return (
    <form
      className='flex flex-col max-w-full gap-y-[12px] md:items-center md:flex-row md:flex-wrap md:gap-x-[8px] xl:flex-nowrap'
      onSubmit={handleSubmit(applyFilter)}
    >
      <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
        <Controller
          name='country'
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => {
            const defaultValue = defaultValues?.country
              ? defaultValues.country
              : country
              ? country
              : countries && countries[0].value

            return (
              <BpSelect
                {...fieldProps}
                options={countries}
                Icon={IconCountry}
                fieldSize='lg'
                title={t('fields.country')}
                defaultValue={defaultValue}
                loading={countriesLoading || countriesFetching}
                disabled={countriesLoading || countriesFetching}
                showSearch
                onChange={(e) => {
                  onChange(e)
                  handleChange()
                }}
              ></BpSelect>
            )
          }}
        />
      </div>
      <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
        <Controller
          name='device'
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => {
            const defaultValue = defaultValues?.device
              ? defaultValues.device
              : device
              ? device
              : devices && devices[0].value

            return (
              <BpSelect
                {...fieldProps}
                options={devices}
                Icon={IconPc}
                fieldSize='lg'
                title={t('fields.device')}
                defaultValue={defaultValue}
                loading={devicesLoading || devicesFetching}
                disabled={devicesLoading || devicesFetching}
                onChange={(e) => {
                  onChange(e)
                  handleChange()
                }}
              ></BpSelect>
            )
          }}
        />
      </div>
      <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
        <Controller
          name='searchEngine'
          control={control}
          render={({ field: { onChange, ...fieldProps } }) => {
            const defaultValue = defaultValues?.searchEngine
              ? defaultValues.searchEngine
              : searchEngine
              ? searchEngine
              : engines && 2

            return (
              <BpSelect
                {...fieldProps}
                options={engines}
                Icon={IconSearchEngine}
                fieldSize='lg'
                title={t('fields.searchEngine')}
                defaultValue={defaultValue}
                loading={enginesLoading || enginesFetching}
                disabled={enginesLoading || enginesFetching}
                onChange={(e) => {
                  onChange(e)
                  handleChange()
                }}
              ></BpSelect>
            )
          }}
        />
      </div>
      <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
        <Controller
          name='keyword'
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, ...fieldProps } }) => (
            <BpSearchInput
              {...fieldProps}
              fieldSize='lg'
              placeholder={t('fields.keyword')}
              onChange={(e) => {
                onChange(e)
                handleChange()
              }}
            ></BpSearchInput>
          )}
        />
      </div>
      <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
        <BpButton
          className='w-full'
          size='lg'
          icon={<IconRoundedCheck />}
          disabled={!isValid || applyFilters}
        >
          {t('buttons.apply')}
        </BpButton>
      </div>
    </form>
  )
}

export const LiveSearchFilters = withSearchParams<
  IWithSearchParamsProps<ILiveSearchFilters>,
  ILiveSearchFilters
>(Filter, selectLiveSearchFilters)
