import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconEye = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.61341 8.47545C1.52263 8.33165 1.47723 8.25978 1.45181 8.14891C1.43273 8.06565 1.43273 7.93431 1.45181 7.85105C1.47723 7.74018 1.52263 7.66831 1.61341 7.52451C2.36369 6.33654 4.59694 3.33331 8.00027 3.33331C11.4036 3.33331 13.6369 6.33654 14.3871 7.52451C14.4779 7.66831 14.5233 7.74018 14.5487 7.85105C14.5678 7.93431 14.5678 8.06565 14.5487 8.14891C14.5233 8.25978 14.4779 8.33165 14.3871 8.47545C13.6369 9.66345 11.4036 12.6666 8.00027 12.6666C4.59694 12.6666 2.36369 9.66345 1.61341 8.47545Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.00049 10C9.10509 10 10.0005 9.1046 10.0005 8C10.0005 6.8954 9.10509 6 8.00049 6C6.89589 6 6.00049 6.8954 6.00049 8C6.00049 9.1046 6.89589 10 8.00049 10Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
