import React, {
  ChangeEvent,
  Children,
  InputHTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from 'react'

type RadioItem = ReactElement<PropsWithChildren<InputHTMLAttributes<HTMLInputElement>>>

export interface IRadioGroupProps {
  name: string
  children: RadioItem | RadioItem[]
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  wrapped?: boolean
}

export const RadioGroup = ({ name, children, onChange, wrapped = false }: IRadioGroupProps) => {
  return (
    <div
      className={`p-[2px] ${
        wrapped && 'table bg-white shadow-outline shadow-focus rounded-[6px] flex-1'
      }`}
    >
      <div className='flex items-center'>
        {Children.map(children, (child) => React.cloneElement(child, { name, onChange }))}
      </div>
    </div>
  )
}
