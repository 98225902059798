import { Field, TFieldTheme } from '../utility/field/Field'
import { ResultDrop } from '../result-drop/ResultDrop'
import React, {
  ComponentType,
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { IWithDropdownProps, withDropdown } from '../../../hoc/WithDropdown'
import { FieldDrop } from '../utility/field-drop/FieldDrop'
import { ISearchFieldProps, SearchField } from '../utility/search-field/SearchField'
import { IWithSearchProps, withSearch } from '../../../hoc/WithSearch'
import { IInputProps } from '../utility/input/BaseInput'
import { IIconProps } from '../../../interfaces/IconProps.interface'

export interface IOption {
  text: string
  value: string
  hidden?: boolean
}

export interface IBpSearchInputProps extends IInputProps, IWithDropdownProps, IWithSearchProps {
  Icon?: ComponentType<IIconProps>
  options?: IOption[]
  label?: string
  focus?: boolean
  loading?: boolean
}

const Input = forwardRef((props: IBpSearchInputProps, ref: ForwardedRef<ISearchFieldProps>) => {
  const {
    Icon,
    fieldSize,
    options,
    focus = false,
    open,
    debouncedSearch,
    label,
    onBlur,
    onFocus,
    loading = false,
    ...inputProps
  } = props

  const [theme, setTheme] = useState<TFieldTheme>('default')
  const [isFocus, setIsFocus] = useState<boolean>(focus)

  const inputRef = useRef<any>(null)
  useImperativeHandle(ref, () => inputRef.current)

  useEffect(() => {
    if (inputProps.disabled) {
      setTheme('disabled')
    } else {
      if (isFocus) {
        setTheme('focus')
      } else {
        setTheme('default')
      }
    }
  })

  const setNativeValue = (element: HTMLInputElement, value = '') => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(
      Object.getPrototypeOf(element),
      'value',
    )?.set

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter?.call(element, value)
    } else {
      valueSetter?.call(element, value)
    }
  }

  const handleSelected = (option: IOption | IOption[] | undefined) => {
    if (!option) return

    if (Array.isArray(option)) {
      setNativeValue(
        inputRef.current,
        option.length ? option[0].text : (inputProps.defaultValue as string),
      )
    } else {
      setNativeValue(inputRef.current, option.text)
    }

    inputRef.current.dispatchEvent(new Event('input', { bubbles: true }))
  }

  return (
    <div className='relative'>
      <Field theme={theme} label={label} fieldSize={fieldSize}>
        <SearchField
          ref={inputRef}
          {...inputProps}
          Icon={Icon}
          fieldSize={fieldSize}
          onFocus={(e) => {
            setIsFocus(true)
            if (onFocus) onFocus(e)
          }}
          onBlur={(e) => {
            setIsFocus(false)
            if (onBlur) onBlur(e)
          }}
          onClear={() => {
            setNativeValue(inputRef.current, '')
            inputRef.current.dispatchEvent(new Event('input', { bubbles: true }))
          }}
        />
        {options !== undefined && (
          <FieldDrop open={open}>
            <ResultDrop
              search={debouncedSearch}
              options={options}
              pinTop={!!open}
              asList
              loading={loading}
              onSelected={handleSelected}
            ></ResultDrop>
          </FieldDrop>
        )}
      </Field>
    </div>
  )
})
export const BpSearchInput = withDropdown(withSearch(Input))
