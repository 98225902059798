import React, { Children, HTMLAttributes, useEffect, useState } from 'react'
import { ToggleBtn } from './toggle-btn/ToggleBtn'
import { Square } from '../../../../shared/components/utility/square/Square'
import { IWithDropdownProps, withDropdown } from '../../../../shared/hoc/WithDropdown'
import { useTranslation } from 'react-i18next'

const Drop = ({ children, open, onToggle }: IWithDropdownProps & HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation()
  const [isHidden, setIsHidden] = useState(!open)

  useEffect(() => {
    open ? setIsHidden(false) : setTimeout(() => setIsHidden(true), 150)
  })

  return (
    <div className='relative table select-none'>
      <div
        className={`transition-opacity duration-150 absolute right-[calc(100%_-_52px)] w-[320px] top-[-18px] ${
          open ? 'opacity-100' : 'opacity-0'
        } ${isHidden && 'invisible'}`}
      >
        <Square className='flex flex-col p-[32px] pr-[65px]'>
          <div className='text-aqua-300 text-[11px] font-medium mb-[17px] uppercase'>
            {t('navigation.mainMenu')}
          </div>
          <ul className='list-none flex-col'>
            {Children.map(children, (child) => {
              return <li className='mb-[18px] last:mb-0'>{child}</li>
            })}
          </ul>
        </Square>
      </div>
      <ToggleBtn active={open} onClick={onToggle} />
    </div>
  )
}

export const NavDrop = withDropdown(Drop)
