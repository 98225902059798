import { PropsWithChildren, ReactElement } from 'react'
import { IBpListItemProps } from './bp-list-item/BpListItem'

type TListItem = ReactElement<PropsWithChildren<IBpListItemProps>>

export interface IBpListProps {
  children: TListItem | TListItem[]
}

export const BpList = ({ children }: IBpListProps) => {
  return <ul className='flex flex-col gap-[16px]'>{children}</ul>
}
