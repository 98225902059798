import { CommonMainPage } from '../../utility/CommonMainPage'
import { BpTabs } from '../../../../shared/components/utility/tabs/BpTabs'
import { BpTab } from '../../../../shared/components/utility/tabs/tab/BpTab'
import { ScreenshotsTab } from './tabs/ScreenshotsTab'
import { HtmlTab } from './tabs/HtmlTab'
import { LinksTab } from './tabs/LinksTab'
import { AdTab } from './tabs/AdTab'
import { BlackhatMonitoringFilters } from './BlackhatMonitoringFilters'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  selectBlackhatMonitoringTab,
  setBlackhatMonitoringTab,
  TBlackhatMonitoringTabs,
} from '../store/BlackhatMonitoringSlice'
import { useTranslation } from 'react-i18next'

export const BlackhatMonitoringPage = () => {
  const { t } = useTranslation()
  const activeTab = useAppSelector(selectBlackhatMonitoringTab)
  const dispatch = useAppDispatch()

  return (
    <CommonMainPage>
      <BlackhatMonitoringFilters />
      <BpTabs
        className='mt-[19px]'
        active={activeTab}
        onToggle={(e) => dispatch(setBlackhatMonitoringTab(e as TBlackhatMonitoringTabs))}
      >
        <BpTab title={t('pages.blackhatMonitoring.screenshots')}>
          <ScreenshotsTab />
        </BpTab>
        <BpTab title={t('pages.blackhatMonitoring.HTML')}>
          <HtmlTab />
        </BpTab>
        <BpTab title={t('pages.blackhatMonitoring.links')}>
          <LinksTab />
        </BpTab>
        <BpTab title={t('pages.blackhatMonitoring.ad')}>
          <AdTab />
        </BpTab>
      </BpTabs>
    </CommonMainPage>
  )
}
