import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconShield = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_375_760)'>
        <path
          d='M7.5345 14.4099C7.6821 14.496 7.7559 14.5391 7.86004 14.5614C7.9409 14.5788 8.05877 14.5788 8.13964 14.5614C8.24377 14.5391 8.31757 14.496 8.46517 14.4099C9.76384 13.6523 13.3332 11.2723 13.3332 8.00005V5.46669C13.3332 4.75076 13.3332 4.3928 13.2228 4.13869C13.1106 3.88042 12.9989 3.74303 12.7689 3.58062C12.5426 3.42084 12.0989 3.32857 11.2116 3.14404C10.2337 2.94065 9.4827 2.57338 8.7961 2.04224C8.46684 1.78753 8.30224 1.66018 8.17337 1.62545C8.03744 1.5888 7.96224 1.5888 7.8263 1.62545C7.69744 1.66018 7.53284 1.78754 7.20357 2.04224C6.51698 2.57338 5.76597 2.94065 4.78804 3.14404C3.90075 3.32857 3.45711 3.42084 3.2308 3.58062C3.00078 3.74303 2.88903 3.88042 2.77686 4.13869C2.6665 4.3928 2.6665 4.75076 2.6665 5.46669V8.00005C2.6665 11.2723 6.23581 13.6523 7.5345 14.4099Z'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_375_760'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
