import moment from 'moment'
import { DEFAULT_DATE_FORMAT } from '../consts/DefaultRangeFormat.const'

export interface IDateRanges {
  week: string
  month: string
  threeMonths: string
  sixMonths: string
  year: string
}

export const useDateRanges = (): IDateRanges => {
  return (() => {
    const NOW_DATE = moment().format(DEFAULT_DATE_FORMAT)

    return {
      week: `${moment().subtract(1, 'weeks').format(DEFAULT_DATE_FORMAT)} - ${NOW_DATE}`,
      month: `${moment().subtract(1, 'months').format(DEFAULT_DATE_FORMAT)} - ${NOW_DATE}`,
      threeMonths: `${moment().subtract(3, 'months').format(DEFAULT_DATE_FORMAT)} - ${NOW_DATE}`,
      sixMonths: `${moment().subtract(6, 'months').format(DEFAULT_DATE_FORMAT)} - ${NOW_DATE}`,
      year: `${moment().subtract(1, 'years').format(DEFAULT_DATE_FORMAT)} - ${NOW_DATE}`,
    }
  })()
}
