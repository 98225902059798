import { CommonMainPage } from '../../utility/CommonMainPage'
import { LiveSearchFilters } from './LiveSearchFilters'
import { LiveSearchTypes } from './LiveSearchTypes'
import { LiveSearchContent } from './LiveSearchContent'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  selectLiveSearchApplyFilters,
  selectLiveSearchFilters,
  setLiveSearchApplyFilters,
} from '../store/LiveSearchSlice'
import { useLazyGetLiveQuery } from '../../../../api/research/Research.api'
import { useEffect } from 'react'
import { usePoolRequest } from '../../../../shared/hooks/UsePoolRequest'

export const LiveSearchPage = () => {
  const applyFilters = useAppSelector(selectLiveSearchApplyFilters)
  const { keyword, ...filters } = useAppSelector(selectLiveSearchFilters)
  const dispatch = useAppDispatch()

  const [getLiveSearch, { isLoading, isFetching }] = useLazyGetLiveQuery()
  const {
    start,
    data,
    isLoading: poolLoading,
    isUninitialized: poolUninitialized,
    isTimeout: poolTimeout,
  } = usePoolRequest({
    request: useLazyGetLiveQuery,
    onCancel: () => {
      dispatch(setLiveSearchApplyFilters(false))
    },
  })

  useEffect(() => {
    if (keyword && applyFilters) {
      getLiveSearch({
        deviceTypeId: filters.device,
        countryId: filters.country,
        engineId: filters.searchEngine,
        keyword: keyword,
      }).then((response) => {
        start(response?.data)
      })
    }
  }, [applyFilters])

  return (
    <CommonMainPage>
      <LiveSearchFilters />
      <LiveSearchTypes
        isLoading={(poolLoading && !poolTimeout) || isLoading || isFetching}
        disable={!data?.items?.length}
      />
      <LiveSearchContent
        data={data}
        isLoading={(poolLoading && !poolTimeout) || isLoading || isFetching}
        isUninitialized={poolUninitialized}
      />
    </CommonMainPage>
  )
}
