import { BpCard } from '../../../../shared/components/cards/card/BpCard'
import { Square } from '../../../../shared/components/utility/square/Square'
import { Controller, useForm } from 'react-hook-form'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { useEditProfileInfoMutation } from '../../../../api/profile/Profile.api'
import { FormControls } from '../../../../shared/components/forms/form-controls/FormControls'
import { IInfoModel } from '../../../../api/profile/models/Info.model'
import { useTranslation } from 'react-i18next'

export interface IEditAccountFormData {
  username: string
  fio: string
  company: string
}

export const EditAccountForm = ({ className, data }: { className?: string; data: IInfoModel }) => {
  const { t } = useTranslation()
  const [editProfile] = useEditProfileInfoMutation()

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
    register,
  } = useForm<IEditAccountFormData>({
    mode: 'onChange',
    defaultValues: { ...data },
  })

  return (
    <Square className={className}>
      <form onSubmit={handleSubmit((data: IEditAccountFormData) => editProfile(data))}>
        <BpCard
          title={t('pages.profile.editAccountInfo')}
          footer={<FormControls disabled={!isDirty || !isValid} onCancel={() => reset()} />}
        >
          <div className='flex flex-col gap-[16px]'>
            <Controller
              name='username'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <BpInput
                  {...field}
                  {...register('username', { required: t('fields.validate.required') })}
                  label={t('pages.profile.fields.nickname')}
                  placeholder={t('pages.profile.fields.enterNickname')}
                  required
                  error={error?.message}
                />
              )}
            />
            <Controller
              name='fio'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <BpInput
                  {...field}
                  {...register('fio', {
                    required: t('fields.validate.required'),
                    minLength: {
                      value: 4,
                      message: t('pages.profile.fields.fullNameMinLength'),
                    },
                  })}
                  label={t('pages.profile.fields.fullName')}
                  placeholder={t('pages.profile.fields.enterFullName')}
                  required
                  error={error?.message}
                />
              )}
            />
            <Controller
              name='company'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <BpInput
                  {...field}
                  {...register('company', {
                    required: t('fields.validate.required'),
                    minLength: {
                      value: 4,
                      message: t('pages.profile.fields.companyNameMinLength'),
                    },
                  })}
                  label={t('pages.profile.fields.companyName')}
                  placeholder={t('pages.profile.fields.enterName')}
                  required
                  error={error?.message}
                />
              )}
            />
          </div>
        </BpCard>
      </form>
    </Square>
  )
}
