import ReactDOM from 'react-dom/client'
import 'antd/dist/antd.min.css'
import './index.css'
import App from './App'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store/Store'
import './i18n'
import 'swiper/css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<App />}></Route>
      </Routes>
    </BrowserRouter>
  </Provider>,
)
