import React, { HTMLAttributes } from 'react'

export interface ISquareProps extends HTMLAttributes<HTMLElement> {
  depth?: '100' | '200' | '300'
}

export const Square = (props: ISquareProps) => {
  const { children, depth = '300', className } = props
  const shadowClass = (() => {
    switch (depth) {
      case '100':
        return 'shadow-depth-100 shadow-focus-500/[0.05]'
      case '200':
        return 'shadow-depth-200 shadow-focus-500/[0.08]'
      case '300':
        return 'shadow-depth-200 shadow-focus-500/[0.24]'
    }
  })()
  return (
    <div
      {...props}
      className={`        
        rounded-[12px]
        bg-white
        ${shadowClass}
        ${className}
      `}
    >
      {children}
    </div>
  )
}
