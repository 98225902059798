import { useEffect, useState } from 'react'

interface IData {
  items?: any[]
  data?: { redirects?: any[] }
  requestType: string
  leftAttempts: number
  hash: string
}

export interface IBody {
  wait: boolean
  dt: string
  data: IData
  timer: {
    duration: number
    interval: number
  }
  success: boolean
}

export const usePoolRequest = ({ request, onCancel }: { request: any; onCancel?: () => void }) => {
  const [body, setBody] = useState<IBody>()
  const [endTime, setEndTime] = useState<number>(Date.now() + 60000)
  const [isTimeout, setIsTimeout] = useState(false)
  const [result, setResult] = useState<IData>()

  const [get, { data, isError, isLoading, isFetching, isUninitialized }] = request()

  useEffect(() => {
    if (body?.wait) {
      get(body.data)
      setEndTime(Date.now() + (body.timer.duration || 60000))
      setIsTimeout(false)
    } else {
      onCancel && onCancel()
      setResult(body?.data)
      setIsTimeout(true)
    }
  }, [body])

  useEffect(() => {
    if (!data?.success && Date.now() < endTime && !isError) {
      setTimeout(async () => {
        data?.wait && (await get(data?.data))
      }, data?.timer.interval || 2000)
    } else {
      onCancel && onCancel()
      setResult(data)
      setIsTimeout(true)
    }
  }, [data])

  return {
    start: setBody,
    data: result,
    isLoading: data?.wait || isLoading || isFetching,
    isUninitialized,
    isTimeout,
  }
}
