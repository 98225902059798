import { useGetStatsQuery } from '../../../../../api/overview/Overview.api'
import { EDateGroup } from '../../../../../api/research/enums/DateGroup.enum'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { selectOverviewFilters } from '../../store/OverviewSlice'
import React, { useEffect, useState } from 'react'
import { IStatsParams } from '../../../../../api/overview/interfaces/StatsParams.interface'
import { useDateRanges } from '../../../../../shared/hooks/UseDateRanges'
import { StatPreview } from '../../../../../shared/components/cards/state-preview/StatPreview'
import ContentLoader from 'react-content-loader'
import { IStatsChart } from '../../../../../api/overview/interfaces/StatsChart.interface'
import { useMakeWritable } from '../../../../../shared/hooks/UseMakeWritable'
import { useTranslation } from 'react-i18next'

export const StatsPreviewList = () => {
  const { t } = useTranslation()
  const overviewFilter = useAppSelector(selectOverviewFilters)
  const [queryParams, setQueryParams] = useState<IStatsParams>()
  const { country, project } = overviewFilter
  const [currentData, setCurrentData] = useState<IStatsChart>()

  const { month: date } = useDateRanges()

  useEffect(() => {
    if (!project) return

    setQueryParams({
      project_id: +project,
      country_ids: country?.length ? country : undefined,
      date_group: EDateGroup.DAY,
      date,
    })
  }, [overviewFilter])

  const { data } = useGetStatsQuery(queryParams as IStatsParams)

  useEffect(() => {
    if (!data) return
    const { keywords, advertisers, ads, infringements, affiliates } = data
    setCurrentData({
      keywords: {
        ...keywords,
        type: 'areaspline',
        data: keywords.data ? useMakeWritable(keywords.data) : [],
      },
      ads: {
        ...ads,
        type: 'areaspline',
        data: ads.data ? useMakeWritable(ads.data) : [],
      },
      affiliates: {
        ...affiliates,
        type: 'areaspline',
        data: affiliates.data ? useMakeWritable(affiliates.data) : [],
      },
      infringements: {
        ...infringements,
        type: 'areaspline',
        data: infringements.data ? useMakeWritable(infringements.data) : [],
      },
      advertisers: {
        ...advertisers,
        type: 'areaspline',
        data: advertisers.data ? useMakeWritable(advertisers.data) : [],
      },
    })
  }, [data])

  if (currentData) {
    const { keywords, advertisers, ads, infringements, affiliates } = currentData
    return (
      <div className='flex flex-wrap w-full gap-[8px] md:gap-[16px]'>
        <StatPreview
          className='w-[calc(50%-4px)] md:w-[calc(50%-8px)] xl:flex-1'
          series={keywords}
          value={keywords.total}
          title={t('pages.overview.keywords')}
          theme='orange'
          tooltip={t('pages.overview.keywordsTooltip')}
        />
        <StatPreview
          className='w-[calc(50%-4px)] md:w-[calc(50%-8px)] xl:flex-1'
          series={advertisers}
          value={advertisers.total}
          title={t('pages.overview.advertisers')}
          theme='green'
          tooltip={t('pages.overview.advertisersTooltip')}
        />
        <StatPreview
          className='w-[calc(50%-4px)] md:w-[calc(50%-8px)] xl:flex-1'
          series={ads}
          value={ads.total}
          title={t('pages.overview.uniqueAds')}
          theme='blue'
          tooltip={t('pages.overview.uniqueAdsTooltip')}
        />
        <StatPreview
          className='w-[calc(50%-4px)] md:w-[calc(50%-8px)] xl:flex-1'
          series={infringements}
          value={infringements.total}
          title={t('pages.overview.adsAnalyzed')}
          theme='red'
          tooltip={t('pages.overview.adsAnalyzedTooltip')}
        />
        <StatPreview
          className='w-full lg:flex-1'
          series={affiliates}
          value={affiliates.total}
          title={t('pages.overview.affiliates')}
          theme='aqua'
          tooltip={t('pages.overview.affiliatesTooltip')}
        />
      </div>
    )
  }

  return (
    <div className='flex flex-wrap w-full gap-[8px] md:gap-[16px]'>
      {Array.from({ length: 5 }, (v, k) => (
        <ContentLoader
          className={`h-[129px] md:h-[120px] ${
            k == 4 ? 'w-full lg:flex-1' : 'w-[calc(50%-4px)] md:w-[calc(50%-8px)] xl:flex-1'
          }`}
          key={k}
          speed={2}
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <rect x='0' y='0' rx='13' ry='13' width='100%' height='100%' />
        </ContentLoader>
      ))}
    </div>
  )
}
