import React, { Children, PropsWithChildren, ReactElement } from 'react'
import { INavItemProps } from '../../../../shared/components/cards/nav-item/NavItem'

type NavItem = ReactElement<PropsWithChildren<INavItemProps>>

export interface IMainNavProps {
  children?: NavItem | NavItem[]
}

export const MainNav = ({ children }: IMainNavProps) => {
  return (
    <ul className='flex list-none last:mr-0 justify-center'>
      {Children.map(children, (child) => {
        return <li className='mr-[32px]'>{child}</li>
      })}
    </ul>
  )
}
