import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconGlobe = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.33203 8.00004H14.6654M1.33203 8.00004C1.33203 11.6819 4.3168 14.6667 7.9987 14.6667M1.33203 8.00004C1.33203 4.31814 4.3168 1.33337 7.9987 1.33337M14.6654 8.00004C14.6654 11.6819 11.6806 14.6667 7.9987 14.6667M14.6654 8.00004C14.6654 4.31814 11.6806 1.33337 7.9987 1.33337M7.9987 1.33337C9.66623 3.15894 10.6139 5.52806 10.6654 8.00004C10.6139 10.472 9.66623 12.8411 7.9987 14.6667M7.9987 1.33337C6.33118 3.15894 5.38353 5.52806 5.33203 8.00004C5.38353 10.472 6.33118 12.8411 7.9987 14.6667'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
