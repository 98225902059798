import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { showAlert } from './reducers/AppSlice'
import { handleCommonResponse } from '../shared/helpers/ErrorHandlingHelplers'

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const {
      payload: {
        status,
        data: { message },
      },
    } = action

    if (status !== 403) {
      api.dispatch(
        showAlert({
          message,
          type: 'error',
        }),
      )
    }
  } else if (
    typeof action?.payload == 'object' &&
    'success' in action.payload &&
    !action.payload.success
  ) {
    handleCommonResponse(action.payload, api.dispatch)
  }

  return next(action)
}
