import { IOption } from '../../search-input/BpSearchInput'
import { Chip } from '../chip/Chip'
import React from 'react'

export interface IAlsoProps {
  items?: IOption[]
  onRemove?: (value: string) => void
}

export const AlsoField = ({ items = [], onRemove }: IAlsoProps) => {
  function handleOnRemove(value: string) {
    if (onRemove) onRemove(value)
  }

  return (
    <div className='flex flex-col gap-[16px] px-[16px] py-[12px] bg-white'>
      <div className='text-aqua-300 text-[10px] font-semibold'>ALSO {items?.length} SELECTED</div>
      <div className='flex flex-wrap gap-[8px]'>
        {items.map((item: IOption) => (
          <span key={item.value} className='p-[1px] max-w-[150px]'>
            <Chip onClick={() => handleOnRemove(item.value)}>{item.text}</Chip>
          </span>
        ))}
      </div>
    </div>
  )
}
