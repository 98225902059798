import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconArrowDown = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.00391 2.66671L8.00391 13.3334M8.00391 13.3334L12.0039 9.33337M8.00391 13.3334L4.00391 9.33337'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
