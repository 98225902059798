import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import queryString from 'query-string'
import { TTableResponse } from '../../shared/types/TableResponse.type'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { LastAdModel } from './models/LastAd.model'
import { AffiliateModel } from './models/Affiliate.model'

export const affiliateApi = createApi({
  reducerPath: 'affiliate/api',
  tagTypes: ['Affiliate'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/affiliate/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    getAffiliate: build.query<TTableResponse<AffiliateModel>, any>({
      query: (params: any) => ({
        url: 'get',
        params,
      }),
      providesTags: ['Affiliate'],
    }),
    getLastAd: build.query<TTableResponse<LastAdModel>, any>({
      query: (params: any) => ({
        url: 'last-ad',
        params,
      }),
      providesTags: ['Affiliate'],
    }),
    update: build.mutation<ICommonResponse<any>, any>({
      query: ({ id, name, comment, email }: any) => ({
        url: 'update',
        method: 'POST',
        body: (() => {
          const formData = new FormData()
          formData.append('id', `${id}`)
          formData.append('name', `${name}`)
          formData.append('comment', `${comment}`)
          formData.append('email', `${email}`)
          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Affiliate'],
    }),
    updateStatus: build.mutation<ICommonResponse<any>, any>({
      query: ({ id, status_id }: any) => ({
        url: 'update',
        method: 'POST',
        body: (() => {
          const formData = new FormData()
          formData.append('id', `${id}`)
          formData.append('status_id', `${status_id}`)
          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Affiliate'],
    }),
    hide: build.mutation<ICommonResponse<any>, any>({
      query: ({ id, is_hidden }: any) => ({
        url: 'hide',
        method: 'POST',
        body: (() => {
          const formData = new FormData()
          formData.append('id', `${id}`)
          formData.append('is_hidden', `${is_hidden}`)
          return formData
        })(),
        cache: 'no-cache',
      }),
      invalidatesTags: ['Affiliate'],
    }),
  }),
})

export const {
  useGetAffiliateQuery,
  useGetLastAdQuery,
  useUpdateMutation,
  useUpdateStatusMutation,
  useHideMutation,
} = affiliateApi
