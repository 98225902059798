import { Outlet } from 'react-router-dom'
import { CommonMainPage } from '../../pages/main/utility/CommonMainPage'

export const CommonPageLayout = () => {
  return (
    <CommonMainPage>
      <Outlet />
    </CommonMainPage>
  )
}
