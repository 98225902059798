import React, { ComponentType, useState } from 'react'

export interface IWithExpandedAllProps {
  onToggleExpand?: (isShow: boolean, key: string) => void
  onExpandRow?: (isShow: boolean, key: string) => void
  onToggleExpandAll?: (isExpand: boolean) => void
  isExpandAll?: boolean
  expandedRowKeys?: string[]
}

export function withExpanded<T>(WrappedComponent: ComponentType<T & IWithExpandedAllProps>) {
  return (props: T & IWithExpandedAllProps) => {
    const [isExpandAll, setIsExpandAll] = useState<boolean>(false)
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

    const handleToggleExpand = (isShow: boolean, key: string) => {
      isShow
        ? setExpandedRowKeys([...expandedRowKeys, key])
        : setExpandedRowKeys(expandedRowKeys.filter((k) => k !== key))
    }

    const expandRow = (isShow: boolean, key: string) => {
      isShow ? setExpandedRowKeys([key]) : setExpandedRowKeys([])
    }

    return (
      <WrappedComponent
        {...props}
        onToggleExpand={handleToggleExpand}
        onToggleExpandAll={setIsExpandAll}
        onExpandRow={expandRow}
        isExpandAll={isExpandAll}
        expandedRowKeys={expandedRowKeys}
      ></WrappedComponent>
    )
  }
}
