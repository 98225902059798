import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDateRanges } from '../../../../shared/hooks/UseDateRanges'
import { IOrganicResultsFilter } from '../interfaces/IOrganicResultsFilter'
import { RootState } from '../../../../store/Store'
import { IPagination } from '../../../../shared/interfaces/Pagination.interface'

type OrganicResultsState = {
  filters: IOrganicResultsFilter
  pagination: IPagination
}

const dateRanges = useDateRanges()

const organicResultsSlice = createSlice({
  name: 'organic',
  initialState: {
    filters: {
      dateRange: dateRanges.month,
      show_affiliates: false,
      show_hidden: false,
      has_brand: false,
    },
    pagination: {
      current: 1,
      pageSize: 10,
      total: 1,
    },
  } as OrganicResultsState,
  reducers: {
    setOrganicResultsFilter: (
      state: OrganicResultsState,
      action: PayloadAction<IOrganicResultsFilter>,
    ) => {
      state.filters = {
        ...state.filters,
        ...{ ...action.payload, has_brand: state.filters.has_brand },
      }
    },
    setOrganicResultsPagination: (
      state: OrganicResultsState,
      action: PayloadAction<IPagination>,
    ) => {
      state.pagination = action.payload
    },
    setHasBrand: (state: OrganicResultsState, action: PayloadAction<boolean>) => {
      state.filters = { ...state.filters, has_brand: action.payload }
    },
  },
})

export const { setOrganicResultsFilter, setOrganicResultsPagination, setHasBrand } =
  organicResultsSlice.actions

export const selectOrganicResultsFilters = (state: RootState) => state.root.organicResults.filters
export const selectOrganicResultsPagination = (state: RootState) =>
  state.root.organicResults.pagination

export default organicResultsSlice.reducer
