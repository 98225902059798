import { Field, TFieldTheme } from '../utility/field/Field'
import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { IconAlert } from '../../icons/IconAlert'
import { IconCheck } from '../../icons/IconCheck'
import { BaseInput, IInputProps } from '../utility/input/BaseInput'
import { IconButton } from '../../buttons/icon-button/IconButton'
import { IconCrossBold } from '../../icons/IconCrossBold'

export interface IBpInputProps extends IInputProps {
  error?: string
  success?: string
  label?: string
  onClear?: () => void
}

export const BpInput = forwardRef((props: IBpInputProps, ref: ForwardedRef<IInputProps>) => {
  const { error, success, label, fieldSize, onBlur, onFocus, ...inputProps } = props
  const { disabled, required } = inputProps
  const [theme, setTheme] = useState<TFieldTheme>('default')
  const [message, setMessage] = useState<string>()
  const [showIcon, setShowIcon] = useState<boolean>(false)
  const [isFocus, setIsFocus] = useState<boolean>(false)

  useEffect(() => {
    if (disabled) {
      setTheme('disabled')
      setShowIcon(false)
    } else if (error) {
      setTheme('error')
      setMessage(error)
      setShowIcon(true)
    } else if (success) {
      setTheme('success')
      setMessage(success)
      setShowIcon(true)
    } else {
      setMessage('')
      setShowIcon(false)
      isFocus ? setTheme('focus') : setTheme('default')
    }
  })

  return (
    <Field theme={theme} message={message} label={label} fieldSize={fieldSize} required={required}>
      <div className='flex items-center'>
        <BaseInput
          {...inputProps}
          ref={ref}
          fieldSize={fieldSize}
          onBlur={(e) => {
            setIsFocus(false)
            if (onBlur) onBlur(e)
          }}
          onFocus={(e) => {
            setIsFocus(true)
            if (onFocus) onFocus(e)
          }}
        ></BaseInput>
        {showIcon && (
          <div className='mr-[14px] w-[17px]'>
            {theme == 'error' && <IconAlert color='#DC2F02'></IconAlert>}
            {theme == 'success' && <IconCheck color='#309700'></IconCheck>}
          </div>
        )}
        {props.value && props.onClear && !props.disabled && (
          <div className='mr-[14px] w-[17px]'>
            <IconButton onClick={props.onClear}>
              <IconCrossBold color='#6684A7' />
            </IconButton>
          </div>
        )}
      </div>
    </Field>
  )
})
