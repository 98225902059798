import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ICompetitorModel } from './models/Competitor.model'
import { ICompetitorsParams } from './interfaces/CompetitorsParams.interface'
import { IKeywordModel } from './models/Keyword.model'
import { IKeywordsParams } from './interfaces/KeywordsParams.interface'
import { IChartModel } from './models/Chart.model'
import { ICompetitorsChartParams } from './interfaces/CompetitorsChartParams.interface'
import { TTableResponse } from '../../shared/types/TableResponse.type'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import queryString from 'query-string'
import { IAdsParams } from './interfaces/AdsParams.interface'
import { IAdModel } from './models/Ad.model'
import { IAdvertiserModel } from './models/Advertiser.model'
import { IAdvertiserParams } from './interfaces/AdvertiserParams.interface'
import { IKeywordsChartParams } from './interfaces/KeywordsChartParams.interface'
import { SeriesLineOptions } from 'highcharts'
import { TExportAdsParams } from './types/ExportAdsParams.type'
import { IExportAdvertiserParams } from './types/ExportAdvertiserParams.type'
import { TExportCompetitorsParams } from './types/ExportCompetitorsParams.type'
import { TExportKeywordsParams } from './types/ExportKeywordsParams.type'
import { IAdvertisingModel } from './models/Advertising.model'
import { IAdvertisingParams } from './interfaces/AdvertisingParams.interface'
import { IAdvertisingAffiliatesParams } from './interfaces/AdvertisingAffiliatesParams.interface'
import { IAdvertisingAffiliatesModel } from './models/AdvertisingAffiliates.model'
import { IAdvertisingKeywordParams } from './interfaces/AdvertisingKeywordParams'
import { IAdvertisingKeywordModel } from './models/AdvertisingKeyword.model'
import { IExportAdvertisingParams } from './types/ExportAdvertisingParams.type'
import { ISeoPositionModel } from './models/SeoPosition.model'
import { ISeoPositionParams } from './interfaces/SeoPositionParams.interface'
import { ISeoPositionChartParams } from './interfaces/SeoPositionChartParams'
import { IBrandPresenceModel } from './models/BrandPresence.model'
import { IBrandPresenceParams } from './interfaces/BrandPresenceParams.interface'
import { IExportBrandPresenceParams } from './types/ExportBrandPresenceParams.type'
import { IBrandPresencePagesModel } from './models/BrandPresencePages.model'
import { IExportAdvertisingAffiliatesParams } from './types/ExportAdvertisingAffiliatesParams.type'
import { ILiveParamsInterface } from './interfaces/LiveParams.interface'
import { ILiveRedirectsParamsInterface } from './interfaces/LiveRedirectsParams.interface'

export const researchApi = createApi({
  reducerPath: 'research/api',
  tagTypes: ['Affiliate'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/research/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    getCompetitors: build.query<TTableResponse<ICompetitorModel>, ICompetitorsParams | void>({
      query: (params: ICompetitorsParams) => ({
        url: 'competitors',
        params,
      }),
    }),
    exportCompetitors: build.mutation<Blob, TExportCompetitorsParams>({
      query(params: TExportCompetitorsParams) {
        return {
          url: 'competitors',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    getCompetitorsChart: build.query<Array<SeriesLineOptions>, ICompetitorsChartParams>({
      query: (body: ICompetitorsChartParams) => ({
        url: 'competitors-chart',
        method: 'POST',
        body: (() => {
          const formData = new FormData()
          body.country_ids.forEach((country) => {
            formData.append('country_ids', `${country}`)
          })
          formData.append('chart_site_ids', `${body.chart_site_ids}`)
          body.project_ids.forEach((project) => {
            formData.append('project_ids', `${project}`)
          })
          formData.append('date', body.date)
          formData.append('date_group', body.date_group)
          return formData
        })(),
      }),
      transformResponse: (response: ICommonResponse<IChartModel[]>): Array<SeriesLineOptions> => {
        return response.data?.map((item) => ({ ...item, type: 'line' })) ?? []
      },
    }),
    getKeywords: build.query<TTableResponse<IKeywordModel>, IKeywordsParams | void>({
      query: (params: IKeywordsParams) => ({
        url: 'keywords',
        params,
      }),
    }),
    getKeywordsChart: build.query<Array<SeriesLineOptions>, IKeywordsChartParams>({
      query: (body: IKeywordsChartParams) => ({
        url: 'keywords-chart',
        method: 'POST',
        body: (() => {
          const formData = new FormData()
          body.country_ids.forEach((country) => {
            formData.append('country_ids', `${country}`)
          })
          formData.append('chart_keywords', `${body.chart_keywords}`)
          body.project_ids.forEach((project) => {
            formData.append('project_ids', `${project}`)
          })
          formData.append('date', body.date)
          formData.append('date_group', body.date_group)
          return formData
        })(),
      }),
      transformResponse: (response: ICommonResponse<IChartModel[]>): Array<SeriesLineOptions> => {
        return response.data?.map((item) => ({ ...item, type: 'line' })) ?? []
      },
    }),
    getAds: build.query<TTableResponse<IAdModel>, IAdsParams>({
      query: (params) => ({
        url: 'competitors-column',
        params,
      }),
    }),
    exportAds: build.mutation<Blob, TExportAdsParams>({
      query(params: TExportAdsParams) {
        return {
          url: 'competitors-column',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    getAdvertisers: build.query<TTableResponse<IAdvertiserModel>, IAdvertiserParams>({
      query: (params) => ({
        url: 'keywords-column-advertisers',
        params,
      }),
    }),
    exportAdvertisers: build.mutation<Blob, IExportAdvertiserParams>({
      query(params: IExportAdvertiserParams) {
        return {
          url: 'keywords-column-advertisers',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    exportKeywords: build.mutation<Blob, TExportKeywordsParams>({
      query(params: TExportKeywordsParams) {
        return {
          url: 'keywords',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    getAdvertising: build.query<TTableResponse<IAdvertisingModel>, IAdvertisingParams>({
      query: (params: IAdvertisingParams) => ({ url: 'advertising', params }),
    }),
    exportAdvertising: build.mutation<Blob, IExportAdvertisingParams>({
      query(params: IExportAdvertisingParams) {
        return {
          url: 'advertising',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    getAdvertisingAffiliates: build.query<
      TTableResponse<IAdvertisingAffiliatesModel>,
      IAdvertisingAffiliatesParams
    >({
      query: (params: IAdvertisingAffiliatesParams) => ({ url: 'advertising-affiliates', params }),
      providesTags: ['Affiliate'],
    }),
    exportAdvertisingAffiliates: build.mutation<Blob, IExportAdvertisingAffiliatesParams>({
      query(params: IExportAdvertisingAffiliatesParams) {
        return {
          url: 'advertising-affiliates',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    getAdvertisingKeyword: build.query<
      TTableResponse<IAdvertisingKeywordModel>,
      IAdvertisingKeywordParams
    >({
      query: (params: IAdvertisingKeywordParams) => ({ url: 'advertising-keyword', params }),
    }),
    getSeoPosition: build.query<TTableResponse<ISeoPositionModel>, ISeoPositionParams>({
      query: (params: ISeoPositionParams) => ({ url: 'seo-position', params }),
    }),
    exportSeoPosition: build.mutation<Blob, IExportAdvertisingParams>({
      query(params: IExportAdvertisingParams) {
        return {
          url: 'seo-position',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    getSeoPositionChart: build.query<Array<SeriesLineOptions>, ISeoPositionChartParams>({
      query: (body: ISeoPositionChartParams) => ({
        url: 'seo-position-chart',
        method: 'POST',
        body: (() => {
          const formData = new FormData()
          formData.append('date_group', body.date_group)
          formData.append('date', body.date)
          formData.append('project_id', `${body.project_id}`)
          formData.append('site', body.site)
          formData.append('country_id', `${body.country_id}`)
          formData.append('engine_id', `${body.engine_id}`)
          formData.append('keyword', body.keyword)
          formData.append('keyword_ids', `${body.keyword_ids}`)

          return formData
        })(),
      }),
      transformResponse: (response: ICommonResponse<IChartModel[]>): Array<SeriesLineOptions> => {
        return response.data?.map((item) => ({ ...item, type: 'line' })) ?? []
      },
    }),
    getBrandPresence: build.query<TTableResponse<IBrandPresenceModel>, IBrandPresenceParams>({
      query: (params: IBrandPresenceParams) => ({ url: 'brand-presence', params }),
    }),
    exportBrandPresence: build.mutation<Blob, IExportBrandPresenceParams>({
      query(params: IExportBrandPresenceParams) {
        return {
          url: 'brand-presence',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    getBrandPresencePages: build.query<
      TTableResponse<IBrandPresencePagesModel>,
      IBrandPresenceParams
    >({
      query: (params: IBrandPresenceParams) => ({ url: 'brand-presence-pages', params }),
    }),
    getLive: build.query({
      query: (params: ILiveParamsInterface) => ({ url: 'live', params }),
    }),
    getLiveRedirects: build.query({
      query: (params: ILiveRedirectsParamsInterface) => ({ url: 'live-redirects', params }),
    }),
    getLiveDecloakAuto: build.query({
      query: (params: ILiveRedirectsParamsInterface) => ({ url: 'live-decloak-auto', params }),
    }),
  }),
})

export const {
  useGetCompetitorsQuery,
  useGetKeywordsQuery,
  useGetCompetitorsChartQuery,
  useGetKeywordsChartQuery,
  useGetAdsQuery,
  useGetAdvertisersQuery,
  useExportAdsMutation,
  useExportAdvertisersMutation,
  useExportCompetitorsMutation,
  useExportKeywordsMutation,
  useGetAdvertisingQuery,
  useExportAdvertisingMutation,
  useGetAdvertisingAffiliatesQuery,
  useExportAdvertisingAffiliatesMutation,
  useGetAdvertisingKeywordQuery,
  useGetSeoPositionQuery,
  useExportSeoPositionMutation,
  useGetSeoPositionChartQuery,
  useGetBrandPresenceQuery,
  useExportBrandPresenceMutation,
  useGetBrandPresencePagesQuery,
  useLazyGetLiveQuery,
  useLazyGetLiveRedirectsQuery,
  useLazyGetLiveDecloakAutoQuery,
} = researchApi
