import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconArrowUp = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 13.3333V2.66663M8 2.66663L4 6.66663M8 2.66663L12 6.66663'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
