import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconDocument = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        clipRule='evenodd'
        d='M9.33464 1.51306V4.26675C9.33464 4.64012 9.33464 4.82681 9.4073 4.96941C9.47124 5.09485 9.57317 5.19684 9.69864 5.26076C9.84124 5.33342 10.0279 5.33342 10.4013 5.33342H13.155M5.33464 10V12M10.668 8.66671V12M8.0013 7.00004V12M13.3346 6.65885V11.4667C13.3346 12.5868 13.3346 13.1468 13.1166 13.5747C12.9249 13.951 12.619 14.257 12.2426 14.4487C11.8148 14.6667 11.2548 14.6667 10.1346 14.6667H5.86797C4.74786 14.6667 4.18781 14.6667 3.75999 14.4487C3.38366 14.257 3.0777 13.951 2.88596 13.5747C2.66797 13.1468 2.66797 12.5868 2.66797 11.4667V4.53337C2.66797 3.41327 2.66797 2.85321 2.88596 2.42539C3.0777 2.04907 3.38366 1.74311 3.75999 1.55136C4.18781 1.33337 4.74786 1.33337 5.86797 1.33337H8.00917C8.4983 1.33337 8.7429 1.33337 8.9731 1.38863C9.17717 1.43763 9.37224 1.51843 9.55124 1.62809C9.75304 1.75177 9.92597 1.92473 10.2719 2.27063L12.3974 4.39611C12.7433 4.74202 12.9162 4.91497 13.0399 5.11681C13.1496 5.29575 13.2304 5.49084 13.2794 5.69491C13.3346 5.92509 13.3346 6.16967 13.3346 6.65885Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
