import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from '../../../../store/Store'
import {IOverviewFilter} from '../interfaces/OverviewFilter.interface'
import {IDateRange} from '../../../../shared/interfaces/DateRange.interface'
import {useDateRange} from '../../../../shared/hooks/UseDateRange'
import {EDateRangeType} from '../../research-tools/enums/DateRangeType'

type OverviewState = {
  filters: IOverviewFilter
  visibilityFilter: IDateRange
  keywordsFilter: IDateRange
  competitorsFilter: IDateRange
}

const dateRange = useDateRange(EDateRangeType.MONTH)

const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    filters: {
      country: [],
      project: ''
    },
    visibilityFilter: dateRange,
    keywordsFilter: dateRange,
    competitorsFilter: dateRange,
  } as OverviewState,
  reducers: {
    setOverviewFilter: (state: OverviewState, action: PayloadAction<IOverviewFilter>) => {
      state.filters = { ...state.filters, ...action.payload }
    },
    setKeywordFilter: (state: OverviewState, action: PayloadAction<IDateRange>) => {
      state.keywordsFilter = { ...state.filters, ...action.payload }
    },
    setVisibilityFilter: (state: OverviewState, action: PayloadAction<IDateRange>) => {
      state.visibilityFilter = { ...state.filters, ...action.payload }
    },
    changeCompetitorsDateRange: (state: OverviewState, action: PayloadAction<IDateRange>) => {
      state.competitorsFilter = action.payload
    },
  },
})

export const {
  setOverviewFilter,
  setKeywordFilter,
  setVisibilityFilter,
  changeCompetitorsDateRange,
} = overviewSlice.actions

export const selectOverviewFilters = (state: RootState) => state.root.overview.filters
export const selectOverviewVisibilityFilter = (state: RootState) =>
  state.root.overview.visibilityFilter
export const selectOverviewKeywordsFilter = (state: RootState) => state.root.overview.keywordsFilter
export const selectOverviewCompetitorsFilter = (state: RootState) =>
  state.root.overview.competitorsFilter

export default overviewSlice.reducer
