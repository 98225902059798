import { Children, PropsWithChildren, ReactElement } from 'react'
import { ISimpleListItemParams } from './simple-list-item/SimpleListItem'

type listItem = ReactElement<PropsWithChildren<ISimpleListItemParams>>

export interface ISimpleListParams {
  children: listItem | listItem[]
}

export const SimpleList = ({ children }: ISimpleListParams) => {
  return (
    <div className='flex flex-wrap gap-x-[22px]'>
      {Children.map(children, (child, index) => (
        <span key={index} className='w-[calc(50%-11px)]'>
          {child}
        </span>
      ))}
    </div>
  )
}
