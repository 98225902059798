import React from 'react'
import { useDispatch } from 'react-redux'
import {
  selectOverviewFilters,
  selectOverviewKeywordsFilter,
  setKeywordFilter,
} from '../../store/OverviewSlice'
import { IDateRange } from '../../../../../shared/interfaces/DateRange.interface'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { IOverviewFilter } from '../../interfaces/OverviewFilter.interface'
import { useGetKeywordsChartQuery } from '../../../../../api/research/Research.api'
import { IKeywordsChartParams } from '../../../../../api/research/interfaces/KeywordsChartParams.interface'
import { GraphReport } from '../../../../../shared/components/cards/graph-report/GraphReport'
import { useTranslation } from 'react-i18next'

export const TopKeywordsGraph = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { country, project } = useAppSelector<IOverviewFilter>(selectOverviewFilters)
  const filter = useAppSelector<IDateRange>(selectOverviewKeywordsFilter)
  const { date, group: date_group } = filter

  const { data } = useGetKeywordsChartQuery({
    date_group,
    date,
    chart_keywords: [],
    project_ids: project ? [project] : [],
    country_ids: country ? country : [],
  } as IKeywordsChartParams)

  return (
    <GraphReport
      title={t('pages.overview.topKeywordsByAdvertisers')}
      filter={filter}
      series={data ? data.slice(0, 5) : []}
      onChange={(e) => dispatch(setKeywordFilter(e))}
    />
  )
}
