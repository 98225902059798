import { AdOverviewFilters } from './filters/AdOverviewFilters'
import { BpTab } from '../../../../shared/components/utility/tabs/tab/BpTab'
import { BpTabs } from '../../../../shared/components/utility/tabs/BpTabs'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import {
  selectAdOverviewActiveTab,
  setAdOverviewActiveTab,
  TAdOverviewTabs,
} from '../store/AdOverviewSlice'
import { AdsReportTable } from './ads-report/AdsReportTable'
import { AffiliatesReportTable } from './affiliates-report/AffiliatesReportTable'
import { AdsControlPanel } from './ads-report/AdsControlPanel'
import { AffControlPanel } from './affiliates-report/AffControlPanel'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { selectPreviousLocation } from '../../../../store/reducers/AppSlice'

export const AdOverviewPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const previousLocation = useAppSelector(selectPreviousLocation)
  const activeTab = useAppSelector(selectAdOverviewActiveTab)

  return (
    <>
      <AdOverviewFilters />
      <BpTabs
        className='mt-[19px]'
        active={activeTab}
        onToggle={(e) => dispatch(setAdOverviewActiveTab(e as TAdOverviewTabs))}
        back={() => {
          navigate(
            previousLocation ? previousLocation.pathname + previousLocation.search : '/ad-reports',
          )
        }}
      >
        <BpTab title={t('pages.adOverview.ads')} panel={<AdsControlPanel />}>
          <section className='flex flex-col gap-[50px] min-h-[550px] lg:min-h-[450px]'>
            <AdsReportTable />
          </section>
        </BpTab>
        <BpTab title={t('pages.adOverview.affiliates')} panel={<AffControlPanel />}>
          <section className='flex flex-col gap-[50px] min-h-[550px] lg:min-h-[450px]'>
            <AffiliatesReportTable />
          </section>
        </BpTab>
      </BpTabs>
    </>
  )
}
