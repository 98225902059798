import React, {ComponentType, ForwardedRef, forwardRef} from 'react'
import {URLSearchParamsInit, useSearchParams} from 'react-router-dom';
import {useAppSelector} from '../../store/hooks/Redux';
import {RootState} from '../../store/Store';

export interface IWithSearchParamsProps<F> {
  searchParams?: { [p: string]: string }
  setSearchParams?: (values: F) => void
}

export function withSearchParams<T, F>(WrappedComponent: ComponentType<T>, select: (state: RootState) => F) {
  return forwardRef((props: IWithSearchParamsProps<F> & T, ref: ForwardedRef<IWithSearchParamsProps<F> & T>) => {
    const filter = useAppSelector<F>(select);
    const [searchParams, setSearchParams] = useSearchParams();

    const params = (() => {
      const map = new Map();

      for (const key of searchParams.keys()) {
        const item = filter[key as keyof F];
        const value = Array.isArray(item) ? searchParams.getAll(key) : searchParams.get(key)
        map.set(key, value)
      }

      return Object.fromEntries(map)
    })();

    const applyFilter = (values: { [k: number]: unknown }) => {
      const params = Object.fromEntries(Object.entries(values).filter(item => item[1]));
      setSearchParams({...params} as URLSearchParamsInit)
    }

    return (
        <WrappedComponent
            {...props}
            searchParams={params}
            setSearchParams={applyFilter}
            ref={ref}
        ></WrappedComponent>
    )
  })
}
