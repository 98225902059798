export function objectToFormData(obj: any) {
  const formData = new FormData()

  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    if (Array.isArray(value)) {
      value.forEach((val, index) => {
        formData.append(`${key}[${index}]`, val)
      })
    } else {
      formData.append(key, typeof value === 'object' ? JSON.stringify(value) : value)
    }
  })

  return formData
}
