import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconEdit = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 15 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.83594 2.66665H4.03594C2.91583 2.66665 2.35578 2.66665 1.92796 2.88464C1.55163 3.07639 1.24567 3.38235 1.05392 3.75867C0.835938 4.1865 0.835938 4.74655 0.835938 5.86665V11.4667C0.835938 12.5867 0.835938 13.1468 1.05392 13.5747C1.24567 13.9509 1.55163 14.2569 1.92796 14.4487C2.35578 14.6667 2.91583 14.6667 4.03594 14.6667H9.63594C10.7561 14.6667 11.3161 14.6667 11.7439 14.4487C12.1203 14.2569 12.4262 13.9509 12.6179 13.5747C12.8359 13.1468 12.8359 12.5867 12.8359 11.4667V8.66667M4.83592 10.6667H5.95228C6.2784 10.6667 6.44147 10.6667 6.59494 10.6298C6.73094 10.5971 6.861 10.5433 6.98034 10.4702C7.11487 10.3877 7.2302 10.2724 7.4608 10.0418L13.8359 3.66665C14.3882 3.11437 14.3882 2.21894 13.8359 1.66665C13.2837 1.11437 12.3882 1.11437 11.8359 1.66665L5.46076 8.0418C5.23016 8.2724 5.11486 8.38773 5.0324 8.52227C4.9593 8.6416 4.90542 8.7716 4.87276 8.90767C4.83592 9.06113 4.83592 9.2242 4.83592 9.55033V10.6667Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
