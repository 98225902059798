import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { selectBlackhatMonitoringFilters } from '../../store/BlackhatMonitoringSlice'
import { useCompareLinksQuery } from '../../../../../api/decloak/Decloak.api'
import { Col, Row, Tooltip } from 'antd'
import React from 'react'
import { BpAnchor } from '../../../../../shared/components/text/anchor/BpAnchor'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { showAlert } from '../../../../../store/reducers/AppSlice'
import { IconCopy } from '../../../../../shared/components/icons/IconCopy'
import { Preloader } from '../../../../../shared/components/cards/preloader/Preloader'
import { useTranslation } from 'react-i18next'

const List = ({ title, links }: { title: string; links?: string[] }) => {
  const dispatch = useAppDispatch()

  return (
    <div className={'flex flex-col p-[16px] gap-[16px]'}>
      <h4 className={'font-medium'}>{title}</h4>

      <ul className='flex flex-col gap-[8px]'>
        {links?.map((link, index) => (
          <li className='flex gap-[16px] items-center' key={link}>
            <span>#{index + 1}</span>
            <Tooltip title={link} overlayClassName='md:max-w-xl lg:max-w-4xl'>
              <BpAnchor target='_blank' href={link} className='truncate'>
                {link}
              </BpAnchor>
            </Tooltip>
            <span className='flex-1'>
              <CopyToClipboard
                text={link}
                onCopy={() =>
                  dispatch(
                    showAlert({
                      message: 'Copied to clipboard',
                      type: 'success',
                      timeout: 2000,
                    }),
                  )
                }
              >
                <button>
                  <IconCopy className='w-[16px] cursor-pointer hover:opacity-50' color='#6684A7' />
                </button>
              </CopyToClipboard>
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const LinksTab = () => {
  const { t } = useTranslation()
  const { time } = useAppSelector(selectBlackhatMonitoringFilters)

  const { data, isLoading } = useCompareLinksQuery(
    { id: time },
    { selectFromResult: (state) => ({ ...state, data: state.data?.data }), skip: !time },
  )

  return time ? (
    !isLoading ? (
      <>
        <Row gutter={16}>
          <Col span={24} lg={12}>
            <div className='p-4 bg-success-400/5 text-success-400 rounded-xl mb-5 mt-5 lg:mt-0'>
              {t('pages.blackhatMonitoring.uncloaked')}
            </div>
            <List
              title={t('pages.blackhatMonitoring.SERPRedirectLinks')}
              links={data?.decloak_links}
            />
            {data?.decloak_additional_links.length ? (
              <List
                title={t('pages.blackhatMonitoring.onSiteLinks')}
                links={data?.decloak_additional_links}
              />
            ) : (
              <></>
            )}
          </Col>
          <Col span={24} lg={12}>
            <div className='p-4 bg-danger/5 text-danger rounded-xl mb-5 mt-5 lg:mt-0'>
              {t('pages.blackhatMonitoring.public')}
            </div>
            <List
              title={t('pages.blackhatMonitoring.SERPRedirectLinks')}
              links={data?.public_links}
            />
            {data?.public_additional_links.length ? (
              <List
                title={t('pages.blackhatMonitoring.onSiteLinks')}
                links={data?.public_additional_links}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </>
    ) : (
      <Preloader />
    )
  ) : (
    <div className='flex flex-col items-center justify-center gap-[40px] h-full inset-0'>
      <svg
        width='78'
        height='85'
        viewBox='0 0 78 85'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M30 77.0004L35.892 80.2736C37.0264 80.904 37.5936 81.2188 38.1944 81.3424C38.726 81.452 39.274 81.452 39.806 81.3424C40.4064 81.2188 40.9736 80.904 42.108 80.2736L48 77.0004M12 67.0004L6.29188 63.8292C5.09384 63.1636 4.49472 62.8308 4.05852 62.3572C3.67264 61.9388 3.3806 61.4424 3.20192 60.9016C3 60.2904 3 59.6052 3 58.2344V52.0004M3 32.0004V25.7661C3 24.3956 3 23.7103 3.20192 23.0991C3.3806 22.5584 3.67264 22.062 4.05852 21.6433C4.49472 21.1699 5.0938 20.8371 6.29188 20.1715L12 17.0004M30 7.00032L35.892 3.72708C37.0264 3.09684 37.5936 2.78172 38.1944 2.65816C38.726 2.54884 39.274 2.54884 39.806 2.65816C40.4064 2.78172 40.9736 3.09684 42.108 3.72708L48 7.00032M66 17.0003L71.708 20.1715C72.9064 20.8371 73.5052 21.1699 73.9416 21.6433C74.3272 22.062 74.6196 22.5584 74.798 23.0991C75 23.7103 75 24.3956 75 25.7661V32.0003M75 52.0004V58.2344C75 59.6052 75 60.2904 74.798 60.9016C74.6196 61.4424 74.3272 61.9388 73.9416 62.3572C73.5052 62.8308 72.9064 63.1636 71.708 63.8292L66 67.0004M30 37.0004L39 42.0004M39 42.0004L48 37.0004M39 42.0004V52.0004M3 22.0003L12 27.0003M66 27.0003L75 22.0003M39 72.0004V82.0004'
          stroke='#6684A7'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
      <span className='text-[24px] text-aqua-300 font-medium'>{t('other.noResultsFound')}</span>
    </div>
  )
}
