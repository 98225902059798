import { IWithSearchParamsProps, withSearchParams } from '../../../../shared/hoc/WithSearchParams'
import { ILanguageComplianceFilter } from '../interface/LanguageComplianceFilter.interface'
import {
  selectLanguageComplianceFilter,
  setLanguageComplianceFilter,
} from '../store/LanguageComplianceSlice'
import { FilterDrop } from '../../../../shared/components/cards/filter-drop/FilterDrop'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { useEffect } from 'react'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { IconFolder } from '../../../../shared/components/icons/IconFolder'
import { IconCountry } from '../../../../shared/components/icons/IconCountry'
import { BpSearchInput } from '../../../../shared/components/forms/search-input/BpSearchInput'
import { BpDatePicker } from '../../../../shared/components/forms/datepicker/BpDatePicker'
import { setCanCloseDropdown } from '../../../../store/reducers/AppSlice'
import moment from 'moment/moment'
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_RANGE_FORMAT,
} from '../../../../shared/consts/DefaultRangeFormat.const'
import { Switch } from 'antd'
import {
  useGetFilterAffiliateQuery,
  useGetFilterCountryQuery,
  useGetFilterPageQuery,
  useGetFilterProjectsQuery,
  useGetFilterWordQuery,
} from '../../../../api/language-compliance/LanguageCompliance.api'

const Filter = ({
  searchParams,
  setSearchParams,
}: IWithSearchParamsProps<ILanguageComplianceFilter>) => {
  const { project, country, url, affiliate, dateRange, restrictedWords, hidden } =
    useAppSelector<ILanguageComplianceFilter>(selectLanguageComplianceFilter)
  const dispatch = useAppDispatch()

  const {
    getValues,
    setValue,
    handleSubmit,
    control,
    formState: { defaultValues },
  } = useForm<ILanguageComplianceFilter>({
    mode: 'onChange',
    defaultValues: {
      project,
      country,
      url,
      affiliate,
      dateRange,
      restrictedWords,
      hidden,
      ...searchParams,
    },
  })

  useEffect(() => {
    applyFilter()
  }, [])

  const {
    data: projectsOptions,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetFilterProjectsQuery()

  const {
    data: countriesOptions,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetFilterCountryQuery(
    {
      project_id: project ? +project : undefined,
    },
    {
      skip: !project,
    },
  )

  const {
    data: pagesOptions,
    isFetching: pagesFetching,
    isLoading: pagesLoading,
  } = useGetFilterPageQuery(
    {
      project_id: project ? +project : undefined,
    },
    {
      skip: !project,
    },
  )

  const {
    data: affiliatesOptions,
    isFetching: affiliatesFetching,
    isLoading: affiliatesLoading,
  } = useGetFilterAffiliateQuery(
    {
      project_id: project ? +project : undefined,
    },
    {
      skip: !project,
    },
  )

  const {
    data: restrictedWordsOptions,
    isFetching: restrictedWordsFetching,
    isLoading: restrictedWordsLoading,
  } = useGetFilterWordQuery(
    {
      project_id: project ? +project : undefined,
    },
    {
      skip: !project,
    },
  )

  const applyFilter = (): void => {
    const values = getValues()
    dispatch(setLanguageComplianceFilter(values))
    if (setSearchParams) setSearchParams(values)
  }

  return (
    <>
      <form
        className='flex flex-col max-w-full gap-y-[12px] md:items-center md:flex-row md:flex-wrap md:gap-x-[8px] xl:flex-nowrap'
        onSubmit={handleSubmit(applyFilter)}
      >
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='project'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.project
                ? defaultValues.project
                : project
                ? project
                : projectsOptions && projectsOptions[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={projectsOptions}
                  Icon={IconFolder}
                  fieldSize='lg'
                  title='Project'
                  defaultValue={defaultValue}
                  loading={projectsLoading || projectsFetching}
                  disabled={projectsLoading || projectsFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)

                    if (e.target.value !== defaultValue) {
                      setValue('country', '')
                    }

                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.country
                ? defaultValues.country
                : country
                ? country
                : countriesOptions && countriesOptions[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={countriesOptions}
                  Icon={IconCountry}
                  fieldSize='lg'
                  title='Country'
                  defaultValue={defaultValue}
                  loading={countriesLoading || countriesFetching}
                  disabled={countriesLoading || countriesFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='url'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSearchInput
                {...fieldProps}
                options={pagesOptions}
                fieldSize='lg'
                placeholder='Page'
                loading={pagesLoading || pagesFetching}
                disabled={pagesLoading || pagesFetching}
                onChange={(e) => {
                  onChange(e)
                  applyFilter()
                }}
              ></BpSearchInput>
            )}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='affiliate'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSearchInput
                {...fieldProps}
                options={affiliatesOptions}
                fieldSize='lg'
                placeholder='Affiliate ID'
                loading={affiliatesLoading || affiliatesFetching}
                disabled={affiliatesLoading || affiliatesFetching}
                onChange={(e) => {
                  onChange(e)
                  applyFilter()
                }}
              ></BpSearchInput>
            )}
          />
        </div>
        <div className='flex flex-col w-full lg:w-[calc(50%-4px)] xl:w-auto'>
          <FilterDrop
            onSubmit={applyFilter}
            onClear={() => {
              setValue('restrictedWords', '')
              setValue('hidden', false)
            }}
            values={getValues(['dateRange', 'restrictedWords', 'hidden'])}
          >
            <Controller
              name='dateRange'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <BpDatePicker
                    onOpenChange={(e) => dispatch(setCanCloseDropdown(!e))}
                    label='Date'
                    onChange={(e, t) => onChange(`${t[0]} - ${t[1]}`)}
                    defaultValue={[
                      moment(value, DEFAULT_DATE_FORMAT),
                      moment(value, DEFAULT_RANGE_FORMAT),
                    ]}
                  />
                )
              }}
            />
            <Controller
              name='restrictedWords'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  options={restrictedWordsOptions}
                  Icon={IconCountry}
                  title='Restricted words'
                  loading={restrictedWordsLoading || restrictedWordsFetching}
                  disabled={restrictedWordsLoading || restrictedWordsFetching}
                  showSearch
                ></BpSelect>
              )}
            />
            <Controller
              name='hidden'
              control={control}
              render={({ field }) => (
                <div className='flex justify-between gap-[15px]'>
                  <span className='text-focus-500 text-[15px] leading-[24px]'>
                    Show hidden pages
                  </span>
                  <Switch {...field} checked={field.value} defaultChecked={defaultValues?.hidden} />
                </div>
              )}
            />
          </FilterDrop>
        </div>
      </form>
    </>
  )
}

export const LanguageComplianceFilters = withSearchParams<
  IWithSearchParamsProps<ILanguageComplianceFilter>,
  ILanguageComplianceFilter
>(Filter, selectLanguageComplianceFilter)
