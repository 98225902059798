import React, { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import {
  useExportAdvertisersMutation,
  useGetAdvertisersQuery,
} from '../../../../../api/research/Research.api'
import { useNavigate, useParams } from 'react-router-dom'
import { IconButton } from '../../../../../shared/components/buttons/icon-button/IconButton'
import { IconArrowBack } from '../../../../../shared/components/icons/IconArrowBack'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { selectCompetitorsFilter } from '../../store/CompetitorsSlice'
import { TTableResponse } from '../../../../../shared/types/TableResponse.type'
import { BpTable } from '../../../../../shared/components/cards/table/BpTable'
import { IBpPaginationProps } from '../../../../../shared/components/cards/pagination/BpPagination'
import { IAdvertiserParams } from '../../../../../api/research/interfaces/AdvertiserParams.interface'
import { IAdvertiserModel } from '../../../../../api/research/models/Advertiser.model'
import { BpAnchor } from '../../../../../shared/components/text/anchor/BpAnchor'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../../shared/hooks/UseSorter'
import { ExportDrop } from '../../../../../shared/components/cards/export-drop/ExportDrop'
import { useTranslation } from 'react-i18next'

export const AdvertisersReportTable = () => {
  const { t } = useTranslation()
  const { id, keyword } = useParams()
  const navigate = useNavigate()
  const [countRows, setCountRows] = useState<number>(0)
  const {
    dateRange: { date },
  } = useAppSelector(selectCompetitorsFilter)
  const [queryParams, setQueryParams] = useState<IAdvertiserParams>({
    page: 1,
    'per-page': 10,
    project_id: (() => {
      if (id) return Number.parseInt(id)
    })(),
    date,
    keyword,
  } as IAdvertiserParams)

  const { data, isLoading, isFetching } = useGetAdvertisersQuery(queryParams, {
    skip: keyword == undefined,
  })
  const [exportFile, { isLoading: exportAdvertiserLoading }] = useExportAdvertisersMutation()

  useEffect(() => {
    if (!data?.data) return
    setCountRows(data.data.total)
  }, [data])

  const columns: ColumnsType<IAdvertiserModel> = [
    {
      title: t('table.columns.domain'),
      dataIndex: 'finish_domain',
      key: 'finish_domain',
      render: (_: string, record: IAdvertiserModel) => {
        return (
          <BpAnchor target='_blank' href={`https://${record.finish_domain}`}>
            {record.finish_domain}
          </BpAnchor>
        )
      },
    },
    {
      title: t('table.columns.ads'),
      dataIndex: 'ads',
      key: 'ads',
      width: 150,
    },
    {
      title: t('table.columns.firstFound'),
      dataIndex: 'first_found',
      key: 'first_found',
      width: 220,
    },
    {
      title: t('table.columns.lastFound'),
      dataIndex: 'last_found',
      key: 'last_found',
      width: 220,
      sorter: true,
    },
  ]

  const handleTableChange = (
    { current, pageSize }: IBpPaginationProps,
    sorter?: SorterResult<IAdvertiserModel> | SorterResult<IAdvertiserModel>[],
  ) => {
    setQueryParams({ ...queryParams, page: current, 'per-page': pageSize, sort: useSorter(sorter) })
  }

  return (
    <section>
      <div className='mb-[16px] flex flex-col md:flex-row gap-[16px] justify-between md:items-center'>
        <span className='flex gap-[16px]'>
          <IconButton size='lg' onClick={() => navigate(-1)}>
            <IconArrowBack color='#00326D' />
          </IconButton>
          <h3 className='flex-1 font-medium'>
            {countRows} {t('pages.advertisingResearch.advertisersFor')} “{keyword}”
          </h3>
        </span>
        <ExportDrop
          onExport={(exportParams) => exportFile({ ...queryParams, ...exportParams })}
          loading={exportAdvertiserLoading}
          columns={[
            { label: t('table.columns.domain'), keys: ['finish_domain'] },
            { label: t('table.columns.ads'), keys: ['ads'] },
            { label: t('table.columns.firstFound'), keys: ['first_found'] },
            { label: t('table.columns.lastFound'), keys: ['last_found'] },
          ]}
        />
      </div>
      <BpTable
        dataSource={data?.data?.items}
        rowKey={({ finish_domain, first_found }) => `${finish_domain}${first_found}`}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<IAdvertiserModel>}
        scroll={{ x: '896px' }}
      ></BpTable>
    </section>
  )
}
