import ReactQuill, { ReactQuillProps } from 'react-quill'
import React, { useEffect, useState } from 'react'
import { Field, TFieldSize, TFieldTheme } from '../utility/field/Field'

export interface IBpTextEditorProps extends ReactQuillProps {
  label?: string
  fieldSize?: TFieldSize
  max?: number
}

export const BpTextEditor = (props: IBpTextEditorProps) => {
  const { label, fieldSize, ...field } = props
  const [theme, setTheme] = useState<TFieldTheme>('default')
  const [message, setMessage] = useState<string>()
  const [isFocus, setIsFocus] = useState<boolean>(false)

  useEffect(() => {
    if (isFocus && field.value) {
      if (props.max && field.value.length > props.max) {
        setTheme('error')
        setMessage(`Please keep text under ${props.max} characters`)
      } else {
        setTheme('default')
        setMessage('')
      }
    }
  }, [field.value])

  return (
    <Field theme={theme} message={message} label={label} fieldSize={'lg'}>
      <ReactQuill
        {...field}
        theme={'bubble'}
        className={'h-[150px]'}
        modules={{
          toolbar: [['bold', 'italic', 'underline', 'strike'], ['clean']],
        }}
        formats={['bold', 'italic', 'underline', 'strike']}
        onFocus={() => setIsFocus(true)}
      />
    </Field>
  )
}
