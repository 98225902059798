import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconAlert = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_783_1023)'>
        <path
          d='M8.00016 5.33334V8.00001M8.00016 10.6667H8.00683M1.3335 5.68184V10.3182C1.3335 10.4812 1.3335 10.5627 1.35192 10.6395C1.36825 10.7075 1.39518 10.7725 1.43174 10.8322C1.47296 10.8995 1.53062 10.9571 1.64592 11.0724L4.92774 14.3543C5.04304 14.4695 5.1007 14.5272 5.16798 14.5684C5.22762 14.605 5.29265 14.6319 5.36068 14.6483C5.4374 14.6667 5.51893 14.6667 5.68199 14.6667H10.3184C10.4814 14.6667 10.5629 14.6667 10.6396 14.6483C10.7077 14.6319 10.7727 14.605 10.8324 14.5684C10.8996 14.5272 10.9573 14.4695 11.0726 14.3543L14.3544 11.0724C14.4697 10.9571 14.5274 10.8995 14.5686 10.8322C14.6052 10.7725 14.6321 10.7075 14.6484 10.6395C14.6668 10.5627 14.6668 10.4812 14.6668 10.3182V5.68184C14.6668 5.51878 14.6668 5.43725 14.6484 5.36052C14.6321 5.2925 14.6052 5.22747 14.5686 5.16782C14.5274 5.10054 14.4697 5.04289 14.3544 4.92759L11.0726 1.64576C10.9573 1.53046 10.8996 1.47281 10.8324 1.43158C10.7727 1.39503 10.7077 1.3681 10.6396 1.35176C10.5629 1.33334 10.4814 1.33334 10.3184 1.33334H5.68199C5.51893 1.33334 5.4374 1.33334 5.36068 1.35176C5.29265 1.3681 5.22762 1.39503 5.16798 1.43158C5.1007 1.47281 5.04304 1.53046 4.92774 1.64576L1.64592 4.92759C1.53062 5.04289 1.47296 5.10054 1.43174 5.16782C1.39518 5.22747 1.36825 5.2925 1.35192 5.36052C1.3335 5.43725 1.3335 5.51878 1.3335 5.68184Z'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_783_1023'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
