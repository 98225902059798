import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconZapSearch = ({ color = '#A059FF', className }: IIconProps) => {
  return (
    <svg
      className={`${className ? className : 'w-full'}`}
      viewBox='0 0 16 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.5 8.49999H1.5M3 5.50002L0.5 5.49999M4 2.49999H0.5M5 11L6.94439 9.05556M5.55556 5.72222C5.55556 8.33022 7.66978 10.4444 10.2778 10.4444C12.8858 10.4444 15 8.33022 15 5.72222C15 3.11421 12.8858 1 10.2778 1C7.66978 1 5.55556 3.11421 5.55556 5.72222Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
