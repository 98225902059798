import { NavLink, NavLinkProps } from 'react-router-dom'
import React, { PropsWithChildren, ReactElement, useState } from 'react'
import { IIconProps } from '../../../interfaces/IconProps.interface'

type Icon = ReactElement<PropsWithChildren<IIconProps>>

export interface INavLinkItemProps extends NavLinkProps {
  icon?: Icon
  label?: string
  children: string
}

export const NavLinkItem = ({ icon, label, children, to, hidden }: INavLinkItemProps) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <NavLink
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      to={to}
      hidden={hidden}
      className={({ isActive }) =>
        `flex
        w-full
        items-center
        p-2
        rounded-[8px]
        gap-3
        transition-all
        duration-150
        text-focus-500
        hover:text-default
        hover:bg-default-200
        ${isActive && 'text-default'}
        ${isActive && 'bg-default-200'}`
      }
    >
      {({ isActive }) => (
        <>
          {icon &&
            React.cloneElement(icon, {
              color: isActive || isHover ? '#A059FF' : '#00326D',
              className: 'w-4',
            })}
          {children}
          {label && (
            <span
              className={`uppercase
              px-1
              border-[1px]
              rounded-[2px]
              text-success-400
              text-[9px]
              leading-4`}
            >
              {label}
            </span>
          )}
        </>
      )}
    </NavLink>
  )
}
