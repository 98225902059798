import { EDateRangeType } from '../../pages/main/research-tools/enums/DateRangeType'
import { EDateGroup } from '../../api/research/enums/DateGroup.enum'
import { IDateRange } from '../interfaces/DateRange.interface'
import { useDateRanges } from './UseDateRanges'

const dateRanges = useDateRanges()

export const useDateRange = (rangeType: EDateRangeType): IDateRange => {
  switch (rangeType) {
    case EDateRangeType.WEEK:
      return {
        date: dateRanges.week,
        group: EDateGroup.DAY,
      }
    case EDateRangeType.MONTH:
      return {
        date: dateRanges.month,
        group: EDateGroup.WEEK,
      }
    case EDateRangeType.THREE_MONTHS:
      return {
        date: dateRanges.threeMonths,
        group: EDateGroup.WEEK,
      }
    case EDateRangeType.SIX_MONTHS:
      return {
        date: dateRanges.sixMonths,
        group: EDateGroup.MONTH,
      }
    case EDateRangeType.YEAR:
      return {
        date: dateRanges.year,
        group: EDateGroup.MONTH,
      }
  }
}
