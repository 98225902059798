import { Controller, useForm } from 'react-hook-form'
import { BpSelect } from '../../../../shared/components/forms/select/BpSelect'
import { IconCountry } from '../../../../shared/components/icons/IconCountry'
import { useGetDevicesQuery } from '../../../../api/filter/Filter.api'
import { ILiveUncloakingFilter } from '../interfaces/LiveUncloakingFilter.interface'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/Redux'
import { BpSearchInput } from '../../../../shared/components/forms/search-input/BpSearchInput'
import { IconPc } from '../../../../shared/components/icons/IconPc'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { IconRoundedCheck } from '../../../../shared/components/icons/IconRoundedCheck'
import { IWithSearchParamsProps, withSearchParams } from '../../../../shared/hoc/WithSearchParams'
import {
  selectLiveUncloakingFilters,
  setLiveUncloakingApplyFilters,
  setLiveUncloakingExcludeIds,
  setLiveUncloakingExcludeProxy,
  setLiveUncloakingFilter,
} from '../store/LiveUncloakingSlice'
import { BpTooltip } from '../../../../shared/components/cards/tooltip/BpTooltip'
import React from 'react'
import { IconGlobe } from '../../../../shared/components/icons/IconGlobe'
import { useTranslation } from 'react-i18next'
import { useGetCountryQuery } from '../../../../api/decloak/Decloak.api'

const Filter = ({
  searchParams,
  setSearchParams,
}: IWithSearchParamsProps<ILiveUncloakingFilter>) => {
  const { t } = useTranslation()
  const { device, country, site } = useAppSelector<ILiveUncloakingFilter>(
    selectLiveUncloakingFilters,
  )
  const dispatch = useAppDispatch()

  const {
    getValues,
    handleSubmit,
    control,
    formState: { defaultValues },
  } = useForm<ILiveUncloakingFilter>({
    mode: 'onChange',
    defaultValues: { device, country, site, ...searchParams },
  })

  const applyFilter = () => {
    const values = getValues()
    dispatch(setLiveUncloakingApplyFilters(true))
    dispatch(setLiveUncloakingExcludeProxy(false))
    dispatch(setLiveUncloakingFilter(values))
    if (setSearchParams) setSearchParams(values)
  }

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery()

  const {
    data: devices,
    isFetching: devicesFetching,
    isLoading: devicesLoading,
  } = useGetDevicesQuery()

  return (
    <>
      <form
        onSubmit={handleSubmit(applyFilter)}
        onChange={() => {
          dispatch(setLiveUncloakingExcludeIds(undefined))
        }}
        className='flex flex-col max-w-full gap-y-[12px] md:items-center md:flex-row md:flex-wrap md:gap-x-[8px] xl:flex-nowrap'
      >
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='country'
            control={control}
            render={({ field }) => {
              const defaultValue = defaultValues?.country
                ? defaultValues.country
                : country
                ? country
                : countries && countries[0].value

              return (
                <BpSelect
                  {...field}
                  options={countries}
                  defaultValue={defaultValue}
                  Icon={IconCountry}
                  fieldSize='lg'
                  title={t('fields.country')}
                  loading={countriesLoading || countriesFetching}
                  disabled={countriesLoading || countriesFetching}
                  showSearch
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='device'
            control={control}
            render={({ field }) => {
              const defaultValue = defaultValues?.device
                ? defaultValues.device
                : device
                ? device
                : devices && 72

              return (
                <BpSelect
                  {...field}
                  options={devices}
                  Icon={IconPc}
                  fieldSize='lg'
                  defaultValue={defaultValue}
                  title={t('fields.device')}
                  loading={devicesLoading || devicesFetching}
                  disabled={devicesLoading || devicesFetching}
                  showSearch
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='site'
            control={control}
            render={({ field }) => {
              return (
                <BpSearchInput
                  {...field}
                  Icon={IconGlobe}
                  fieldSize='lg'
                  defaultValue={defaultValues?.site}
                  placeholder={t('fields.enterLinkToUncloak')}
                />
              )
            }}
          />
        </div>
        <div className='flex flex-col w-full lg:w-[calc(50%-4px)] xl:w-auto'>
          <BpButton size='lg' icon={<IconRoundedCheck />}>
            {t('buttons.apply')}
          </BpButton>
        </div>
        <div className='flex items-center w-full lg:w-[calc(50%-4px)] xl:w-auto'>
          <button
            type='button'
            className='text-aqua-300 font-normal px-[8px] hover:text-default'
            onClick={() => {
              const values = getValues()
              dispatch(setLiveUncloakingApplyFilters(true))
              dispatch(setLiveUncloakingExcludeProxy(true))
              dispatch(setLiveUncloakingFilter(values))
              if (setSearchParams) setSearchParams(values)
            }}
          >
            {t('buttons.tryWithNewProxy')}
          </button>
          <BpTooltip
            arrowPointAtCenter
            placement='topRight'
            title={t('pages.liveUncloaking.tryWithNewProxyTooltip')}
          />
        </div>
      </form>
    </>
  )
}

export const LiveUncloakingFilters = withSearchParams<
  IWithSearchParamsProps<ILiveUncloakingFilter>,
  ILiveUncloakingFilter
>(Filter, selectLiveUncloakingFilters)
