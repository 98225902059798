import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconSearchEngine = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.9987 13.6482C8.70636 14.2816 9.6409 14.6667 10.6654 14.6667C12.8745 14.6667 14.6654 12.8758 14.6654 10.6667C14.6654 8.82211 13.4168 7.26911 11.7186 6.80684M4.27882 6.80684C2.58065 7.26911 1.33203 8.82211 1.33203 10.6667C1.33203 12.8758 3.12289 14.6667 5.33203 14.6667C7.54116 14.6667 9.33203 12.8758 9.33203 10.6667C9.33203 10.1464 9.2327 9.64924 9.0519 9.19324M11.9987 5.33337C11.9987 7.54251 10.2078 9.33337 7.9987 9.33337C5.78956 9.33337 3.9987 7.54251 3.9987 5.33337C3.9987 3.12423 5.78956 1.33337 7.9987 1.33337C10.2078 1.33337 11.9987 3.12423 11.9987 5.33337Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
