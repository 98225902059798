import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import queryString from 'query-string'
import { IDecloakFilterParams } from './interfaces/DecloakFilterParams.interface'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { IDecloakScreenshotsModel } from './models/DecloakScreenshots.model'
import { IDecloakHtmlModel } from './models/DecloakHtml.model'
import { IDecloakLinksModel } from './models/DecloakLinks.model'
import { IDecloakAdModel } from './models/DecloakAd.model'
import { IDecloakLiveParams } from './interfaces/DecloakLiveParams.interface'
import { IDecloakLiveModel } from './models/DecloakLive.model'
import { IOption } from '../../shared/components/forms/search-input/BpSearchInput'
import { ICountryParams } from '../filter/interfaces/CountryParams.interface'
import { mapToOptions } from '../../shared/helpers/MapToOptions'

export const decloakApi = createApi({
  reducerPath: 'decloak/api',
  tagTypes: ['Decloak'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/decloak/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    filters: build.query<ICommonResponse<any>, IDecloakFilterParams>({
      query: (params: IDecloakFilterParams) => ({
        url: 'filter',
        params,
      }),
    }),
    compareScreenshots: build.query<ICommonResponse<IDecloakScreenshotsModel>, { id?: string }>({
      query: (params: { id?: string }) => ({ url: 'compare-screenshots', params }),
    }),
    compareHtml: build.query<ICommonResponse<IDecloakHtmlModel>, { id?: string }>({
      query: (params: { id?: string }) => ({ url: 'compare-html', params }),
    }),
    compareLinks: build.query<ICommonResponse<IDecloakLinksModel>, { id?: string }>({
      query: (params: { id?: string }) => ({ url: 'compare-links', params }),
    }),
    ad: build.query<ICommonResponse<IDecloakAdModel>, { id?: string }>({
      query: (params: { id?: string }) => ({ url: 'ad', params }),
    }),
    live: build.query<ICommonResponse<IDecloakLiveModel>, IDecloakLiveParams>({
      query: (params: IDecloakLiveParams) => ({ url: 'live', params }),
    }),
    getCountry: build.query<IOption[], ICountryParams | void>({
      query: (params: ICountryParams) => ({ url: 'country', params }),
      transformResponse: mapToOptions,
    }),
  }),
})

export const {
  useLazyFiltersQuery,
  useFiltersQuery,
  useCompareScreenshotsQuery,
  useCompareHtmlQuery,
  useCompareLinksQuery,
  useAdQuery,
  useLazyLiveQuery,
  useGetCountryQuery,
} = decloakApi
