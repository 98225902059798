import { CommonMainPage } from '../../utility/CommonMainPage'
import React from 'react'
import { EditAccountForm } from './EditAccountForm'
import { useGetProfileInfoQuery } from '../../../../api/profile/Profile.api'
import { ChangeEmailForm } from './ChangeEmailForm'
import { ChangePasswordForm } from './ChangePasswordlForm'
import { useTranslation } from 'react-i18next'

export const ProfileSettingsPage = () => {
  const { t } = useTranslation()
  const { data: profileInfo } = useGetProfileInfoQuery()

  return (
    <CommonMainPage>
      <h2 className='mb-[32px]'>{t('pages.profile.profileSettings')}</h2>
      <section className='flex flex-col gap-[24px] xl:gap-[32px] lg:flex-wrap lg:flex-row lg:items-start'>
        {profileInfo?.data && (
          <EditAccountForm
            data={profileInfo.data}
            className='flex-1 lg:flex-initial lg:w-[calc(50%-12px)] xl:w-auto xl:flex-1'
          />
        )}
        <ChangeEmailForm className='flex-1 lg:flex-initial lg:w-[calc(50%-12px)] xl:w-auto xl:flex-1' />
        <ChangePasswordForm className='flex-1 lg:flex-initial lg:w-[calc(50%-12px)] xl:w-auto xl:flex-1' />
      </section>
    </CommonMainPage>
  )
}
