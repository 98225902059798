import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react'
import { IWithDropdownProps, withDropdown } from '../../../hoc/WithDropdown'
import { Square } from '../../utility/square/Square'
import { NavLinkProps } from 'react-router-dom'
import { IconTriangle } from '../../icons/IconTriangle'

type NavLink = ReactElement<PropsWithChildren<NavLinkProps>>

export interface INavDropItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children: NavLink | NavLink[]
  label?: string
  active?: boolean
  theme?: 'default' | 'dark'
  size?: 'sm' | 'md' | 'lg'
  decoration?: 'dot' | 'line'
}

const classDecoration = {
  line: `after:rounded-t-sm
    after:h-[3px]
    after:bottom-0
    after:left-0
    after:right-0`,
  dot: `after:h-[6px]
    after:w-[6px]
    after:top-0
    after:bottom-0
    after:right-auto
    after:left-[-18px]
    after:m-auto
    after:rounded-t-full
    after:rounded-b-full`,
}

const Drop = ({
  children,
  label,
  active = false,
  theme = 'default',
  size = 'md',
  decoration = 'line',
  open,
  onToggle,
  hidden,
  ...divAttributes
}: IWithDropdownProps & INavDropItemProps) => {
  const [isHidden, setIsHidden] = useState(!open)
  const [isHover, setIsHover] = useState(false)

  useEffect(() => {
    open ? setIsHidden(false) : setTimeout(() => setIsHidden(true), 150)
  })

  return (
    <div
      hidden={hidden}
      onClick={onToggle}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      {...divAttributes}
      className={`py-[28px]
        cursor-pointer
        table
        group
        relative
        after:content-['']
        after:block
        after:absolute
        after:opacity-0
        after:transition-opacity
        after:duration-150
        hover:after:opacity-100
        flex-shrink
        ${classDecoration[decoration]}
        ${active && 'after:opacity-100'}
        ${!isHidden && 'after:opacity-100'} 
        ${theme == 'dark' ? 'after:bg-default' : 'after:bg-fill'} 
        ${size == 'sm' && 'py-[3px] pb-[6px]'}
        ${size == 'lg' && 'py-[13px]'}`}
    >
      <span
        className={`flex
          gap-3   
          transition-colors
          duration-150
          ${
            theme == 'default' &&
            `${!isHidden || active ? 'text-fill' : 'text-white'} group-hover:text-fill`
          }
          ${
            theme == 'dark' &&
            `${!isHidden || active ? 'text-default' : 'text-focus-500'} group-hover:text-default`
          }
          ${size == 'md' || (size == 'sm' && 'text-[16px] leading-[24px]')}
          ${size == 'lg' && 'text-[24px] leading-[32px] font-medium'}`}
      >
        {label}
        <IconTriangle
          className='w-2'
          color={!isHidden || isHover || active ? '#3BFFBF' : '#FFFFFF'}
        />
      </span>
      <div
        className={`transition-opacity
          duration-150
          absolute
          left-0
          w-[255px]
          top-[74px]
          after:content-['']
          after:block
          after:absolute
          after:top-0
          after:left-0
          after:right-0
          after:h-[3px]
          after:opacity-0
          after:transition-opacity
          after:duration-150
          after:rounded-t-sm
          hover:after:opacity-100
          flex-shrink 
          ${!isHidden && 'after:opacity-100'} 
          ${theme == 'dark' ? 'after:bg-default' : 'after:bg-fill'} 
          ${open ? 'opacity-100' : 'opacity-0'}
          ${isHidden && 'invisible'} z-10`}
      >
        <Square depth='300' className='flex flex-col px-2 py-3 rounded-t-sm'>
          {children}
        </Square>
      </div>
    </div>
  )
}

export const NavDropItem = withDropdown(Drop)
