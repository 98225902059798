import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconRoundedCheck = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.3337 9.23811V10.0048C18.3327 11.8018 17.7507 13.5504 16.6747 14.9896C15.5988 16.4289 14.0864 17.4819 12.3632 17.9914C10.6398 18.5009 8.79807 18.4397 7.11238 17.8169C5.42672 17.1942 3.98753 16.0432 3.00946 14.5357C2.03138 13.0282 1.56682 11.2449 1.68506 9.45169C1.80329 7.6586 2.498 5.95173 3.66556 4.58569C4.83312 3.21964 6.41098 2.26761 8.16382 1.87158C9.91666 1.47555 11.7506 1.65674 13.392 2.38812M18.3337 3.33336L10.0003 11.675L7.50032 9.17502'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
