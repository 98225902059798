import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconPaidResults = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.33464 7.33337H5.33464M6.66797 10H5.33464M10.668 4.66671H5.33464M13.3346 7.00004V4.53337C13.3346 3.41327 13.3346 2.85321 13.1166 2.42539C12.9249 2.04907 12.619 1.74311 12.2426 1.55136C11.8148 1.33337 11.2548 1.33337 10.1346 1.33337H5.86797C4.74786 1.33337 4.18781 1.33337 3.75999 1.55136C3.38366 1.74311 3.0777 2.04907 2.88596 2.42539C2.66797 2.85321 2.66797 3.41327 2.66797 4.53337V11.4667C2.66797 12.5868 2.66797 13.1468 2.88596 13.5747C3.0777 13.951 3.38366 14.257 3.75999 14.4487C4.18781 14.6667 4.74786 14.6667 5.86797 14.6667H7.66797'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 12.8666C10 13.5294 10.4975 14.0666 11.1111 14.0666H12.2222C12.8359 14.0666 13.3333 13.5294 13.3333 12.8666C13.3333 12.2039 12.8359 11.6666 12.2222 11.6666H11.1111C10.4975 11.6666 10 11.1294 10 10.4666C10 9.80388 10.4975 9.26663 11.1111 9.26663H12.2222C12.8359 9.26663 13.3333 9.80388 13.3333 10.4666M11.6667 8.66663V14.6666'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
