import { ByVisibilityGraph } from './by-visibility/ByVisibilityGraph'
import { CompetitorsReportTable } from './report/CompetitorsReportTable'

export const Competitors = () => {
  return (
    <section className='flex flex-col gap-[50px]'>
      <ByVisibilityGraph />
      <CompetitorsReportTable />
    </section>
  )
}
