import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconFolder = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.66683 4.66667L7.92316 3.17928C7.7091 2.7512 7.6021 2.53715 7.44243 2.38077C7.30123 2.24249 7.13103 2.13731 6.94423 2.07287C6.73296 2 6.49364 2 6.01502 2H3.46683C2.7201 2 2.34672 2 2.06151 2.14533C1.81062 2.27315 1.60665 2.47713 1.47882 2.72801C1.3335 3.01323 1.3335 3.3866 1.3335 4.13333V4.66667M1.3335 4.66667H11.4668C12.587 4.66667 13.147 4.66667 13.5748 4.88465C13.9512 5.0764 14.2571 5.38236 14.4488 5.75869C14.6668 6.18651 14.6668 6.74653 14.6668 7.86667V10.8C14.6668 11.9201 14.6668 12.4801 14.4488 12.908C14.2571 13.2843 13.9512 13.5903 13.5748 13.782C13.147 14 12.587 14 11.4668 14H4.5335C3.41339 14 2.85334 14 2.42552 13.782C2.04919 13.5903 1.74323 13.2843 1.55148 12.908C1.3335 12.4801 1.3335 11.9201 1.3335 10.8V4.66667Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
