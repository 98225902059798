import React, { PropsWithChildren, ReactElement, useState } from 'react'
import { SwiperProps, SwiperSlideProps } from 'swiper/react/swiper-react'
import { Swiper, useSwiper } from 'swiper/react'
import { Square } from '../../utility/square/Square'
import { Swiper as SwiperClass } from 'swiper/types'
import { TriangleButton } from '../../buttons/triangle/TriangleButton'
import { useTranslation } from 'react-i18next'

type BpCardSlide = ReactElement<PropsWithChildren<SwiperSlideProps>>

export interface IBpCardCarouselProps extends SwiperProps {
  className?: string
  children?: BpCardSlide | BpCardSlide[]
  title?: string
}

export const BpCardCarousel = (props: IBpCardCarouselProps) => {
  const { t } = useTranslation()
  const { className, children, title, ...swiperProps } = props
  const [activeIndex, setActiveIndex] = useState<number>()
  const [totalSlides, setTotalSlides] = useState<number>()

  const handleChange = (e: SwiperClass) => {
    setActiveIndex(e.realIndex + 1)
    setTotalSlides(e.slides.length || 1)
  }

  const Title = () => {
    return (
      <h4 className='mb-[16px] truncate'>
        {title && `${title}:`} {t('other.countOfTotal', { count: activeIndex, total: totalSlides })}
      </h4>
    )
  }

  const Navigation = () => {
    const swiper = useSwiper()
    const isDisabledPrev = !swiper.allowSlidePrev || swiper.isBeginning
    const isDisabledNext = !swiper.allowSlideNext || swiper.isEnd
    return (
      <div className='table shadow-outline overflow-hidden shadow-focus rounded-[6px] mt-[1px] mr-[1px] border-spacing-0'>
        <div className='flex'>
          <div
            className={`w-[24px] h-[24px] flex justify-center items-center border-r border-r-focus/[0.05] ${
              isDisabledPrev ? 'bg-focus-100 cursor-default' : 'cursor-pointer hover:bg-grey-300'
            }`}
            onClick={() => !isDisabledPrev && swiper.slidePrev()}
          >
            <TriangleButton
              color={`${!isDisabledPrev ? '#A059FF' : 'rgba(102,132,167,0.5)'}`}
              position='left'
            />
          </div>
          <div
            className={`w-[24px] h-[24px] flex justify-center items-center ${
              isDisabledNext ? 'bg-focus-100 cursor-default' : 'cursor-pointer hover:bg-grey-300'
            }`}
            onClick={() => !isDisabledNext && swiper.slideNext()}
          >
            <TriangleButton
              color={`${!isDisabledNext ? '#A059FF' : 'rgba(102,132,167,0.5)'}`}
              position='right'
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Square className={`p-[16px] ${className}`}>
      <Swiper {...swiperProps} onActiveIndexChange={handleChange} onSwiper={handleChange}>
        <span slot='container-start'>
          <div className='flex items-start justify-between'>
            <Title />
            <Navigation />
          </div>
        </span>
        {children}
      </Swiper>
    </Square>
  )
}
