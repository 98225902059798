import { useEffect, useState } from 'react'

export interface IMediaSize {
  xs: boolean
  sm: boolean
  md: boolean
  lg: boolean
  xl: boolean
}

export const useWindowSize = (): IMediaSize => {
  const [mediaSize, setMediaSize] = useState<IMediaSize>({
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
  })

  function handleResize() {
    const width = window.innerWidth

    setMediaSize({
      xs: width < 360,
      sm: width >= 360 && width < 640,
      md: width >= 640 && width < 960,
      lg: width >= 960 && width < 1280,
      xl: width >= 1280,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return mediaSize
}
