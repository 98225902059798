import React, { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { ILanguageComplianceFilter } from '../interface/LanguageComplianceFilter.interface'
import { selectLanguageComplianceFilter } from '../store/LanguageComplianceSlice'
import { useGetHistoryDetailsQuery } from '../../../../api/language-compliance/LanguageCompliance.api'
import { useAppSelector } from '../../../../store/hooks/Redux'
import { Square } from '../../../../shared/components/utility/square/Square'
import { IAdvertisingAffiliatesModel } from '../../../../api/research/models/AdvertisingAffiliates.model'
import { BpAnchor } from '../../../../shared/components/text/anchor/BpAnchor'
import { setModal } from '../../../../store/reducers/AppSlice'
import { LanguageComplianceReport } from './LanguageComplianceReport'
import { useDispatch } from 'react-redux'
import { BpTable } from '../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../shared/types/TableResponse.type'
import { IBpPaginationProps } from '../../../../shared/components/cards/pagination/BpPagination'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../shared/hooks/UseSorter'

export const LanguageCompilianceExpandRow = ({ record }: any) => {
  const dispatch = useDispatch()
  const filters = useAppSelector<ILanguageComplianceFilter>(selectLanguageComplianceFilter)
  const { project, country, affiliate, dateRange, restrictedWords, hidden } = filters

  const [queryParams, setQueryParams] = useState<any>({
    page: 1,
    'per-page': 5,
  })
  const { data, isLoading, isFetching } = useGetHistoryDetailsQuery(queryParams, {
    skip: !queryParams.project_id || !queryParams.country_id,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      date: dateRange,
      project_id: Number(project),
      country_id: Number(country),
      url: record.url,
      affiliate: affiliate,
      word_id: restrictedWords ? Number(restrictedWords) : undefined,
      show_hidden: hidden,
    })
  }, [filters])

  const columns: ColumnsType<any> = [
    {
      title: 'Last Check',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 250,
    },
    {
      title: 'Infringements',
      dataIndex: 'infringements_count',
      key: 'infringements_count',
      render: (_: string, { infringements_count, hash }) => {
        return (
          <div className={'flex items-start gap-[6px]'}>
            {infringements_count}
            <span
              className={`text-focus
                  text-[13px]
                  border-dotted
                  border-b
                  cursor-pointer`}
              onClick={() => {
                dispatch(
                  setModal({
                    size: 'lg',
                    children: (
                      <LanguageComplianceReport id={hash} state={record.state} url={record.url} />
                    ),
                  }),
                )
              }}
            >
              Report
            </span>
          </div>
        )
      },
      sorter: true,
    },
    {
      title: 'Screenshot',
      dataIndex: 'link_screen_url',
      key: 'link_screen_url',
      render: (_: string, { link_screen_url }: IAdvertisingAffiliatesModel) => (
        <BpAnchor href={link_screen_url} target='_blank'>
          Screenshot
        </BpAnchor>
      ),
    },
  ]

  const handleTableChange = (
    { current }: IBpPaginationProps,
    sorter?: SorterResult<any> | SorterResult<any>[],
  ) => {
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': 5,
      sort: useSorter(sorter),
    })
  }

  return (
    <Square className='p-4 w-8/12 m-auto'>
      <BpTable
        className='transparent-header'
        dataSource={data?.data?.items}
        rowKey={(record) => record.hash}
        columns={columns}
        loading={isLoading || isFetching}
        pagination={{ pageSize: 5 }}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<any>}
        sizePagination={'sm'}
        scroll={{ x: 0 }}
      />
    </Square>
  )
}
