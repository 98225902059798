import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconHamburger = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.5 10H17.5M2.5 5H17.5M2.5 15H17.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
