import { SubmitHandler, useForm } from 'react-hook-form'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { EMAIL_REGEX } from '../../../../shared/consts/RegularExpressions.const'

interface ISignUpFormData {
  fullName: string
  email: string
  company: string
}

export const SignUpForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ISignUpFormData>({
    mode: 'onChange',
    defaultValues: {
      company: '',
      email: '',
      fullName: '',
    },
  })

  const onSubmit: SubmitHandler<ISignUpFormData> = (data: ISignUpFormData) =>
    console.log(data, errors)

  return (
    <>
      <h2 className='mb-[24px]'>Create your account</h2>
      <form className='flex flex-col gap-[16px]' onSubmit={handleSubmit(onSubmit)}>
        <BpInput
          fieldSize='lg'
          label='Full Name'
          required
          error={errors.fullName?.message}
          {...register('fullName', {
            required: 'Required',
          })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label='Work E-mail'
          required
          error={errors.email?.message}
          {...register('email', {
            required: 'Required',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Invalid email address',
            },
          })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label='Company'
          required
          error={errors.company?.message}
          {...register('company', { required: 'Required' })}
        ></BpInput>
        <div className='mt-[16px] flex flex-col'>
          <BpButton type='submit' disabled={!isValid} size='lg'>
            REQUEST A DEMO
          </BpButton>
        </div>
      </form>
    </>
  )
}
