import { Field, IFieldProps, TFieldTheme } from '../utility/field/Field'
import React, { ForwardedRef, forwardRef, TextareaHTMLAttributes, useEffect, useState } from 'react'

export interface IBpTextAreaProps
  extends Omit<IFieldProps, 'children'>,
    TextareaHTMLAttributes<HTMLTextAreaElement> {
  disabled?: boolean
  error?: string
  success?: string
}

export const BpTextArea = forwardRef(
  (props: IBpTextAreaProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
    const { label, fieldSize, error, success, ...areaProps } = props
    const { disabled, required } = areaProps

    const [theme, setTheme] = useState<TFieldTheme>('default')
    const [message, setMessage] = useState<string>()
    const [isFocus, setIsFocus] = useState<boolean>(false)

    useEffect(() => {
      if (disabled) {
        setTheme('disabled')
      } else if (error) {
        setTheme('error')
        setMessage(error)
      } else if (success) {
        setTheme('success')
        setMessage(success)
      } else {
        setMessage('')
        isFocus ? setTheme('focus') : setTheme('default')
      }
    })

    return (
      <Field
        theme={theme}
        message={message}
        label={label}
        fieldSize={fieldSize}
        required={required}
      >
        <textarea
          {...areaProps}
          ref={ref}
          className={`
            flex 
            h-full 
            p-[12px] 
            rounded-[8px]
            outline-none
            border-none
            text-[15px]
            text-aqua-300
            focus:text-focus-500
            w-full
            disabled:bg-transparent
          `}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
        />
      </Field>
    )
  },
)
