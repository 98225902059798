import { HTMLAttributes, PropsWithChildren, ReactElement } from 'react'
import { IIconProps } from '../../../interfaces/IconProps.interface'

export interface IIconButtonProps extends HTMLAttributes<HTMLButtonElement> {
  size?: 'md' | 'lg'
  children: ReactElement<PropsWithChildren<IIconProps>>
  className?: string
}

export const IconButton = ({ children, onClick, size = 'md', className }: IIconButtonProps) => {
  return (
    <button
      onClick={onClick}
      type='button'
      className={`
        border
        border-focus-200
        rounded-[6px]
        outline-none
        flex
        items-center
        justify-center
        bg-focus-100
        transition-opacity
        duration-150
        hover:opacity-50
        ${size == 'md' ? 'h-[24px] w-[24px]' : 'h-[32px] w-[32px]'}
        ${className}        
      `}
    >
      <span
        className={`flex justify-center items-center ${
          size == 'md' ? 'w-[8px] h-[8px]' : 'w-[15px] h-[15px]'
        }`}
      >
        {children}
      </span>
    </button>
  )
}
