import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../Store'
import { IUserModel } from '../../api/site/models/User.model'

type AuthState = {
  authenticated: boolean | null
  user: IUserModel
}

const authSlice = createSlice({
  name: 'auth',
  initialState: { authenticated: null } as AuthState,
  reducers: {
    login: (state: AuthState, { payload }: PayloadAction<IUserModel>) => {
      state.authenticated = true
      state.user = payload
    },
    logOut: (state: AuthState) => {
      state.authenticated = false
    },
  },
})

export const { login, logOut } = authSlice.actions

export const selectAuthenticated = (state: RootState) => state.root.auth.authenticated
export const selectUser = (state: RootState) => state.root.auth.user

export default authSlice.reducer
