import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface IToggleListParams {
  title: string
  list: string[]
  onToggle?: (isShow: boolean) => void
}

export const ToggleList = ({ title, list, onToggle }: IToggleListParams) => {
  const { t } = useTranslation()
  const [isShow, setIsShow] = useState<boolean>(false)

  useEffect(() => {
    if (onToggle) onToggle(isShow)
  }, [isShow])

  return (
    <div className='flex flex-col items-start'>
      <div className='text-[15px] leading-[24px] text-focus-500'>
        {list.length} {title}
      </div>
      <button
        className={`text-focus
            text-[13px]
            border-dotted
            border-b
            cursor-pointer`}
        type='button'
        onClick={() => setIsShow(!isShow)}
      >
        {isShow ? t('buttons.closeList') : t('buttons.showList')}
      </button>
    </div>
  )
}
