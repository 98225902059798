import { useAppSelector } from '../../../../../store/hooks/Redux'
import { IDetailedInfoFilter } from '../../interfaces/DetailedInfoFilter.interface'
import { selectDetailedInfoFilters } from '../../store/DetailedInfoSlice'
import React, { Fragment, useEffect, useState } from 'react'
import { IAdvertisingKeywordParams } from '../../../../../api/research/interfaces/AdvertisingKeywordParams'
import { useGetAdvertisingKeywordQuery } from '../../../../../api/research/Research.api'
import { IAdvertisingModel } from '../../../../../api/research/models/Advertising.model'
import { Square } from '../../../../../shared/components/utility/square/Square'
import { ColumnsType } from 'antd/lib/table'
import { IAdvertisingKeywordModel } from '../../../../../api/research/models/AdvertisingKeyword.model'
import { Table } from 'antd'
import { BpAnchor } from '../../../../../shared/components/text/anchor/BpAnchor'
import { PositionCell } from '../../../../../shared/components/cells/PositionCell'
import { IconArrowDown } from '../../../../../shared/components/icons/IconArrowDown'
import { useTranslation } from 'react-i18next'

interface DetailedInfoPagesExpandedRowProps {
  record: IAdvertisingModel
}

export const DetailedInfoPagesExpandedRow = ({ record }: DetailedInfoPagesExpandedRowProps) => {
  const { t } = useTranslation()
  const detailedInfoFilter = useAppSelector<IDetailedInfoFilter>(selectDetailedInfoFilters)
  const {
    project,
    site,
    country,
    engine_id,
    dateRange: date,
    keyword,
    has_brand,
  } = detailedInfoFilter

  const [queryParams, setQueryParams] = useState<IAdvertisingKeywordParams>()
  const { data, isLoading, isFetching } = useGetAdvertisingKeywordQuery(
    queryParams as IAdvertisingKeywordParams,
    { skip: !project || queryParams == undefined },
  )

  useEffect(() => {
    if (!project) return

    setQueryParams({
      page: 1,
      'per-page': record.keywords_count,
      project_id: +project,
      site,
      country_id: country ? +country : undefined,
      engine_id: +engine_id,
      date,
      keyword,
      has_brand,
      title: record.title,
      url: record.url,
    })
  }, [detailedInfoFilter])

  const columns: ColumnsType<IAdvertisingKeywordModel> = [
    {
      title: t('table.columns.keyword'),
      dataIndex: 'keyword',
      key: 'keyword',
    },
    {
      title: t('table.columns.avgPosition'),
      dataIndex: 'position',
      key: 'position',
      render: (_: string, { position, previous_position }: IAdvertisingKeywordModel) => {
        const current = +position.toFixed(1)
        const previous = previous_position !== null && +previous_position.toFixed(1)

        if (!current && previous) {
          return (
            <div className='flex items-center gap-2'>
              <span className='w-8'>&gt;10</span>
              <span className='flex items-center text-danger'>
                <IconArrowDown className='w-4' color='#DC2F02' />
                {11 - previous}
              </span>
            </div>
          )
        } else if (!current) {
          return <span className={'text-danger'}>Lost</span>
        }

        return <PositionCell current={current} previous={previous} />
      },
      sorter: true,
    },
    {
      title: t('table.columns.lastUpdated'),
      dataIndex: 'last_found',
      key: 'last_found',
      sorter: true,
    },
    {
      title: t('table.columns.details'),
      key: 'details',
      render: (_: string, { link_screen_url, city, country, device }: IAdvertisingKeywordModel) => {
        return (
          <Fragment>
            <BpAnchor href={link_screen_url} target='_blank'>
              {t('buttons.SERPScreenshot')}
            </BpAnchor>
            <span className='text-aqua-300 ml-5'>{`${city} ${country} | ${device}`}</span>
          </Fragment>
        )
      },
    },
  ]

  return (
    <Square className='p-4 w-10/12 m-auto'>
      <Table
        className='transparent-header'
        rowClassName={(e) => (e.position === 0 ? 'disabled' : '')}
        loading={isLoading || isFetching}
        dataSource={data?.data?.items}
        columns={columns}
        pagination={false}
      />
    </Square>
  )
}
