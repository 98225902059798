import { useForm } from 'react-hook-form'
import { FormHTMLAttributes } from 'react'
import { BpInput } from '../../../shared/components/forms/input/BpInput'
import { BpTextArea } from '../../../shared/components/forms/textarea/BpTextArea'
import { BpButton } from '../../../shared/components/buttons/bp-button/BpButton'
import { EMAIL_REGEX } from '../../../shared/consts/RegularExpressions.const'

export interface IContactFormData {
  name: string
  email: string
  company: string
  message: string
}

export interface IContactFormProps extends FormHTMLAttributes<HTMLFormElement> {
  submitHandler?: (data: IContactFormData) => void
  isFetching?: boolean
}

export const ContactForm = ({
  submitHandler,
  isFetching = false,
  ...formProps
}: IContactFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<IContactFormData>({ mode: 'onChange' })

  return (
    <>
      <h2 className='mb-[24px]'>Request a Demo</h2>
      <form
        {...formProps}
        className='flex flex-col gap-[16px]'
        onSubmit={handleSubmit((data: IContactFormData) => {
          if (submitHandler) submitHandler(data)
        })}
      >
        <BpInput
          fieldSize='lg'
          label='Full Name'
          required
          error={errors.name?.message}
          {...register('name', {
            required: 'Required',
            minLength: {
              value: 4,
              message: 'Full Name should contain at least 4 characters.',
            },
          })}
        />
        <BpInput
          fieldSize='lg'
          label='Work E-mail'
          required
          type='email'
          error={errors.email?.message}
          {...register('email', {
            required: 'Required',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Invalid email address',
            },
          })}
        />
        <BpInput
          fieldSize='lg'
          label='Company'
          required
          error={errors.company?.message}
          {...register('company', {
            required: 'Required',
            minLength: {
              value: 4,
              message: 'Full Name should contain at least 4 characters.',
            },
          })}
        />
        <BpTextArea
          label='Please tell us about your interest'
          fieldSize='lg'
          required
          error={errors.message?.message}
          {...register('message', {
            required: 'Required',
            minLength: {
              value: 15,
              message: 'Message should contain at least 15 characters.',
            },
          })}
        />
        <div className='mt-[16px] flex flex-col'>
          <BpButton type='submit' disabled={!isValid || isFetching} size='lg'>
            REQUEST A DEMO
          </BpButton>
        </div>
      </form>
    </>
  )
}
