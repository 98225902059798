import { IconEqual } from '../icons/IconEqual'
import { IconArrowUp } from '../icons/IconArrowUp'
import { IconArrowDown } from '../icons/IconArrowDown'
import React from 'react'

interface IPositionCellProps {
  current: number
  previous: number | false
}

export const PositionCell = ({ current, previous }: IPositionCellProps) => {
  if (!previous) {
    return (
      <div className='flex items-center gap-2'>
        <span className='w-8'>{current}</span>
        <span className='text-success-400'>new</span>
      </div>
    )
  }

  return (
    <div className='flex items-center gap-2'>
      <span className='w-8'>{current}</span>

      {current === previous ? (
        <IconEqual className='w-4' color='#6684A7' />
      ) : current < previous ? (
        <span className='flex items-center text-success-400'>
          <IconArrowUp className='w-4' color='#4DB11E' />
          {((current - previous) * -1).toFixed(1)}
        </span>
      ) : (
        <span className='flex items-center text-danger'>
          <IconArrowDown className='w-4' color='#DC2F02' />
          {(current - previous).toFixed(1)}
        </span>
      )}
    </div>
  )
}
