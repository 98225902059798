import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResearchFilter } from '../interfaces/ResearchFilter.interface'
import { RootState } from '../../../../store/Store'

export type TResearchTabs = 'Competitors' | 'Keywords'

type ResearchState = {
  filters: IResearchFilter
  activeTab: TResearchTabs
}

const researchSlice = createSlice({
  name: 'research',
  initialState: {
    filters: {
      country: [],
      domain: '',
    },
    activeTab: 'Competitors',
  } as ResearchState,
  reducers: {
    setResearchFilter: (state: ResearchState, action: PayloadAction<IResearchFilter>) => {
      state.filters = action.payload
    },
    setResearchActiveTab: (state: ResearchState, action: PayloadAction<TResearchTabs>) => {
      state.activeTab = action.payload
    },
  },
})

export const { setResearchFilter, setResearchActiveTab } = researchSlice.actions

export const selectResearchFilters = (state: RootState) => state.root.research.filters
export const selectResearchActiveTab = (state: RootState) => state.root.research.activeTab

export default researchSlice.reducer
