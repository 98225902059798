import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconSeoPosition = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.332 2.33337V1.33337M12.9582 3.04048L13.6654 2.33337M13.6722 5.66671H14.6722M14.6324 8.66671C14.298 12.0356 11.4556 14.6667 7.9987 14.6667C4.3168 14.6667 1.33203 11.6819 1.33203 8.00004C1.33203 4.54314 3.96315 1.70077 7.33203 1.36629M7.9987 5.33337H10.6654V8.00004M10.4118 5.33337C8.84223 7.55177 6.25627 9.00004 3.33203 9.00004C2.66343 9.00004 2.01251 8.92431 1.38738 8.78104'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
