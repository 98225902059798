import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPositionTrackerFilter } from '../interfaces/IPositionTrackerFilter'
import { RootState } from '../../../../store/Store'
import { IDateRange } from '../../../../shared/interfaces/DateRange.interface'
import { IPositionTrackerChart } from '../interfaces/IPositionTrackerChart'
import { useDateRange } from '../../../../shared/hooks/UseDateRange'
import { EDateRangeType } from '../../research-tools/enums/DateRangeType'
import { EDateGroup } from '../../../../api/research/enums/DateGroup.enum'

type PositionTrackerState = {
  filters: IPositionTrackerFilter
  chart: IPositionTrackerChart
}

const dateRange = useDateRange(EDateRangeType.MONTH)

const positionTrackerSlice = createSlice({
  name: 'organic',
  initialState: {
    filters: {
      no_serp_presence: false,
    },
    chart: { dateRange: { date: dateRange.date, group: EDateGroup.DAY } },
  } as PositionTrackerState,
  reducers: {
    setPositionTrackerFilter: (
      state: PositionTrackerState,
      action: PayloadAction<IPositionTrackerFilter>,
    ) => {
      state.filters = {
        ...state.filters,
        ...{ ...action.payload, no_serp_presence: state.filters.no_serp_presence },
      }
    },
    setSerpPresence: (state: PositionTrackerState, action: PayloadAction<boolean>) => {
      state.filters = { ...state.filters, no_serp_presence: action.payload }
    },
    changeChartDataRange: (state: PositionTrackerState, action: PayloadAction<IDateRange>) => {
      state.chart.dateRange = action.payload
      state.filters.date = action.payload.date
    },
    changeChartIds: (state: PositionTrackerState, action: PayloadAction<number[] | undefined>) => {
      state.chart.chartIds = action.payload
    },
  },
})

export const { setPositionTrackerFilter, setSerpPresence, changeChartDataRange, changeChartIds } =
  positionTrackerSlice.actions

export const selectPositionTrackerFilters = (state: RootState) => state.root.positionTracker.filters

export const selectPositionTrackerChart = (state: RootState) => state.root.positionTracker.chart

export default positionTrackerSlice.reducer
