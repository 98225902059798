import { HTMLAttributes, useState } from 'react'
import { IconCrossBold } from '../../../icons/IconCrossBold'

export const Chip = ({ children, onClick }: HTMLAttributes<HTMLButtonElement>) => {
  const [iconColor, setIconColor] = useState<string>('#6684A7')
  return (
    <div className='rounded-[6px] shadow-outline shadow-focus hover:shadow-default h-[32px] overflow-hidden group'>
      <div className='flex h-full items-center white'>
        <span className='flex-1 px-[8px] py-[4px] text-focus-500 text-[14px] truncate'>
          {children}
        </span>
        <button
          onClick={onClick}
          onMouseOver={() => setIconColor('#A059FF')}
          onMouseLeave={() => setIconColor('#6684A7')}
          type='button'
          className='flex self-stretch w-[30px] items-center justify-center border-l border-focus-200 group-hover:border-default-100 hover:bg-grey-300'
        >
          <div className='w-[8px]'>
            <IconCrossBold color={iconColor} />
          </div>
        </button>
      </div>
    </div>
  )
}
