import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconArrowBack = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.5 4.50001H12.75C14.8211 4.50001 16.5 6.17892 16.5 8.25C16.5 10.3211 14.8211 12 12.75 12H9M1.5 4.50001L4.83333 1.16667M1.5 4.50001L4.83333 7.83334'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
