import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconLanguageCompliance = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.99756 7.33301H5.33073M10.1644 4.66634C9.51345 4.66634 9.1485 4.66634 8.49756 4.66634M13.3307 5.99967V4.53301C13.3307 3.4129 13.3307 2.85285 13.1127 2.42503C12.921 2.0487 12.6151 1.74274 12.2387 1.55099C11.8109 1.33301 11.2509 1.33301 10.1307 1.33301H5.86406C4.74396 1.33301 4.1839 1.33301 3.75608 1.55099C3.37976 1.74274 3.0738 2.0487 2.88205 2.42503C2.66406 2.85285 2.66406 3.4129 2.66406 4.53301V11.4663C2.66406 12.5865 2.66406 13.1465 2.88205 13.5743C3.0738 13.9507 3.37976 14.2566 3.75608 14.4483C4.1839 14.6663 4.74396 14.6663 5.86406 14.6663H6.66406'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 10.8V12M12 13.2H12.003M9 10.9568V13.0432C9 13.1165 9 13.1532 9.00829 13.1878C9.01564 13.2184 9.02776 13.2476 9.04421 13.2745C9.06276 13.3048 9.0887 13.3307 9.14059 13.3826L10.6174 14.8594C10.6693 14.9113 10.6952 14.9372 10.7255 14.9558C10.7524 14.9723 10.7816 14.9844 10.8122 14.9917C10.8468 15 10.8834 15 10.9568 15H13.0432C13.1165 15 13.1532 15 13.1878 14.9917C13.2184 14.9844 13.2476 14.9723 13.2745 14.9558C13.3048 14.9372 13.3307 14.9113 13.3826 14.8594L14.8594 13.3826C14.9113 13.3307 14.9372 13.3048 14.9558 13.2745C14.9723 13.2476 14.9844 13.2184 14.9917 13.1878C15 13.1532 15 13.1165 15 13.0432V10.9568C15 10.8834 15 10.8468 14.9917 10.8122C14.9844 10.7816 14.9723 10.7524 14.9558 10.7255C14.9372 10.6952 14.9113 10.6693 14.8594 10.6174L13.3826 9.14059C13.3307 9.0887 13.3048 9.06276 13.2745 9.04421C13.2476 9.02776 13.2184 9.01564 13.1878 9.00829C13.1532 9 13.1165 9 13.0432 9H10.9568C10.8834 9 10.8468 9 10.8122 9.00829C10.7816 9.01564 10.7524 9.02776 10.7255 9.04421C10.6952 9.06276 10.6693 9.0887 10.6174 9.14059L9.14059 10.6174C9.0887 10.6693 9.06276 10.6952 9.04421 10.7255C9.02776 10.7524 9.01564 10.7816 9.00829 10.8122C9 10.8468 9 10.8834 9 10.9568Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
