import { BpCheckbox } from '../../../../shared/components/forms/checkbox/BpCheckbox'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../store/hooks/Redux'
import { setLiveSearchType } from '../store/LiveSearchSlice'
import { useTranslation } from 'react-i18next'

export const LiveSearchTypes = ({
  isLoading,
  disable,
}: {
  isLoading: boolean
  disable: boolean
}) => {
  const { t } = useTranslation()
  const [state, setState] = useState({ paid: false, organic: false })
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      setLiveSearchType(
        state.paid && state.organic
          ? 'all'
          : state.paid
          ? 'paid'
          : state.organic
          ? 'organic'
          : 'all',
      ),
    )
  }, [state])

  return (
    <div className='mx-auto flex items-center bg-focus-500/[2%] font-[500] rounded-xl mt-8 mb-6 px-5 py-4 text-[16px] w-full text-focus-500 justify-between md:gap-[75px] md:w-auto'>
      {t('pages.liveSearch.showResults')}
      <div className='flex gap-[8px] flex-col md:flex-row md:gap-[30px]'>
        <BpCheckbox
          disabled={isLoading || disable}
          onChange={(e) => setState({ ...state, paid: e.target.checked })}
          label={t('pages.liveSearch.paid')}
        />
        <BpCheckbox
          disabled={isLoading || disable}
          onChange={(e) => setState({ ...state, organic: e.target.checked })}
          label={t('pages.liveSearch.organic')}
        />
      </div>
    </div>
  )
}
