import React, { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'

export type IRadioButtonProps = InputHTMLAttributes<HTMLInputElement>

export const RadioButton = forwardRef(
  (props: IRadioButtonProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { placeholder, className, ...inputProps } = props

    return (
      <label className={`cursor-pointer table relative rounded-[6px] overflow-hidden ${className}`}>
        <div className='flex items-center h-[28px]'>
          <input {...inputProps} ref={ref} type='radio' className='peer' hidden />
          <span
            className='
              text-[13px]
              px-[8px]
              text-aqua-300
              before:content-[""]
              before:absolute
              before:inset-0
              before:block
              before:invisible
              before:bg-grey-300
              peer-checked:text-default
              peer-checked:before:visible
            '
          >
            <span className='relative'>{placeholder}</span>
          </span>
        </div>
      </label>
    )
  },
)
