import React from 'react'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { selectOverviewFilters } from '../../store/OverviewSlice'
import { useGetVisibilityByCountryQuery } from '../../../../../api/overview/Overview.api'
import { IOverviewFilter } from '../../interfaces/OverviewFilter.interface'
import HighchartsReact from 'highcharts-react-official'
import Highcharts, { Options } from 'highcharts'
import { IVisibilityByCountryParams } from '../../../../../api/overview/interfaces/VisibilityByCountryParams.interface'
import { RadioButton } from '../../../../../shared/components/forms/radio-button/RadioButton'
import { RadioGroup } from '../../../../../shared/components/forms/radio-group/RadioGroup'
import { useDateRange } from '../../../../../shared/hooks/UseDateRange'
import { EDateRangeType } from '../../../research-tools/enums/DateRangeType'
import { BpTooltip } from '../../../../../shared/components/cards/tooltip/BpTooltip'
import { useTranslation } from 'react-i18next'

export const VisibilityByCountryReport = () => {
  const { t } = useTranslation()
  const { country, project } = useAppSelector<IOverviewFilter>(selectOverviewFilters)
  const { date } = useDateRange(EDateRangeType.MONTH)

  const { data } = useGetVisibilityByCountryQuery(
    {
      project_id: +project,
      country_ids: country?.length ? country : undefined,
      date,
    } as IVisibilityByCountryParams,
    { skip: !project },
  )

  return (
    <section className='flex flex-col w-full'>
      <h3 className='mb-[16px] gap-[16px] flex flex-col justify-between md:flex-row md:items-center'>
        <span className='flex gap-[8px] items-center'>
          <span className='font-medium'>{t('pages.overview.visibilityByCountry')}</span>
          <BpTooltip title={t('pages.overview.visibilityByCountryTooltip')} />
        </span>
        <RadioGroup name='visibility-by-country-filter'>
          <RadioButton placeholder={t('other.last30Days')} defaultChecked />
        </RadioGroup>
      </h3>
      <HighchartsReact
        highcharts={Highcharts}
        options={
          {
            chart: {
              type: 'column',
              height: '300px',
            },
            tooltip: {
              backgroundColor: 'white',
              borderRadius: 15,
              style: {
                fontSize: '14px',
                color: '#00326D',
              },
              pointFormat: '<span style="color:#0E63D8;">●</span> {point.y}%',
            },
            colors: ['#0E63D8'],
            title: '',
            xAxis: {
              type: 'category',
              labels: {
                style: {
                  color: 'rgba(0,50,109,0.3)',
                  fontSize: '13px',
                  fontFamily: 'Jost, sans-serif',
                },
              },
              tickColor: 'transparent',
            },
            yAxis: {
              title: undefined,
              gridLineColor: 'rgba(0,50,109,0.03)',
              labels: {
                style: {
                  color: 'rgba(0,50,109,0.3)',
                  fontSize: '13px',
                  fontFamily: 'Jost, sans-serif',
                },
              },
            },
            series: data?.map((item) => ({
              ...item,
              data: item.data ? [...item.data] : [],
            })),
            credits: {
              enabled: false,
            },
            legend: {
              enabled: false,
            },
          } as Options
        }
      />
    </section>
  )
}
