import { ICommonResponse } from '../interfaces/CommonResponse.interface'
import { showAlert } from '../../store/reducers/AppSlice'
import { AppDispatch } from '../../store/Store'

export function handleCommonResponse(
  { success, errors }: ICommonResponse<unknown>,
  dispatch: AppDispatch,
  successMessage?: string,
): void {
  if (!success && errors) {
    for (const error in errors) {
      errors[error].forEach((message) => {
        dispatch(
          showAlert({
            message,
            type: 'error',
          }),
        )
      })
    }
  } else if (successMessage) {
    dispatch(
      showAlert({
        message: successMessage,
        type: 'success',
      }),
    )
  }
}
