import './BpArticle.css'

export interface IBpArticleProps {
  title?: string
  text?: string
  link?: string
  href?: string
}

export const BpArticle = ({ title, text, link, href }: IBpArticleProps) => {
  return (
    <article className='flex flex-col text-[14px] leading-[18px]'>
      {href
        ? title && (
            <a
              className='text-focus-400 font-semibold text-inh leading-[16px]'
              href={href}
              dangerouslySetInnerHTML={{ __html: title }}
              target='_blank'
              rel='noreferrer'
            ></a>
          )
        : title && (
            <h4
              className='text-focus-400 font-semibold text-inh leading-[16px]'
              dangerouslySetInnerHTML={{ __html: title }}
            ></h4>
          )}
      {text && (
        <p dangerouslySetInnerHTML={{ __html: text }} className='text-focus-600 leading-[18px]'></p>
      )}
      {href
        ? link && (
            <a
              className='text-success-400 leading-[18px] break-all hover:text-success-400'
              href={href}
              dangerouslySetInnerHTML={{ __html: link }}
              target='_blank'
              rel='noreferrer'
            />
          )
        : link && (
            <span
              className='text-success-400 leading-[18px] break-all'
              dangerouslySetInnerHTML={{ __html: link }}
            ></span>
          )}
    </article>
  )
}
