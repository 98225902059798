import { ReactNode } from 'react'

export interface IBpCard {
  title?: ReactNode
  children?: ReactNode
  footer?: ReactNode
}

export const BpCard = ({ title, children, footer }: IBpCard) => {
  return (
    <div className={'flex flex-col overflow-y-hidden'}>
      {title && (
        <h2 className='text-[20px] md:text-[24px] p-[24px] pb-[21px] border-b-2 border-b-focus-500/[0.02] flex gap-[15px]'>
          {title}
        </h2>
      )}
      <div className='p-[24px] flex flex-col gap-[24px] overflow-y-auto'>{children}</div>
      {footer && (
        <div className='p-[24px] border-t-2 border-t-focus-500/[0.02] flex gap-[16px]'>
          {footer}
        </div>
      )}
    </div>
  )
}
