import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconInfringement = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.9732 6.8875C11.9308 6.80322 11.8445 6.75004 11.7501 6.74999H8.79156L9.47932 2.28806C9.50034 2.15161 9.40675 2.02395 9.2703 2.00292C9.17842 1.98877 9.08627 2.02676 9.03107 2.10157L4.04885 8.85159C3.96686 8.96268 3.99049 9.11922 4.10161 9.20118C4.14459 9.23289 4.19662 9.25 4.25004 9.25H7.20856L6.5208 13.7119C6.50317 13.8256 6.56559 13.9367 6.67193 13.9807C6.70228 13.9936 6.73493 14.0001 6.76788 14C6.84726 14 6.92191 13.9623 6.96905 13.8984L11.9513 7.14843C12.0072 7.07256 12.0157 6.97168 11.9732 6.8875Z'
        fill={color}
      />
    </svg>
  )
}
