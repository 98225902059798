import React, { PropsWithChildren, ReactElement, useState } from 'react'
import { NavLinkProps } from 'react-router-dom'

type NavLink = ReactElement<PropsWithChildren<NavLinkProps>>

export interface INavCollapseItemProps extends React.HTMLAttributes<HTMLDivElement> {
  children: NavLink | NavLink[]
  label?: string
  active?: boolean
  theme?: 'default' | 'dark'
  size?: 'sm' | 'md' | 'lg'
  decoration?: 'dot' | 'line'
}

const classDecoration = {
  line: `after:rounded-t-sm
    after:h-[3px]
    after:bottom-0
    after:left-0
    after:right-0`,
  dot: `after:h-[6px]
    after:w-[6px]
    after:top-3
    after:bottom-0
    after:right-auto
    after:left-[-18px]
    after:rounded-t-full
    after:rounded-b-full`,
}

export const NavCollapseItem = ({
  children,
  label,
  active = false,
  theme = 'default',
  size = 'md',
  decoration = 'line',
  ...divAttributes
}: INavCollapseItemProps) => {
  const [isHidden, setIsHidden] = useState(true)

  return (
    <div
      {...divAttributes}
      className={`py-[28px]
        w-full
        cursor-pointer
        table
        group
        relative
        after:content-['']
        after:block
        after:absolute
        after:opacity-0
        after:transition-opacity
        after:duration-150
        hover:after:opacity-100
        flex-shrink
        ${classDecoration[decoration]}
        ${active && 'after:opacity-100'}
        ${!isHidden && 'after:opacity-100'} 
        ${theme == 'dark' ? 'after:bg-default' : 'after:bg-fill'} 
        ${size == 'sm' && 'py-[3px]'}
        ${size == 'lg' && 'py-[13px]'}`}
    >
      <span
        onClick={() => setIsHidden(!isHidden)}
        className={`block
          transition-colors
          duration-150
          ${
            theme == 'default' &&
            `${!isHidden || active ? 'text-fill' : 'text-white'} group-hover:text-fill`
          }
          ${
            theme == 'dark' &&
            `${!isHidden || active ? 'text-default' : 'text-focus-500'} group-hover:text-default`
          }
          ${size == 'sm' && 'pb-[6px]'}
          ${size == 'md' || (size == 'sm' && 'text-[16px] leading-[24px]')}
          ${size == 'lg' && 'text-[24px] leading-[32px] font-medium'}`}
      >
        {label}
      </span>
      <div
        className={`overflow-hidden
          transition-max-h
          duration-500
          w-full
          ${isHidden ? 'max-h-0' : 'max-h-64 mb-[-8px]'}`}
      >
        {children}
      </div>
    </div>
  )
}
