import React, { HTMLAttributes, ReactNode, useEffect, useState } from 'react'
import { IconFilter } from '../../icons/IconFilter'
import { BpButton } from '../../buttons/bp-button/BpButton'
import { Square } from '../../utility/square/Square'
import { IWithDropdownProps, withDropdown } from '../../../hoc/WithDropdown'
import { useTranslation } from 'react-i18next'

export interface IFilterDropParams extends IWithDropdownProps {
  children?: ReactNode | undefined
  onSubmit?: () => void
  onClear?: () => void
  values?: (string | number | boolean | undefined)[]
}

const Drop = ({
  children,
  open,
  onToggle,
  onSubmit,
  onClear,
  values = [],
}: IFilterDropParams & HTMLAttributes<HTMLElement>) => {
  const { t } = useTranslation()
  const [isHidden, setIsHidden] = useState(!open)
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    open ? setIsHidden(false) : setTimeout(() => setIsHidden(true), 150)
  })

  useEffect(() => {
    setCounter(values?.filter((value) => !!value).length)
  }, [values])

  return (
    <div className='relative table select-none w-full'>
      <BpButton
        className='relative w-full'
        icon={<IconFilter />}
        size='lg'
        onClick={onToggle}
        active={open}
        type='button'
      >
        {t('buttons.filters')}
        <span
          className={`bg-[#00326D] text-[12px] rounded-[4px] py-[2px] px-[6px] absolute -top-[4px] -right-[5px] ${
            !counter && 'hidden'
          }`}
        >
          {counter}
        </span>
      </BpButton>
      <div
        className={`transition-opacity
        duration-150
        absolute
        right-0
        w-[320px]
        top-[54px]
        max-w-[85vw]
        ${open ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
        ${isHidden && 'invisible'} z-50`}
      >
        <Square depth='200' className='flex flex-col p-[24px] gap-[18px]'>
          {children}
          <div className='flex flex-col md:flex-row border-t-[3px] border-t-focus-500/[0.02] p-[24px] pb-0 mx-[-24px] gap-[18px]'>
            <BpButton type='button' onClick={onSubmit}>
              {t('buttons.applyFilters')}
            </BpButton>
            <BpButton theme='outline' type='button' onClick={onClear}>
              {t('buttons.clearSelected')}
            </BpButton>
          </div>
        </Square>
      </div>
    </div>
  )
}

export const FilterDrop = withDropdown(Drop)
