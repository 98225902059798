import { CommonMainPage } from '../utility/CommonMainPage'
import { DetailedInfoFilters } from './components/filters/DetailedInfoFilters'
import { BpTabs } from '../../../shared/components/utility/tabs/BpTabs'
import { BpTab } from '../../../shared/components/utility/tabs/tab/BpTab'
import { DetailedInfoPagesTable } from './components/pages/DetailedInfoPagesTable'
import { DetailedInfoAffiliatesTable } from './components/affiliates/DetailedInfoAffiliatesTable'
import { DetailedInfoPagesPanel } from './components/pages/DetailedInfoPagesPanel'
import { DetailedInfoAffiliatesPanel } from './components/affiliates/DetailedInfoAffiliatesPanel'
import { useAppDispatch, useAppSelector } from '../../../store/hooks/Redux'
import {
  selectDetailedInfoActiveTab,
  setDetailedInfoActiveTab,
  TDetailedInfoTabs,
} from './store/DetailedInfoSlice'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const DetailedInfoPage = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const activeTab = useAppSelector(selectDetailedInfoActiveTab)

  return (
    <CommonMainPage>
      <DetailedInfoFilters />
      <BpTabs
        className='mt-[19px]'
        active={activeTab}
        onToggle={(e) => dispatch(setDetailedInfoActiveTab(e as TDetailedInfoTabs))}
        back={() => navigate('/organic-results')}
      >
        <BpTab title={t('pages.domainOverview.pages')} panel={<DetailedInfoPagesPanel />}>
          <DetailedInfoPagesTable />
        </BpTab>
        <BpTab title={t('pages.domainOverview.affiliates')} panel={<DetailedInfoAffiliatesPanel />}>
          <DetailedInfoAffiliatesTable />
        </BpTab>
      </BpTabs>
    </CommonMainPage>
  )
}
