import { CommonMainPage } from '../utility/CommonMainPage'
import { PositionTrackerFilters } from './components/filter/PositionTrackerFilters'
import { PositionTrackerChart } from './components/report/PositionTrackerChart'
import { PositionTrackerTable } from './components/report/PositionTrackerTable'

export const PositionTrackerPage = () => {
  return (
    <CommonMainPage>
      <section className='flex flex-col gap-[50px]'>
        <PositionTrackerFilters />
        <PositionTrackerChart />
        <PositionTrackerTable />
      </section>
    </CommonMainPage>
  )
}
