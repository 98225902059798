import React, { ChangeEvent, ComponentType, ForwardedRef, forwardRef, useState } from 'react'
import { useDebounce } from '../hooks/UseDebounce'

export interface IWithSearchProps {
  searchValue?: string
  debouncedSearch?: string
  onClear?: () => void
}

export function withSearch<T>(WrappedComponent: ComponentType<T & IWithSearchProps>) {
  return forwardRef((props: T & IWithSearchProps, ref: ForwardedRef<IWithSearchProps>) => {
    const { searchValue } = props
    const [currentSearch, setCurrentSearch] = useState<string>(searchValue ? searchValue : '')
    const debouncedSearch = useDebounce(currentSearch, 300)

    return (
      <WrappedComponent
        {...props}
        ref={ref}
        onInput={(e: ChangeEvent<HTMLInputElement>) => setCurrentSearch(e.target.value)}
        debouncedSearch={debouncedSearch}
        onClear={() => setCurrentSearch('')}
      ></WrappedComponent>
    )
  })
}
