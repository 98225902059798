import { IOption } from '../components/forms/search-input/BpSearchInput'

export const GroupByOptions: IOption[] = [
  {
    text: 'Day',
    value: 'day',
  },
  {
    text: 'Week',
    value: 'week',
  },
  {
    text: 'Month',
    value: 'month',
  },
]
