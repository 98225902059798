import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useDateRanges } from '../../../../shared/hooks/UseDateRanges'
import { ISerpPresenceFilter } from '../interfaces/SerpPresenceFilter'
import { RootState } from '../../../../store/Store'

type SerpPresenceState = {
  filters: ISerpPresenceFilter
}

const dateRanges = useDateRanges()

const serpPresenceSlice = createSlice({
  name: 'serpPresence',
  initialState: {
    filters: {
      dateRange: dateRanges.month,
      no_serp_presence: false,
      is_seo: false,
    },
  } as SerpPresenceState,
  reducers: {
    setSerpPresenceFilter: (
      state: SerpPresenceState,
      action: PayloadAction<ISerpPresenceFilter>,
    ) => {
      state.filters = {
        ...state.filters,
        ...{
          ...action.payload,
          no_serp_presence: state.filters.no_serp_presence,
          is_seo: state.filters.is_seo,
        },
      }
    },
    setSerpPresence: (state: SerpPresenceState, action: PayloadAction<boolean>) => {
      state.filters = { ...state.filters, no_serp_presence: action.payload }
    },
    setIsSeo: (state: SerpPresenceState, action: PayloadAction<boolean>) => {
      state.filters = { ...state.filters, is_seo: action.payload }
    },
  },
})

export const { setSerpPresenceFilter, setSerpPresence, setIsSeo } = serpPresenceSlice.actions

export const selectSerpPresenceFilters = (state: RootState) => state.root.serpPresence.filters

export default serpPresenceSlice.reducer
