import { BpSelect } from '../../../../../shared/components/forms/select/BpSelect'
import { IconFolder } from '../../../../../shared/components/icons/IconFolder'
import { useGetCountryQuery, useGetProjectsQuery } from '../../../../../api/filter/Filter.api'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { IOption } from '../../../../../shared/components/forms/search-input/BpSearchInput'
import { IconCountry } from '../../../../../shared/components/icons/IconCountry'
import { IOverviewFilter } from '../../interfaces/OverviewFilter.interface'
import { selectOverviewFilters, setOverviewFilter } from '../../store/OverviewSlice'
import {
  IWithSearchParamsProps,
  withSearchParams,
} from '../../../../../shared/hoc/WithSearchParams'
import { useTranslation } from 'react-i18next'

const Filter = ({ searchParams, setSearchParams }: IWithSearchParamsProps<IOverviewFilter>) => {
  const { t } = useTranslation()
  const { country, project } = useAppSelector<IOverviewFilter>(selectOverviewFilters)
  const dispatch = useAppDispatch()

  const {
    getValues,
    setValue,
    control,
    formState: { defaultValues },
  } = useForm<IOverviewFilter>({
    mode: 'onChange',
    defaultValues: {
      country,
      project,
      ...searchParams,
    },
  })

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetProjectsQuery()

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery(
    {
      project_id: project ? +project : undefined,
    },
    {
      skip: !project,
    },
  )

  const applyFilter = () => {
    const values = getValues()
    dispatch(setOverviewFilter(values))
    if (setSearchParams) setSearchParams(values)
  }

  return (
    <>
      <form className='flex flex-col lg:items-center max-w-full gap-y-[12px] lg:flex-row lg:flex-wrap lg:gap-x-[8px] xl:flex-nowrap'>
        <div className='flex-1'>
          <Controller
            name='project'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.project
                ? defaultValues.project
                : project
                ? project
                : projects && projects[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={projects}
                  Icon={IconFolder}
                  fieldSize='lg'
                  title={t('fields.project')}
                  defaultValue={defaultValue}
                  loading={projectsLoading || projectsFetching}
                  disabled={projectsLoading || projectsFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    if (e.target.value !== defaultValue) {
                      setValue('country', [])
                    }
                    applyFilter()
                  }}
                />
              )
            }}
          />
        </div>
        <div className='flex-1 md:flex-grow-[3]'>
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              return (
                <BpSelect
                  {...fieldProps}
                  options={countries}
                  Icon={IconCountry}
                  fieldSize='lg'
                  title={t('fields.country')}
                  defaultValue={defaultValues?.country as string[]}
                  multiple
                  loading={countriesLoading || countriesFetching}
                  disabled={countriesLoading || countriesFetching}
                  onChange={(e) => {
                    onChange([].slice.call(e.target.selectedOptions).map((o: IOption) => o.value))
                    applyFilter()
                  }}
                  showSearch
                />
              )
            }}
          />
        </div>
      </form>
    </>
  )
}
export const OverviewFilters = withSearchParams<
  IWithSearchParamsProps<IOverviewFilter>,
  IOverviewFilter
>(Filter, selectOverviewFilters)
