import { EDateRangeType } from '../../../../pages/main/research-tools/enums/DateRangeType'
import { RadioButton } from '../radio-button/RadioButton'
import { IRadioGroupProps, RadioGroup } from '../radio-group/RadioGroup'
import React from 'react'
import { useDateRanges } from '../../../hooks/UseDateRanges'
import { useTranslation } from 'react-i18next'

export interface IDateRangesParams extends Omit<IRadioGroupProps, 'children'> {
  dateRange: string
  exceptions?: EDateRangeType[]
}

export const DateRanges = (params: IDateRangesParams) => {
  const { t } = useTranslation()
  const { dateRange, exceptions, ...radioGroupProps } = params
  const dateRanges = useDateRanges()

  return (
    <RadioGroup {...radioGroupProps}>
      <RadioButton
        className={`${exceptions?.includes(EDateRangeType.WEEK) && 'hidden'}`}
        value={EDateRangeType.WEEK}
        placeholder={t('other.week')}
        defaultChecked={dateRange === dateRanges.week}
      />
      <RadioButton
        className={`${exceptions?.includes(EDateRangeType.MONTH) && 'hidden'}`}
        value={EDateRangeType.MONTH}
        placeholder={t('other.month')}
        defaultChecked={dateRange === dateRanges.month}
      />
      <RadioButton
        className={`${exceptions?.includes(EDateRangeType.THREE_MONTHS) && 'hidden'}`}
        value={EDateRangeType.THREE_MONTHS}
        placeholder={t('other.3Months')}
        defaultChecked={dateRange === dateRanges.threeMonths}
      />
      <RadioButton
        className={`${exceptions?.includes(EDateRangeType.SIX_MONTHS) && 'hidden'}`}
        value={EDateRangeType.SIX_MONTHS}
        placeholder={t('other.6Months')}
        defaultChecked={dateRange === dateRanges.sixMonths}
      />
      <RadioButton
        className={`${exceptions?.includes(EDateRangeType.YEAR) && 'hidden'}`}
        value={EDateRangeType.YEAR}
        placeholder={t('other.1Year')}
        defaultChecked={dateRange === dateRanges.year}
      />
    </RadioGroup>
  )
}
