import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import queryString from 'query-string'
import { TTableResponse } from '../../shared/types/TableResponse.type'
import { NonCompliantAffiliatesModel } from './models/NonCompliantAffiliates.model'
import { INonCompliantAffiliatesParams } from './interfaces/NonCompliantAffiliatesParams.interface'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { IOption } from '../../shared/components/forms/search-input/BpSearchInput'
import { ILastSendEmailParams } from './interfaces/LastSendEmailParams.interface'
import { LastSendEmailModel } from './models/LastSendEmail.model'
import { ISendAbuseMailParams } from './interfaces/SendAbuseMailParams.interface'
import { objectToFormData } from '../../shared/helpers/ObjectToFormData'

export const complianceApi = createApi({
  reducerPath: 'compliance/api',
  tagTypes: ['Compliance'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/compliance/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    getNonCompliantAffiliates: build.query<
      TTableResponse<NonCompliantAffiliatesModel>,
      INonCompliantAffiliatesParams
    >({
      query: (params: INonCompliantAffiliatesParams) => ({
        url: 'non-compliant-affiliates',
        params,
      }),
    }),
    sendAbuseMail: build.mutation<ICommonResponse<any>, ISendAbuseMailParams>({
      query: (params: ISendAbuseMailParams) => ({
        url: 'send-abuse-mail',
        method: 'POST',
        body: objectToFormData(params),
        cache: 'no-cache',
      }),
    }),
    getEmailList: build.query<IOption[], any>({
      query: () => ({
        url: 'get-email-list',
      }),
      transformResponse: (response: ICommonResponse<string[]>): IOption[] => {
        if (response?.data)
          return response.data.map((value: string) => ({ text: value, value: value }))
        return []
      },
    }),
    getLastSendEmail: build.query<TTableResponse<LastSendEmailModel>, ILastSendEmailParams>({
      query: (params: ILastSendEmailParams) => ({
        url: 'last-send-email',
        params,
      }),
    }),
  }),
})

export const {
  useGetNonCompliantAffiliatesQuery,
  useSendAbuseMailMutation,
  useGetEmailListQuery,
  useGetLastSendEmailQuery,
} = complianceApi
