import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconUser = ({ color = 'white' }: IIconProps) => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.66928 14.5449C3.071 14.6666 3.6136 14.6666 4.53594 14.6666H11.4693C12.3916 14.6666 12.9342 14.6666 13.3359 14.5449M2.66928 14.5449C2.58315 14.5188 2.50349 14.4871 2.42796 14.4486C2.05163 14.2569 1.74567 13.9509 1.55392 13.5746C1.33594 13.1467 1.33594 12.5867 1.33594 11.4666V4.53325C1.33594 3.41315 1.33594 2.85309 1.55392 2.42527C1.74567 2.04895 2.05163 1.74299 2.42796 1.55124C2.85578 1.33325 3.41583 1.33325 4.53594 1.33325H11.4693C12.5894 1.33325 13.1494 1.33325 13.5773 1.55124C13.9536 1.74299 14.2595 2.04895 14.4513 2.42527C14.6693 2.85309 14.6693 3.41315 14.6693 4.53325V11.4666C14.6693 12.5867 14.6693 13.1467 14.4513 13.5746C14.2595 13.9509 13.9536 14.2569 13.5773 14.4486C13.5017 14.4871 13.4221 14.5188 13.3359 14.5449M2.66928 14.5449C2.6695 14.0053 2.67274 13.7199 2.72051 13.4797C2.93093 12.4219 3.75786 11.5949 4.8157 11.3845C5.07329 11.3333 5.38306 11.3333 6.0026 11.3333H10.0026C10.6221 11.3333 10.9319 11.3333 11.1895 11.3845C12.2473 11.5949 13.0743 12.4219 13.2847 13.4797C13.3325 13.7199 13.3357 14.0053 13.3359 14.5449M10.6693 6.33325C10.6693 7.80598 9.47534 8.99992 8.0026 8.99992C6.52984 8.99992 5.33594 7.80598 5.33594 6.33325C5.33594 4.86049 6.52984 3.66659 8.0026 3.66659C9.47534 3.66659 10.6693 4.86049 10.6693 6.33325Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
