import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { TTableResponse } from '../../shared/types/TableResponse.type'
import { IAdvertiserModel } from './models/Advertiser.model'
import { IAllAdvertisersParams } from './interfaces/AllAdvertisersParams.interface'
import { IEditSiteSettingsParams } from './interfaces/EditSiteSettingsParams.interface'
import { ICommonResponse } from '../../shared/interfaces/CommonResponse.interface'
import { ISiteSettingsModel } from './models/SiteSettings.model'
import queryString from 'query-string'
import { IOrganicModel } from './models/Organic.model'
import { TExportOrganicResultsParams } from './types/ExportOrganicResultsParams.type'
import { IEditDecloakSettingsParams } from './interfaces/EditDecloakSettingsParams.interface'
import { IDecloakSettingsModel } from './models/DecloakSettings.model'

export const advertiserReportsApi = createApi({
  reducerPath: 'advertiserReports/api',
  tagTypes: ['SiteSettings'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/advertiser-reports/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    getAllAdvertisers: build.query<TTableResponse<IAdvertiserModel>, IAllAdvertisersParams>({
      query: (params: IAllAdvertisersParams) => ({
        url: 'all-advertisers',
        params,
      }),
      providesTags: ['SiteSettings'],
    }),
    editSiteSettings: build.mutation<ICommonResponse<ISiteSettingsModel>, IEditSiteSettingsParams>({
      query: ({ is_affiliate, domain, is_hide, project_id, status }: IEditSiteSettingsParams) => ({
        url: 'site-settings',
        method: 'post',
        body: (() => {
          const formData = new FormData()
          formData.append('is_affiliate', `${is_affiliate}`)
          formData.append('domain', domain)
          formData.append('is_hide', `${is_hide}`)
          formData.append('project_id', `${project_id}`)
          formData.append('status', `${status}`)
          return formData
        })(),
      }),
      invalidatesTags: ['SiteSettings'],
    }),
    editDecloakSettings: build.mutation<
      ICommonResponse<IDecloakSettingsModel>,
      IEditDecloakSettingsParams
    >({
      query: ({ project_id, domain, is_decloak }: IEditDecloakSettingsParams) => ({
        url: 'decloak-settings',
        method: 'post',
        body: (() => {
          const formData = new FormData()
          formData.append('project_id', `${project_id}`)
          formData.append('domain', domain)
          formData.append('is_decloak', `${is_decloak}`)
          return formData
        })(),
      }),
      invalidatesTags: ['SiteSettings'],
    }),
    getOrganicResults: build.query<TTableResponse<IOrganicModel>, IAllAdvertisersParams>({
      query: (params: IAllAdvertisersParams) => ({
        url: 'organic',
        params,
      }),
      providesTags: ['SiteSettings'],
    }),
    exportOrganicResults: build.mutation<Blob, TExportOrganicResultsParams>({
      query(params: TExportOrganicResultsParams) {
        return {
          url: 'organic',
          params: params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    editOrganicSettings: build.mutation<
      ICommonResponse<ISiteSettingsModel>,
      IEditSiteSettingsParams
    >({
      query: ({ project_id, domain, is_hide }: IEditSiteSettingsParams) => ({
        url: 'site-settings',
        method: 'post',
        body: (() => {
          const formData = new FormData()
          formData.append('project_id', `${project_id}`)
          formData.append('domain', domain)
          formData.append('is_hide', `${is_hide}`)
          return formData
        })(),
      }),
      invalidatesTags: ['SiteSettings'],
    }),
  }),
})

export const {
  useGetAllAdvertisersQuery,
  useEditSiteSettingsMutation,
  useEditDecloakSettingsMutation,
  useGetOrganicResultsQuery,
  useExportOrganicResultsMutation,
  useEditOrganicSettingsMutation,
} = advertiserReportsApi
