import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconBell = ({ color = 'white' }: IIconProps) => {
  return (
    <svg className='w-full' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.0374 15.0121C13.3948 16.3458 12.6034 17.7166 11.2697 18.0739C9.93602 18.4313 8.56519 17.6398 8.20782 16.3062M1.71321 9.67751C1.38864 8.51234 1.72617 7.25267 2.58984 6.40591M10.1211 4.7838C10.3746 4.32772 10.4581 3.77647 10.3124 3.23263C10.0146 2.12125 8.87227 1.4617 7.76085 1.75949C6.64947 2.05729 5.98992 3.19966 6.28772 4.31105C6.43344 4.85487 6.78138 5.29052 7.22896 5.55875M16.9317 5.59976C16.6302 4.42842 15.708 3.50625 14.5367 3.20477M14.5747 7.87082C14.2773 6.76072 13.4832 5.83242 12.3671 5.29013C11.251 4.74786 9.90444 4.63602 8.62352 4.97924C7.34264 5.32246 6.23236 6.09261 5.53696 7.12027C4.84156 8.14792 4.618 9.34892 4.91545 10.459C5.4076 12.2958 5.31204 13.761 5.00125 14.8745C4.64702 16.1435 4.46991 16.7781 4.51775 16.9055C4.5725 17.0513 4.6121 17.0913 4.7573 17.1475C4.8842 17.1967 5.41735 17.0538 6.48363 16.7681L16.3712 14.1188C17.4374 13.833 17.9706 13.6902 18.0559 13.5842C18.1535 13.4628 18.1679 13.4084 18.1424 13.2548C18.1201 13.1205 17.6494 12.6596 16.7082 11.7377C15.8823 10.9288 15.0669 9.70751 14.5747 7.87082Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
