import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const AuthLayout = () => {
  const { t } = useTranslation()

  return (
    <div className='flex flex-col min-h-screen bg-grey md:bg-focus-500 px-[24px] md:px-[50px] py-[24px] overflow-hidden animate-appear-hand'>
      <div className='container mx-auto relative'>
        <div
          className='
          bg-white
          block
          w-[1181px]
          h-[1181px]
          md:-left-[550px]
          md:-top-[190px]
          absolute
          rotate-45
          rounded-[25%]
          invisible
          md:visible
        '
        ></div>
        <header className='relative flex justify-center md:justify-start mb-[31px] md:mb-[56px]'>
          <a href={t('project.landing')}>
            <img alt='logo' src={'/logo_dark.svg'} className='max-w-[166px]' />
          </a>
        </header>
        <section className='relative md:px-[50px]'>
          <Outlet />
        </section>
      </div>
    </div>
  )
}
