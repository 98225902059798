import { Field, IFieldProps, TFieldTheme } from '../utility/field/Field'
import { IResultDropProps, ResultDrop, TResultValue } from '../result-drop/ResultDrop'
import React, {
  ComponentType,
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { IIconProps } from '../../../interfaces/IconProps.interface'
import { IWithDropdownProps, withDropdown } from '../../../hoc/WithDropdown'
import { TriangleButton } from '../../buttons/triangle/TriangleButton'
import { IWithFieldSizeProps, withFieldSize } from '../../../hoc/WithFieldSize'
import { IconCheck } from '../../icons/IconCheck'
import { FieldDrop } from '../utility/field-drop/FieldDrop'
import { IOption } from '../search-input/BpSearchInput'
import { Chip } from './chip/Chip'
import { AlsoField } from './also-field/AlsoField'
import { SearchField } from '../utility/search-field/SearchField'
import { IWithSearchProps, withSearch } from '../../../hoc/WithSearch'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next'

export interface IBpSelect
  extends IWithDropdownProps,
    IWithFieldSizeProps,
    IWithSearchProps,
    IFieldProps,
    IResultDropProps {
  Icon?: ComponentType<IIconProps>
  placeholder?: string
  label?: string
  disabled?: boolean
  options?: IOption[]
  title?: string
  showSearch?: boolean
  onInput?: () => void
  className?: string | undefined
  hideIcon?: boolean
  height?: number
  hasTranslate?: boolean
}

const DEFAULT_ICON_COLOR = '#00326D'

const Select = forwardRef((props: IBpSelect, ref: ForwardedRef<HTMLSelectElement>) => {
  const {
    Icon = IconCheck,
    fieldSize = 'md',
    open,
    classSize,
    placeholder = '',
    label,
    options = [],
    title = '',
    onToggle,
    debouncedSearch,
    onInput,
    onClear,
    showSearch = false,
    className,
    loading = false,
    hideIcon = false,
    height,
    hasTranslate = false,
    ...selectProps
  } = props
  const { t: translateOption } = useTranslation('translation', { keyPrefix: 'fields.options' })
  const { disabled, value } = selectProps
  const [theme, setTheme] = useState<TFieldTheme>('default')
  const [selectedOptions, setSelectedOptions] = useState<IOption | IOption[]>()
  const [currentValue, setCurrentValue] = useState<TResultValue | undefined>(value)
  const [extraOptions, setExtraOptions] = useState<IOption[]>([])
  const [showLength, setShowLength] = useState<number>(0)
  const [currentOpen, setCurrentOpen] = useState(open)
  const chipFieldRef = useRef<HTMLDivElement>(null)
  const chipListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (disabled) {
      setCurrentOpen(false)
      setTheme('disabled')
    } else {
      if (currentOpen) {
        setTheme('focus')
      } else {
        setTheme('default')
      }
    }
  })

  useEffect(() => {
    setCurrentValue(value)
  }, [value, options])

  useEffect(() => {
    setCurrentOpen(open)
  }, [open])

  useEffect(() => {
    if (Array.isArray(selectedOptions)) {
      if (fieldSize == 'sm') {
        setCurrentValue(selectedOptions.map((o: IOption) => o.value))
        return
      }

      if (!canAddExtraOption() && showLength < selectedOptions.length) {
        setShowLength(showLength + 1)
      }
    }
  })

  useEffect(() => {
    if (Array.isArray(selectedOptions)) {
      let extra: IOption[] = extraOptions

      if (canAddExtraOption()) {
        extra = selectedOptions.slice(showLength, selectedOptions.length)
      }

      setExtraOptions(extra)
    }
  }, [showLength, selectedOptions])

  const iconElement: JSX.Element = (
    <span className='mr-[10px] w-[17px]'>{<Icon color={DEFAULT_ICON_COLOR} />}</span>
  )
  const placeholderElement: JSX.Element = <span className='text-aqua-300'>{placeholder} </span>
  const nameElement: JSX.Element = <span className='text-focus-500 mr-[10px]'>{title} </span>

  const singleValueElement: (text: string) => JSX.Element = (text: string) => (
    <span className={`flex-1 truncate ${title ? 'text-default' : 'text-focus-500'}`}>
      {hasTranslate && text.length ? translateOption(text) : text}
    </span>
  )

  const chipsElement: (values: IOption[]) => JSX.Element = (values: IOption[]) => (
    <div ref={chipFieldRef} className='flex flex-grow'>
      <div ref={chipListRef} className='flex shrink gap-[8px]'>
        {values.map(({ value, text }: IOption) => (
          <span key={value} className='chip p-[1px] max-w-[150px]'>
            <Chip onClick={() => removeChip(value)}>{text}</Chip>
          </span>
        ))}
      </div>
    </div>
  )

  const textElement: (values: IOption[]) => JSX.Element = (values: IOption[]) => (
    <div className='flex gap-[5px]'>
      {fieldSize == 'sm' &&
        values.map((val: IOption) => (
          <span key={val.value} className='max-w-[150px] truncate'>
            {val.text},
          </span>
        ))}
    </div>
  )

  const optionsElement = Array.isArray(selectedOptions)
    ? fieldSize == 'sm'
      ? textElement(selectedOptions)
      : chipsElement(selectedOptions.slice(0, showLength))
    : selectedOptions && singleValueElement(selectedOptions.text)

  const extraCounter = (
    <span
      className={`
        w-[41px]
        h-[32px]
        flex
        items-center
        justify-center
        bg-focus-50
        rounded-[4px]
        text-aqua-300
        text-[14px]
        font-medium 
        ml-[8px]        
      `}
    >
      +{extraOptions.length}
    </span>
  )

  const removeChip = (chipValue: string) => {
    if (Array.isArray(selectedOptions)) {
      const filteredOptions = selectedOptions.filter(
        (option: IOption) => option.value !== chipValue,
      )
      setSelectedOptions(filteredOptions)
      setCurrentValue(filteredOptions.map((o: IOption) => o.value))
    }
  }

  const handleToggle = (e: MouseEvent) => {
    if (onToggle && !(e.target as Element).closest('.chip')) onToggle()
  }

  const canAddExtraOption = (): boolean => {
    const refFieldElement = chipFieldRef.current
    const refListElement = chipListRef.current

    if (refFieldElement && refListElement) {
      return refFieldElement.offsetWidth - refListElement.scrollWidth <= 150
    } else {
      return false
    }
  }

  const canShowPlaceholder = (): boolean => {
    if (selectedOptions) {
      if (Array.isArray(selectedOptions)) {
        return selectedOptions.length <= 0 || !selectedOptions[0].value
      } else {
        return !selectedOptions.value
      }
    } else {
      return true
    }
  }

  if (loading) {
    return (
      <ContentLoader
        className={`w-full
          ${fieldSize === 'sm' && 'h-[32px]'} 
          ${fieldSize === 'md' && 'h-[44px]'} 
          ${fieldSize === 'lg' && 'h-[56px]'}`}
        speed={2}
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
      >
        <rect x='0' y='0' rx='8' ry='8' width='100%' height='100%' />
      </ContentLoader>
    )
  }

  return (
    <div className={`relative ${disabled && 'pointer-events-none'} ${className}`}>
      <Field fieldSize={fieldSize} label={label} theme={theme} loading={loading}>
        <div
          onClick={handleToggle}
          className={`flex items-center select-none ${!disabled && 'cursor-pointer'} ${
            fieldSize == 'lg' && 'pl-[14px]'
          } ${fieldSize == 'md' && 'pl-[12px]'} ${fieldSize == 'sm' && 'pl-[8px]'} ${classSize}`}
        >
          {fieldSize == 'lg' && !hideIcon && iconElement}
          <span
            className={`mr-[10px] truncate flex flex-1 items-center ${
              fieldSize == 'lg' && 'text-[16px]'
            } ${fieldSize == 'md' && 'text-[15px]'} ${fieldSize == 'sm' && 'text-[14px]'}`}
          >
            {nameElement && nameElement}
            {canShowPlaceholder() && placeholderElement}
            {optionsElement}
            {fieldSize !== 'sm' && extraOptions.length > 0 && extraCounter}
          </span>
          <span className='px-[16px] flex items-center justify-center'>
            <TriangleButton
              position={currentOpen ? 'top' : 'down'}
              color='#A059FF'
            ></TriangleButton>
          </span>
        </div>
        <FieldDrop open={currentOpen}>
          <div className='shadow-outline shadow-default'>
            {fieldSize !== 'sm' && extraOptions.length > 0 && (
              <div className='border-b border-default/[0.07]'>
                <AlsoField
                  items={extraOptions}
                  onRemove={(val: string) => removeChip(val)}
                ></AlsoField>
              </div>
            )}
            {showSearch && (
              <div className='bg-white border-b border-default/[0.07]'>
                <SearchField
                  disabled={disabled}
                  onInput={onInput}
                  onClear={onClear}
                  fieldSize={fieldSize}
                />
              </div>
            )}
            <ResultDrop
              {...selectProps}
              hasTranslate={hasTranslate}
              ref={ref}
              options={options}
              onSelected={(value: IOption | IOption[] | undefined) => setSelectedOptions(value)}
              value={currentValue}
              noShadow
              search={debouncedSearch}
              pinTop={!!currentOpen}
              height={height}
            ></ResultDrop>
          </div>
        </FieldDrop>
      </Field>
    </div>
  )
})
export const BpSelect = withDropdown(withFieldSize(withSearch(Select)))
