import { Route, Routes, useLocation } from 'react-router-dom'
import { AuthLayout } from './layouts/auth-layout/AuthLayout'
import { LoginPage } from './pages/auth/login/LoginPage'
import { SignUpPage } from './pages/auth/signup/SignUpPage'
import { ResetPasswordPage } from './pages/auth/reset-password/ResetPasswordPage'
import { AdvertiserReportPage } from './pages/main/advertiser-report/AdvertiserReportPage'
import { MainLayout } from './layouts/main-layout/MainLayout'
import { AuthGuard } from './guards/AuthGuard'
import { MainGuard } from './guards/MainGuard'
import { NotFound } from './shared/components/cards/not-found/NotFound'
import { useAppDispatch, useAppSelector } from './store/hooks/Redux'
import {
  hideAlert,
  hideModal,
  selectAlerts,
  selectModal,
  selectPreviousLocation,
  setLocation,
} from './store/reducers/AppSlice'
import { BpAlert } from './shared/components/cards/alert/BpAlert'
import { ResearchToolsPage } from './pages/main/research-tools/components/ResearchToolsPage'
import { ColumnReportTable } from './pages/main/research-tools/components/column-report/ColumnReportTable'
import { CommonPageLayout } from './layouts/common-page-layout/CommonPageLayout'
import { AdvertisersReportTable } from './pages/main/research-tools/components/advertisers-report/AdvertisersReportTable'
import { AdOverviewPage } from './pages/main/ad-overview/components/AdOverviewPage'
import { BpModal } from './shared/components/cards/modal/BpModal'
import { useEffect, useState } from 'react'
import { OverviewPage } from './pages/main/overview/components/OverviewPage'
import { ProfileSettingsPage } from './pages/main/profile-settings/components/ProfileSettingsPage'
import { useOutsideClick } from './shared/hooks/UseOutsideClick'
import { ContactPage } from './pages/auth/contact/ContactPage'
import { AffiliatesAdsReport } from './pages/main/ad-overview/components/AffiliatesAdsReport'
import { LiveUncloakingPage } from './pages/main/live-uncloaking/components/LiveUncloakingPage'
import Highcharts from 'highcharts'
import { OrganicResultsPage } from './pages/main/organic-results/OrganicResultsPage'
import { DetailedInfoPage } from './pages/main/detailed-info/DetailedInfoPage'
import { PositionTrackerPage } from './pages/main/position-tracker/PositionTrackerPage'
import { SerpPresence } from './pages/main/serp-presence/SerpPresence'
import { SetUpPasswordPage } from './pages/auth/set-up-password/SetUpPasswordPage'
import { BlackhatMonitoringPage } from './pages/main/blackhat-monitoring/components/BlackhatMonitoringPage'
import { LiveSearchPage } from './pages/main/live-search/components/LiveSearchPage'
import { PpcCompliancePage } from './pages/main/ppc-compliance/components/PpcCompliancePage'
import { LanguageCompliancePage } from './pages/main/language-compliance/components/LanguageCompliancePage'

function App() {
  const dispatch = useAppDispatch()
  const alerts = useAppSelector(selectAlerts)
  const modal = useAppSelector(selectModal)
  const previousLocation = useAppSelector(selectPreviousLocation)
  const location = useLocation()
  const body = document.querySelector('body')
  const [isShowAlerts, setIsShowAlerts] = useState<boolean>(true)
  const clickRef = useOutsideClick(() => {
    if (alerts.length) setIsShowAlerts(false)
  })

  useEffect(() => {
    if (body && modal) {
      body.style.overflow = 'hidden'
    } else if (body) {
      body.style.overflow = 'auto'
    }
  }, [modal])

  useEffect(() => {
    dispatch(setLocation(location))
  }, [location])

  Highcharts.setOptions({
    time: {
      timezoneOffset: 3 * 60,
      useUTC: true,
    },
  })

  return (
    <>
      {alerts && (
        <div
          ref={clickRef}
          className='fixed right-0 top-0 md:right-5 md:top-5 z-50 max-w-full md:max-w-[320px] flex flex-col gap-[10px]'
        >
          {alerts.map((alert, index: number) => (
            <BpAlert
              {...alert}
              key={index}
              show={isShowAlerts}
              onAfterClose={() => {
                dispatch(hideAlert(alert.message))
                setIsShowAlerts(true)
              }}
            />
          ))}
        </div>
      )}
      {modal?.children && (
        <BpModal size={modal.size} onClose={() => dispatch(hideModal())}>
          {modal.children}
        </BpModal>
      )}
      <Routes>
        <Route element={<AuthGuard />}>
          <Route path='/auth' element={<AuthLayout />}>
            <Route index element={<LoginPage />} />
            <Route path='signup' element={<SignUpPage />} />
            <Route path='reset-password' element={<ResetPasswordPage />} />
            <Route path='set-up-password' element={<SetUpPasswordPage />} />
            <Route path='request-demo' element={<ContactPage />} />
            <Route path='*' element={<NotFound />}></Route>
          </Route>
        </Route>
        <Route element={<MainGuard />}>
          <Route path='/' element={<MainLayout />}>
            <Route index element={<OverviewPage />} />
            <Route path='ad-reports' element={<AdvertiserReportPage />} />
            <Route path='adv-overview' element={<CommonPageLayout />}>
              <Route index element={<AdOverviewPage />} />
              <Route path=':publisher_id/:affiliate' element={<AffiliatesAdsReport />} />
            </Route>
            <Route path='advertising-research' element={<CommonPageLayout />}>
              <Route index element={<ResearchToolsPage />} />
              <Route path='ads-domain/:id/:domain' element={<ColumnReportTable />} />
              <Route path='ads-keyword/:id/:keyword' element={<ColumnReportTable />} />
              <Route path='advertisers/:id/:keyword' element={<AdvertisersReportTable />} />
            </Route>
            <Route path='profile-settings' element={<ProfileSettingsPage />} />
            <Route path='organic-results' element={<OrganicResultsPage />} />
            <Route path='detailed-info' element={<DetailedInfoPage />} />
            <Route path='position-tracker' element={<PositionTrackerPage />} />
            <Route path='serp-presence' element={<SerpPresence />} />
            <Route path='blackhat-monitoring' element={<BlackhatMonitoringPage />} />
            <Route path='live-uncloaking' element={<LiveUncloakingPage />} />
            <Route path='ppc-compliance' element={<PpcCompliancePage />} />
            <Route path='language-compliance-page' element={<LanguageCompliancePage />} />
            <Route path='live-search' element={<LiveSearchPage />} />
            <Route path='*' element={<NotFound />}></Route>
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
