import { BpSelect } from '../../../../../shared/components/forms/select/BpSelect'
import { BpSearchInput } from '../../../../../shared/components/forms/search-input/BpSearchInput'
import { IconFolder } from '../../../../../shared/components/icons/IconFolder'
import { IconCountry } from '../../../../../shared/components/icons/IconCountry'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { Controller, useForm } from 'react-hook-form'
import { useEffect } from 'react'
import {
  useGetAffiliatesQuery,
  useGetCountryQuery,
  useGetDomainsQuery,
  useGetEnginesQuery,
  useGetKeywordsQuery,
  useGetProjectsQuery,
} from '../../../../../api/filter/Filter.api'
import { FilterDrop } from '../../../../../shared/components/cards/filter-drop/FilterDrop'
import { BpDatePicker } from '../../../../../shared/components/forms/datepicker/BpDatePicker'
import { setCanCloseDropdown } from '../../../../../store/reducers/AppSlice'
import moment from 'moment/moment'
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_RANGE_FORMAT,
} from '../../../../../shared/consts/DefaultRangeFormat.const'
import {
  selectOrganicResultsFilters,
  setOrganicResultsFilter,
} from '../../store/OrganicResultsSlice'
import { IOrganicResultsFilter } from '../../interfaces/IOrganicResultsFilter'
import { PolicyOptions } from '../../../../../shared/consts/PolicyOptions.const'
import { IDomainParams } from '../../../../../api/filter/interfaces/DomainParams.interface'
import { IKeywordsParams } from '../../../../../api/filter/interfaces/KeywordsParams.interface'
import { Switch } from 'antd'
import {
  IWithSearchParamsProps,
  withSearchParams,
} from '../../../../../shared/hoc/WithSearchParams'
import { IAffiliateParams } from '../../../../../api/filter/interfaces/AffiliatesParams.interface'
import { IconSearchEngine } from '../../../../../shared/components/icons/IconSearchEngine'
import { useTranslation } from 'react-i18next'

const Filter = ({
  searchParams,
  setSearchParams,
}: IWithSearchParamsProps<IOrganicResultsFilter>) => {
  const {
    country,
    engine_id,
    project,
    dateRange,
    site,
    policy,
    keyword,
    affiliate,
    show_hidden,
    show_affiliates,
  } = useAppSelector<IOrganicResultsFilter>(selectOrganicResultsFilters)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const {
    getValues,
    setValue,
    control,
    formState: { defaultValues },
  } = useForm<IOrganicResultsFilter>({
    mode: 'onChange',
    defaultValues: {
      country,
      engine_id,
      project,
      dateRange,
      site,
      policy,
      keyword,
      affiliate,
      show_hidden,
      show_affiliates,
      ...searchParams,
    },
  })

  useEffect(() => {
    applyFilter()
  }, [])

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetProjectsQuery()

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery({ project_id: project ? +project : undefined }, { skip: !project })

  const {
    data: engines,
    isFetching: enginesFetching,
    isLoading: enginesLoading,
  } = useGetEnginesQuery({ project_id: project ? +project : undefined }, { skip: !project })

  const {
    data: domains,
    isFetching: domainsFetching,
    isLoading: domainsLoading,
  } = useGetDomainsQuery(
    {
      project_id: project ? +project : undefined,
      search: site,
      date: dateRange,
      seo:true,
    } as IDomainParams,
    { skip: !project },
  )

  const {
    data: keywords,
    isFetching: keywordsFetching,
    isLoading: keywordsLoading,
  } = useGetKeywordsQuery(
    {
      project_id: project,
      search: keyword,
    } as IKeywordsParams,
    { skip: !project },
  )

  const {
    data: affiliates,
    isFetching: affiliatesFetching,
    isLoading: affiliatesLoading,
  } = useGetAffiliatesQuery(
    {
      date: dateRange,
      project_id: +project,
      search: affiliate,
    } as IAffiliateParams,
    { skip: !project },
  )

  const applyFilter = () => {
    const values = getValues()
    dispatch(setOrganicResultsFilter(values))
    if (setSearchParams) setSearchParams(values)
  }

  return (
    <>
      <form className='flex flex-col max-w-full gap-y-[12px] md:items-center md:flex-row md:flex-wrap md:gap-x-[8px] xl:flex-nowrap'>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='project'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.project
                ? defaultValues.project
                : project
                ? project
                : projects && projects[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={projects}
                  Icon={IconFolder}
                  fieldSize='lg'
                  title={t('fields.project')}
                  defaultValue={defaultValue}
                  loading={projectsLoading || projectsFetching}
                  disabled={projectsLoading || projectsFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    if (e.target.value !== defaultValue) {
                      setValue('country', '')
                      setValue('policy', undefined)
                    }
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='engine_id'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.engine_id
                ? defaultValues.engine_id
                : engine_id
                ? engine_id
                : engines && engines[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={engines}
                  Icon={IconSearchEngine}
                  fieldSize='lg'
                  title={t('fields.searchEngine')}
                  defaultValue={defaultValue}
                  loading={enginesLoading || enginesFetching}
                  disabled={enginesLoading || enginesFetching}
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.country
                ? defaultValues.country
                : country
                ? country
                : countries?.length && countries[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={countries}
                  Icon={IconCountry}
                  fieldSize='lg'
                  title={t('fields.country')}
                  defaultValue={defaultValue}
                  loading={countriesLoading || countriesFetching}
                  disabled={countriesLoading || countriesFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] xl:w-auto xl:flex-1'>
          <Controller
            name='site'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSearchInput
                {...fieldProps}
                fieldSize='lg'
                placeholder={t('fields.domain')}
                options={domains}
                loading={domainsLoading || domainsFetching}
                onChange={(e) => {
                  onChange(e)
                  applyFilter()
                }}
              ></BpSearchInput>
            )}
          />
        </div>
        <div className='flex flex-col w-full lg:w-[calc(50%-4px)] xl:w-auto'>
          <FilterDrop
            onSubmit={applyFilter}
            onClear={() => {
              setValue('policy', undefined)
              setValue('keyword', '')
              setValue('affiliate', '')
              setValue('show_affiliates', false)
              setValue('show_hidden', false)
            }}
            values={getValues([
              'dateRange',
              'policy',
              'keyword',
              'affiliate',
              'show_affiliates',
              'show_hidden',
            ])}
          >
            <Controller
              name='dateRange'
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <BpDatePicker
                    onOpenChange={(e) => dispatch(setCanCloseDropdown(!e))}
                    label={t('fields.date')}
                    onChange={(e, t) => onChange(`${t[0]} - ${t[1]}`)}
                    defaultValue={[
                      moment(value, DEFAULT_DATE_FORMAT),
                      moment(value, DEFAULT_RANGE_FORMAT),
                    ]}
                  />
                )
              }}
            />
            <Controller
              name='policy'
              control={control}
              render={({ field }) => (
                <BpSelect
                  {...field}
                  hasTranslate
                  placeholder={t('fields.other.selectFromList')}
                  options={PolicyOptions}
                  label={t('fields.policy')}
                />
              )}
            />
            <Controller
              name='keyword'
              control={control}
              render={({ field }) => (
                <BpSearchInput
                  {...field}
                  label={t('fields.keyword')}
                  placeholder={t('fields.other.search')}
                  options={keywords}
                  loading={keywordsLoading || keywordsFetching}
                ></BpSearchInput>
              )}
            />
            <Controller
              name='affiliate'
              control={control}
              render={({ field }) => (
                <BpSearchInput
                  {...field}
                  label={t('fields.affiliate')}
                  placeholder={t('fields.other.search')}
                  options={affiliates}
                  loading={affiliatesLoading || affiliatesFetching}
                ></BpSearchInput>
              )}
            />
            <Controller
              control={control}
              render={({ field }) => (
                <div className='flex justify-between gap-[15px]'>
                  <span className='text-focus-500 text-[15px] leading-[24px]'>
                    {t('fields.showAffiliates')}
                  </span>
                  <Switch
                    {...field}
                    defaultChecked={show_affiliates}
                    checked={field.value}
                  ></Switch>
                </div>
              )}
              name='show_affiliates'
            />
            <Controller
              control={control}
              render={({ field }) => (
                <div className='flex justify-between gap-[15px]'>
                  <span className='text-focus-500 text-[15px] leading-[24px]'>
                    {t('fields.showHiddenSites')}
                  </span>
                  <Switch {...field} defaultChecked={show_hidden} checked={field.value}></Switch>
                </div>
              )}
              name='show_hidden'
            />
          </FilterDrop>
        </div>
      </form>
    </>
  )
}

export const OrganicResultsFilters = withSearchParams<
  IWithSearchParamsProps<IOrganicResultsFilter>,
  IOrganicResultsFilter
>(Filter, selectOrganicResultsFilters)
