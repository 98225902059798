import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconTriangle = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 8 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.56765 4.55069C3.80369 4.79599 4.19631 4.79599 4.43235 4.55069L7.35246 1.51603C7.71925 1.13484 7.44911 0.5 6.92011 0.5H1.07989C0.550894 0.5 0.280751 1.13484 0.647543 1.51603L3.56765 4.55069Z'
        fill={color}
      />
    </svg>
  )
}
