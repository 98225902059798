import { useForm } from 'react-hook-form'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { FormHTMLAttributes } from 'react'

export interface ISetUpPasswordFormData {
  password: string
  confirmPassword: string
}

export interface ISetUpPasswordFormProps extends FormHTMLAttributes<HTMLFormElement> {
  submitHandler?: (data: ISetUpPasswordFormData) => void
  isFetching?: boolean
}

export const SetUpPasswordForm = ({
  submitHandler,
  isFetching = false,
  ...formProps
}: ISetUpPasswordFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ISetUpPasswordFormData>({
    mode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  return (
    <>
      <h2 className='mb-[24px]'>Set up your password</h2>
      <form
        {...formProps}
        className='flex flex-col gap-[16px]'
        onSubmit={handleSubmit((data: ISetUpPasswordFormData) => {
          if (submitHandler) submitHandler(data)
        })}
      >
        <BpInput
          fieldSize='lg'
          label='New password'
          type='password'
          required
          error={errors.password?.message}
          {...register('password', { required: 'Required' })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label='Confirm new password'
          type='password'
          required
          error={errors.confirmPassword?.message}
          {...register('confirmPassword', { required: 'Required' })}
        ></BpInput>
        <div className='mt-[16px] flex flex-col'>
          <BpButton className='uppercase' type='submit' disabled={!isValid || isFetching} size='lg'>
            Confirm
          </BpButton>
        </div>
      </form>
    </>
  )
}
