import { MainNav } from './main-nav/MainNav'
import { NavItem } from '../../../shared/components/cards/nav-item/NavItem'
import { ProfileDrop } from './profile-drop/ProfileDrop'
import { ProfileDropItem } from './profile-drop/profile-drop-item/ProfileDropItem'
import { useWindowSize } from '../../../shared/hooks/UseWindowSize'
import { IconLogout } from '../../../shared/components/icons/IconLogout'
import { IconProfile } from '../../../shared/components/icons/IconProfile'
import { NavDrop } from './nav-drop/NavDrop'
import { NavLink, useLocation } from 'react-router-dom'
import { useAuth } from '../../../shared/hooks/UseAuth'
import { selectUser } from '../../../store/reducers/AuthSlice'
import { useAppSelector } from '../../../store/hooks/Redux'
import { NavDropItem } from '../../../shared/components/cards/nav-drop-item/NavDropItem'
import { IconDocument } from '../../../shared/components/icons/IconDocument'
import { IconPaidResults } from '../../../shared/components/icons/IconPaidResults'
import { IconSeoPosition } from '../../../shared/components/icons/IconSeoPosition'
import { IconSeoSearch } from '../../../shared/components/icons/IconSeoSearch'
import { NavLinkItem } from '../../../shared/components/cards/nav-link-item/NavLinkItem'
import { NavCollapseItem } from '../../../shared/components/cards/nav-collapse-item/NavCollapseItem'
import { IconBarChart } from '../../../shared/components/icons/IconBarChart'
import { IconZap } from '../../../shared/components/icons/IconZap'
import { IconSpy } from '../../../shared/components/icons/IconSpy'
import { IconZapSearch } from '../../../shared/components/icons/IconZapSearch'
import { useTranslation } from 'react-i18next'
import { IconPPCCompliance } from '../../../shared/components/icons/IconPPCCompliance'
import { IconLanguageCompliance } from '../../../shared/components/icons/IconLanguageCompliance'

export const Header = () => {
  const { t } = useTranslation()
  const { xs, sm, xl } = useWindowSize()
  const iconColor = '#0E63D8'
  const user = useAppSelector(selectUser)
  const { logout } = useAuth()
  const { pathname } = useLocation()

  return (
    <div className='flex items-center relative gap-[16px]'>
      <NavLink to='/'>
        <img alt='logo' src={'/logo.svg'} className='cursor-pointer w-[111px]' />
      </NavLink>
      {xl && (
        <div className='flex-1'>
          <MainNav>
            <NavLink to='/' end>
              {({ isActive }) => <NavItem active={isActive}>{t('navigation.overview')}</NavItem>}
            </NavLink>
            <NavDropItem
              label={t('navigation.SERPMonitoring')}
              active={['/ad-reports', '/organic-results', '/live-search'].includes(pathname)}
            >
              <NavLinkItem to='/ad-reports' icon={<IconPaidResults />}>
                {t('navigation.paidResults')}
              </NavLinkItem>
              <NavLinkItem to='/organic-results' icon={<IconDocument />}>
                {t('navigation.organicResults')}
              </NavLinkItem>
              <NavLinkItem to='/live-search' icon={<IconZapSearch />} label='Beta'>
                {t('navigation.liveSearch')}
              </NavLinkItem>
            </NavDropItem>
            <NavDropItem
              label={t('navigation.nonCompliantAffiliates')}
              active={['/ppc-compliance', '/language-compliance-page'].includes(pathname)}
            >
              <NavLinkItem to='/ppc-compliance' icon={<IconPPCCompliance />}>
                PPC Compliance
              </NavLinkItem>
              <NavLinkItem to='/language-compliance-page' icon={<IconLanguageCompliance />}>
                Language Compliance
              </NavLinkItem>
            </NavDropItem>
            <NavDropItem
              label={t('navigation.researchTools')}
              active={['/position-tracker', '/serp-presence', '/advertising-research'].includes(
                pathname,
              )}
            >
              <NavLinkItem to='/position-tracker' icon={<IconSeoPosition />}>
                {t('navigation.SEOPositionTracker')}
              </NavLinkItem>
              <NavLinkItem to='/serp-presence' icon={<IconBarChart />}>
                {t('navigation.onSiteBrandMentions')}
              </NavLinkItem>
              <NavLinkItem to='/advertising-research' icon={<IconSeoSearch />}>
                {t('navigation.advertisingResearch')}
              </NavLinkItem>
            </NavDropItem>
            <NavDropItem
              label={t('navigation.uncloaking')}
              active={['/live-uncloaking', '/blackhat-monitoring'].includes(pathname)}
              hidden={!user.allow_decloak_live && !user.allow_decloak_auto}
            >
              <NavLinkItem
                to='/live-uncloaking'
                icon={<IconZap />}
                hidden={!user.allow_decloak_live}
              >
                {t('navigation.liveUncloaking')}
              </NavLinkItem>
              <NavLinkItem
                to='/blackhat-monitoring'
                icon={<IconSpy />}
                hidden={!user.allow_decloak_auto}
              >
                {t('navigation.blackhatMonitoring')}
              </NavLinkItem>
            </NavDropItem>
          </MainNav>
        </div>
      )}
      <div className='ml-auto flex justify-end gap-[16px] w-[calc(100%-127px)] md:w-auto'>
        <ProfileDrop
          username={user ? user.username : ''}
          short={xs || sm}
          className='max-w-[calc(100%-52px)] md:max-w-full'
        >
          <NavLink to='/profile-settings'>
            <ProfileDropItem icon={<IconProfile color={iconColor}></IconProfile>}>
              {t('pages.profile.profile')}
            </ProfileDropItem>
          </NavLink>
          <ProfileDropItem
            icon={<IconLogout color={iconColor}></IconLogout>}
            onClick={() => logout()}
          >
            {t('pages.profile.logout')}
          </ProfileDropItem>
        </ProfileDrop>
        {!xl && (
          <div className='z-10'>
            <NavDrop>
              <NavLink to='/' end>
                {({ isActive }) => (
                  <NavItem theme='dark' size='sm' decoration='dot' active={isActive}>
                    {t('navigation.overview')}
                  </NavItem>
                )}
              </NavLink>
              <NavCollapseItem
                label={t('navigation.SERPMonitoring')}
                theme='dark'
                size='sm'
                decoration='dot'
                active={['/ad-reports', '/organic-results', '/live-search'].includes(pathname)}
              >
                <NavLinkItem to='/ad-reports' icon={<IconPaidResults />}>
                  {t('navigation.paidResults')}
                </NavLinkItem>
                <NavLinkItem to='/organic-results' icon={<IconDocument />}>
                  {t('navigation.organicResults')}
                </NavLinkItem>
                <NavLinkItem to='/live-search' icon={<IconZapSearch />} label='Beta'>
                  {t('navigation.liveSearch')}
                </NavLinkItem>
              </NavCollapseItem>
              <NavCollapseItem
                label={t('navigation.nonCompliantAffiliates')}
                theme='dark'
                size='sm'
                decoration='dot'
                active={['/ppc-compliance', '/language-compliance-page'].includes(pathname)}
              >
                <NavLinkItem to='/ppc-compliance' icon={<IconPPCCompliance />}>
                  PPC Compliance
                </NavLinkItem>
                <NavLinkItem to='/language-compliance-page' icon={<IconLanguageCompliance />}>
                  Language Compliance
                </NavLinkItem>
              </NavCollapseItem>
              <NavCollapseItem
                label={t('navigation.researchTools')}
                theme='dark'
                size='sm'
                decoration='dot'
                active={['/position-tracker', '/serp-presence', '/advertising-research'].includes(
                  pathname,
                )}
              >
                <NavLinkItem to='/position-tracker' icon={<IconSeoPosition />}>
                  {t('navigation.SEOPositionTracker')}
                </NavLinkItem>
                <NavLinkItem to='/serp-presence' icon={<IconBarChart />}>
                  {t('navigation.onSiteBrandMentions')}
                </NavLinkItem>
                <NavLinkItem to='/advertising-research' icon={<IconSeoSearch />}>
                  {t('navigation.advertisingResearch')}
                </NavLinkItem>
              </NavCollapseItem>
              <NavCollapseItem
                label={t('navigation.uncloaking')}
                theme='dark'
                size='sm'
                decoration='dot'
                active={['/live-uncloaking', '/blackhat-monitoring'].includes(pathname)}
              >
                <NavLinkItem to='/live-uncloaking' icon={<IconZap />}>
                  {t('navigation.liveUncloaking')}
                </NavLinkItem>
                <NavLinkItem to='/blackhat-monitoring' icon={<IconSpy />}>
                  {t('navigation.blackhatMonitoring')}
                </NavLinkItem>
              </NavCollapseItem>
            </NavDrop>
          </div>
        )}
      </div>
    </div>
  )
}
