import { Square } from '../../../shared/components/utility/square/Square'
import { HTMLAttributes } from 'react'

export const CommonMainPage = ({ children, className }: HTMLAttributes<HTMLElement>) => {
  return (
    <Square className={`flex flex-col min-w-full p-[16px] xl:p-[32px] ${className}`} depth='100'>
      {children}
    </Square>
  )
}
