import React, { ComponentType, ForwardedRef, forwardRef } from 'react'
import { TFieldSize } from '../components/forms/utility/field/Field'

export interface IWithFieldSizeProps {
  fieldSize?: TFieldSize
  classSize?: string
}

export function withFieldSize<T>(WrappedComponent: ComponentType<T>) {
  return forwardRef(
    (props: T & IWithFieldSizeProps, ref: ForwardedRef<IWithFieldSizeProps & T>) => {
      const { fieldSize = 'md' } = props
      return (
        <WrappedComponent
          {...props}
          ref={ref}
          classSize={(() => {
            switch (fieldSize) {
              case 'sm':
                return 'h-[32px] text-[14px]'
              case 'md':
                return 'h-[40px] text-[15px]'
              case 'lg':
                return 'h-[48px] text-[16px]'
            }
          })()}
        ></WrappedComponent>
      )
    },
  )
}
