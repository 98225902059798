import React, { HTMLAttributes, useEffect, useState } from 'react'

export interface IFieldDropProps extends HTMLAttributes<HTMLElement> {
  open?: boolean
}

export const FieldDrop = ({ children, open }: IFieldDropProps) => {
  const initialClassShowResult = 'opacity-0'
  const [classShowResult, setClassShowResult] = useState(initialClassShowResult)

  useEffect(() => {
    open ? openDrop() : closeDrop()
  }, [open])

  const openDrop = () => setClassShowResult('opacity-100')
  const closeDrop = () => {
    setTimeout(() => setClassShowResult('opacity-0 invisible'), 150)
    setClassShowResult(initialClassShowResult)
  }

  return (
    <div
      className={`absolute
        w-full
        transition-opacity
        duration-150
        top-full
        z-50
        ${open ? 'pointer-events-auto' : 'pointer-events-none'}
        ${classShowResult}`}
    >
      {children}
    </div>
  )
}
