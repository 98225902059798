import { AdvertiserReportFilters } from './components/filters/AdvertiserReportFilters'
import { CommonMainPage } from '../utility/CommonMainPage'
import { AdvertiserReportTable } from './components/report/AdvertiserReportTable'

export const AdvertiserReportPage = () => {
  return (
    <CommonMainPage>
      <section className='flex flex-col gap-[50px] min-h-[950px] lg:min-h-[750px]'>
        <AdvertiserReportFilters />
        <AdvertiserReportTable />
      </section>
    </CommonMainPage>
  )
}
