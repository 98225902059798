import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconClock = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_313_10)'>
        <mask id='mask0_313_10' x='0' y='0'>
          <path d='M16 0H0V16H16V0Z' fill='white' />
        </mask>
        <g mask='url(#mask0_313_10)'>
          <path
            d='M8.00016 4.00001V8.00001L10.6668 9.33334M14.6668 8.00001C14.6668 11.6819 11.682 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00001C1.3335 4.31811 4.31826 1.33334 8.00016 1.33334C11.682 1.33334 14.6668 4.31811 14.6668 8.00001Z'
            stroke={color}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_313_10'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
