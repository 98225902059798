import { BpSelect } from '../../../../../shared/components/forms/select/BpSelect'
import { IconFolder } from '../../../../../shared/components/icons/IconFolder'
import {
  useGetCountryQuery,
  useGetDomainsQuery,
  useGetProjectsQuery,
} from '../../../../../api/filter/Filter.api'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { selectResearchFilters, setResearchFilter } from '../../store/ResearchSlice'
import { IResearchFilter } from '../../interfaces/ResearchFilter.interface'
import {
  BpSearchInput,
  IOption,
} from '../../../../../shared/components/forms/search-input/BpSearchInput'
import { IDomainParams } from '../../../../../api/filter/interfaces/DomainParams.interface'
import { IconCountry } from '../../../../../shared/components/icons/IconCountry'
import {
  IWithSearchParamsProps,
  withSearchParams,
} from '../../../../../shared/hoc/WithSearchParams'
import { useTranslation } from 'react-i18next'

const Filter = ({ searchParams, setSearchParams }: IWithSearchParamsProps<IResearchFilter>) => {
  const { t } = useTranslation()
  const { domain, country, project } = useAppSelector<IResearchFilter>(selectResearchFilters)
  const dispatch = useAppDispatch()

  const {
    getValues,
    setValue,
    control,
    formState: { defaultValues },
  } = useForm<IResearchFilter>({
    mode: 'onChange',
    defaultValues: {
      domain,
      country,
      project,
      ...searchParams,
    },
  })

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetProjectsQuery()

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery(
    {
      project_id: project ? +project : undefined,
    },
    {
      skip: !project,
    },
  )

  const {
    data: domains,
    isFetching: domainsFetching,
    isLoading: domainsLoading,
  } = useGetDomainsQuery(
    {
      project_id: project,
      search: domain,
      seo:false,
    } as IDomainParams,
    { skip: !project },
  )

  const applyFilter = () => {
    const values = getValues()
    dispatch(setResearchFilter(getValues()))
    if (setSearchParams) setSearchParams(values)
  }

  return (
    <>
      <form className='flex flex-col lg:items-center max-w-full gap-y-[12px] lg:flex-row lg:flex-wrap lg:gap-x-[8px] xl:flex-nowrap'>
        <div className='flex-1 xl:grow'>
          <Controller
            name='project'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.project
                ? defaultValues.project
                : project
                ? project
                : projects && projects[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={projects}
                  Icon={IconFolder}
                  fieldSize='lg'
                  title={t('fields.project')}
                  defaultValue={defaultValue}
                  loading={projectsLoading || projectsFetching}
                  disabled={projectsLoading || projectsFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    if (e.target.value !== defaultValue) {
                      setValue('country', [])
                      setValue('domain', '')
                    }
                    applyFilter()
                  }}
                />
              )
            }}
          />
        </div>
        <div className='flex-1 xl:flex-grow-[2]'>
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => (
              <BpSelect
                {...fieldProps}
                options={countries}
                Icon={IconCountry}
                fieldSize='lg'
                title={t('fields.country')}
                multiple
                loading={countriesLoading || countriesFetching}
                disabled={countriesLoading || countriesFetching}
                defaultValue={defaultValues?.country as string[]}
                showSearch
                onChange={(e) => {
                  onChange([].slice.call(e.target.selectedOptions).map((o: IOption) => o.value))
                  applyFilter()
                }}
              />
            )}
          />
        </div>
        <div className='flex-1 xl:grow'>
          <Controller
            name='domain'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              return (
                <BpSearchInput
                  {...fieldProps}
                  fieldSize='lg'
                  placeholder={t('fields.domain')}
                  options={domains}
                  loading={domainsLoading || domainsFetching}
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                />
              )
            }}
          />
        </div>
      </form>
    </>
  )
}

export const ResearchToolsFilters = withSearchParams<
  IWithSearchParamsProps<IResearchFilter>,
  IResearchFilter
>(Filter, selectResearchFilters)
