import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IBlackhatMonitoringFilters } from '../interfaces/BlackhatMonitoringFilters.interface'
import { RootState } from '../../../../store/Store'

export type TBlackhatMonitoringTabs = 'Screenshots' | 'Html' | 'Links' | 'Ad'

type BlackhatMonitoringState = {
  filters: IBlackhatMonitoringFilters
  activeTab: TBlackhatMonitoringTabs
}

const blackhatMonitoringSlice = createSlice({
  name: 'blackhatMonitoring',
  initialState: {
    filters: {
      domain: '',
      country: '',
      date: '',
      time: '',
    },
    activeTab: 'Screenshots',
  } as BlackhatMonitoringState,
  reducers: {
    setBlackhatMonitoringFilter: (
      state: BlackhatMonitoringState,
      { payload }: PayloadAction<IBlackhatMonitoringFilters>,
    ) => {
      state.filters = { ...state.filters, ...payload }
    },
    setBlackhatMonitoringDomain: (
      state: BlackhatMonitoringState,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.filters = { ...state.filters, domain: payload }
    },
    setBlackhatMonitoringCountry: (
      state: BlackhatMonitoringState,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.filters = { ...state.filters, country: payload }
    },
    setBlackhatMonitoringDate: (
      state: BlackhatMonitoringState,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.filters = { ...state.filters, date: payload }
    },
    setBlackhatMonitoringTime: (
      state: BlackhatMonitoringState,
      { payload }: PayloadAction<string | undefined>,
    ) => {
      state.filters = { ...state.filters, time: payload }
    },
    setBlackhatMonitoringTab: (
      state: BlackhatMonitoringState,
      { payload }: PayloadAction<TBlackhatMonitoringTabs>,
    ) => {
      state.activeTab = payload
    },
  },
})

export const {
  setBlackhatMonitoringFilter,
  setBlackhatMonitoringDomain,
  setBlackhatMonitoringCountry,
  setBlackhatMonitoringDate,
  setBlackhatMonitoringTime,
  setBlackhatMonitoringTab,
} = blackhatMonitoringSlice.actions

export const selectBlackhatMonitoringFilters = (state: RootState) =>
  state.root.blackhatMonitoring.filters

export const selectBlackhatMonitoringTab = (state: RootState) =>
  state.root.blackhatMonitoring.activeTab

export default blackhatMonitoringSlice.reducer
