import React from 'react'
import { useAppSelector } from '../../../../../../store/hooks/Redux'
import { useGetCompetitorsChartQuery } from '../../../../../../api/research/Research.api'
import { IResearchFilter } from '../../../interfaces/ResearchFilter.interface'
import { ICompetitorsChartParams } from '../../../../../../api/research/interfaces/CompetitorsChartParams.interface'
import { selectResearchFilters } from '../../../store/ResearchSlice'
import {
  changeCompetitorsDateRange,
  selectCompetitorsFilter,
} from '../../../store/CompetitorsSlice'
import { ICompetitorsFilter } from '../../../interfaces/CompetitorsFilter.interface'
import { useDispatch } from 'react-redux'
import { GraphReport } from '../../../../../../shared/components/cards/graph-report/GraphReport'
import { useTranslation } from 'react-i18next'

export const ByVisibilityGraph = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { country, project } = useAppSelector<IResearchFilter>(selectResearchFilters)
  const { sites, dateRange: filter } = useAppSelector<ICompetitorsFilter>(selectCompetitorsFilter)
  const { date, group: date_group } = filter

  const { data } = useGetCompetitorsChartQuery(
    {
      date_group,
      date,
      chart_site_ids: sites,
      project_ids: project ? [project] : [],
      country_ids: country ? country : [],
    } as ICompetitorsChartParams,
    { skip: sites === undefined || !sites.length },
  )

  return (
    <GraphReport
      title={t('pages.advertisingResearch.advertisersByAds')}
      filter={filter}
      series={data}
      onChange={(e) => dispatch(changeCompetitorsDateRange(e))}
    />
  )
}
