import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './shared/locales/en.json'
import ru from './shared/locales/ru.json'

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
}

void i18n.use(initReactI18next).init({
  resources,
  lng: process.env.REACT_APP_LANGUAGE,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
})
