import { BpSelect } from '../../../../../shared/components/forms/select/BpSelect'
import { BpSearchInput } from '../../../../../shared/components/forms/search-input/BpSearchInput'
import { IconFolder } from '../../../../../shared/components/icons/IconFolder'
import { IconCountry } from '../../../../../shared/components/icons/IconCountry'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { Controller, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import {
  useGetCountryQuery,
  useGetEnginesQuery,
  useGetKeywordsQuery,
  useGetLandingsQuery,
  useGetOwnDomainsQuery,
  useGetProjectsQuery,
} from '../../../../../api/filter/Filter.api'
import { FilterDrop } from '../../../../../shared/components/cards/filter-drop/FilterDrop'
import {
  selectPositionTrackerFilters,
  setPositionTrackerFilter,
} from '../../store/PositionTrackerSlice'
import { IPositionTrackerFilter } from '../../interfaces/IPositionTrackerFilter'
import { IKeywordsParams } from '../../../../../api/filter/interfaces/KeywordsParams.interface'
import {
  IWithSearchParamsProps,
  withSearchParams,
} from '../../../../../shared/hoc/WithSearchParams'
import { IconSearchEngine } from '../../../../../shared/components/icons/IconSearchEngine'
import { IOwnDomainsParams } from '../../../../../api/filter/interfaces/OwnDomainsParams.interface'
import { IconGlobe } from '../../../../../shared/components/icons/IconGlobe'
import { IDomainParams } from '../../../../../api/filter/interfaces/DomainParams.interface'
import { useTranslation } from 'react-i18next'

const Filter = ({
  searchParams,
  setSearchParams,
}: IWithSearchParamsProps<IPositionTrackerFilter>) => {
  const { t } = useTranslation()
  const { country, engine_id, project, site, keyword } = useAppSelector<IPositionTrackerFilter>(
    selectPositionTrackerFilters,
  )
  const dispatch = useAppDispatch()
  const [landingsSearch, setLandingsSearch] = useState<string | undefined>()

  const {
    getValues,
    setValue,
    control,
    formState: { defaultValues },
  } = useForm<IPositionTrackerFilter>({
    mode: 'onChange',
    defaultValues: {
      country,
      engine_id,
      project,
      site,
      keyword,
      ...searchParams,
    },
  })

  useEffect(() => {
    applyFilter()
  }, [])

  const {
    data: projects,
    isFetching: projectsFetching,
    isLoading: projectsLoading,
  } = useGetProjectsQuery()

  const {
    data: countries,
    isFetching: countriesFetching,
    isLoading: countriesLoading,
  } = useGetCountryQuery({ project_id: project ? +project : undefined }, { skip: !project })

  const {
    data: engines,
    isFetching: enginesFetching,
    isLoading: enginesLoading,
  } = useGetEnginesQuery({ project_id: project ? +project : undefined }, { skip: !project })

  const {
    data: domains,
    isFetching: domainsFetching,
    isLoading: domainsLoading,
  } = useGetOwnDomainsQuery(
    {
      project_id: project ? +project : undefined,
    } as IOwnDomainsParams,
    { skip: !project },
  )

  const {
    data: landings,
    isFetching: landingsFetching,
    isLoading: landingsLoading,
  } = useGetLandingsQuery(
    {
      project_id: project ? +project : undefined,
      search: landingsSearch,
      seo:true,
    } as IDomainParams,
    { skip: !project },
  )

  const {
    data: keywords,
    isFetching: keywordsFetching,
    isLoading: keywordsLoading,
  } = useGetKeywordsQuery(
    {
      project_id: project,
      search: keyword,
    } as IKeywordsParams,
    { skip: !project },
  )

  const applyFilter = () => {
    const values = getValues()
    dispatch(setPositionTrackerFilter(values))
    if (setSearchParams) setSearchParams(values)
  }

  useEffect(() => {
    if (domains) {
      !domains.some((value) => value.text === site) && setValue('site', domains[0].value)
    }
  }, [domains])

  return (
    <>
      <form className='flex flex-col max-w-full gap-y-[12px] md:items-center md:flex-row md:flex-wrap md:gap-x-[8px] xl:flex-nowrap'>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='project'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.project
                ? defaultValues.project
                : project
                ? project
                : projects && projects[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={projects}
                  Icon={IconFolder}
                  fieldSize='lg'
                  title={t('fields.project')}
                  defaultValue={defaultValue}
                  loading={projectsLoading || projectsFetching}
                  disabled={projectsLoading || projectsFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    if (e.target.value !== defaultValue) {
                      setValue('country', '')
                    }
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-[calc(25%-2.5px)] xl:flex-1'>
          <Controller
            name='site'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.site
                ? defaultValues.site
                : site
                ? site
                : domains && domains[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={domains}
                  Icon={IconGlobe}
                  fieldSize='lg'
                  title={t('fields.domain')}
                  defaultValue={defaultValue}
                  loading={domainsLoading || domainsFetching}
                  disabled={domainsLoading || domainsFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='country'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.country
                ? defaultValues.country
                : country
                ? country
                : countries && countries[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={countries}
                  Icon={IconCountry}
                  fieldSize='lg'
                  title={t('fields.country')}
                  defaultValue={defaultValue}
                  loading={countriesLoading || countriesFetching}
                  disabled={countriesLoading || countriesFetching}
                  showSearch
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='md:w-[calc(50%-4px)] lg:w-[calc(33%-2.5px)] xl:w-auto xl:flex-1'>
          <Controller
            name='engine_id'
            control={control}
            render={({ field: { onChange, ...fieldProps } }) => {
              const defaultValue = defaultValues?.engine_id
                ? defaultValues.engine_id
                : engine_id
                ? engine_id
                : engines && engines[0].value

              return (
                <BpSelect
                  {...fieldProps}
                  options={engines}
                  Icon={IconSearchEngine}
                  fieldSize='lg'
                  title={t('fields.searchEngine')}
                  defaultValue={defaultValue}
                  loading={enginesLoading || enginesFetching}
                  disabled={enginesLoading || enginesFetching}
                  onChange={(e) => {
                    onChange(e)
                    applyFilter()
                  }}
                ></BpSelect>
              )
            }}
          />
        </div>
        <div className='flex flex-col w-full lg:w-[calc(50%-4px)] xl:w-auto'>
          <FilterDrop
            onSubmit={applyFilter}
            onClear={() => {
              setValue('keyword', '')
              setValue('landing', '')
            }}
            values={getValues(['keyword', 'landing'])}
          >
            <Controller
              name='keyword'
              control={control}
              render={({ field }) => (
                <BpSearchInput
                  {...field}
                  label={t('fields.keyword')}
                  placeholder={t('fields.other.search')}
                  options={keywords}
                  loading={keywordsLoading || keywordsFetching}
                ></BpSearchInput>
              )}
            />
            <Controller
              name='landing'
              control={control}
              render={({ field }) => (
                <BpSearchInput
                  {...field}
                  label={t('fields.pageUrl')}
                  placeholder={t('fields.other.search')}
                  options={landings}
                  loading={landingsLoading || landingsFetching}
                  onChange={(e) => {
                    field.onChange(e)
                    setLandingsSearch(field.value)
                  }}
                ></BpSearchInput>
              )}
            />
          </FilterDrop>
        </div>
      </form>
    </>
  )
}

export const PositionTrackerFilters = withSearchParams<
  IWithSearchParamsProps<IPositionTrackerFilter>,
  IPositionTrackerFilter
>(Filter, selectPositionTrackerFilters)
