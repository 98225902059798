import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks/Redux'
import { BpTable } from '../../../../../shared/components/cards/table/BpTable'
import { TTableResponse } from '../../../../../shared/types/TableResponse.type'
import { ColumnsType } from 'antd/lib/table'
import { IBpPaginationProps } from '../../../../../shared/components/cards/pagination/BpPagination'
import { IOrganicResultsFilter } from '../../interfaces/IOrganicResultsFilter'
import {
  selectOrganicResultsFilters,
  selectOrganicResultsPagination,
  setHasBrand,
  setOrganicResultsPagination,
} from '../../store/OrganicResultsSlice'
import {
  useEditOrganicSettingsMutation,
  useExportOrganicResultsMutation,
  useGetOrganicResultsQuery,
} from '../../../../../api/advertiser-report/AdvertiserReport.api'
import { IOrganicModel } from '../../../../../api/advertiser-report/models/Organic.model'
import { BpAnchor } from '../../../../../shared/components/text/anchor/BpAnchor'
import { IEditSiteSettingsParams } from '../../../../../api/advertiser-report/interfaces/EditSiteSettingsParams.interface'
import { IconButton } from '../../../../../shared/components/buttons/icon-button/IconButton'
import { TriangleButton } from '../../../../../shared/components/buttons/triangle/TriangleButton'
import { IconEyeOff } from '../../../../../shared/components/icons/IconEyeOff'
import { IconEye } from '../../../../../shared/components/icons/IconEye'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../../shared/hooks/UseSorter'
import { ExportDrop } from '../../../../../shared/components/cards/export-drop/ExportDrop'
import { OrganicResultsExpandRow } from './OrganicResultsExpandRow'
import { IconMaximize } from '../../../../../shared/components/icons/IconMaximize'
import { IconMinimize } from '../../../../../shared/components/icons/IconMinimize'
import { IWithExpandedAllProps, withExpanded } from '../../../../../shared/hoc/WithExpanded'
import { BpButton } from '../../../../../shared/components/buttons/bp-button/BpButton'
import { BpTooltip } from '../../../../../shared/components/cards/tooltip/BpTooltip'
import { Switch } from 'antd'
import { IOrganicParams } from '../../../../../api/advertiser-report/interfaces/OrganicParams'
import { IPagination } from '../../../../../shared/interfaces/Pagination.interface'
import { PositionCell } from '../../../../../shared/components/cells/PositionCell'
import { useTranslation } from 'react-i18next'

const Table = ({
  onToggleExpand,
  isExpandAll,
  expandedRowKeys,
  onToggleExpandAll,
}: IWithExpandedAllProps) => {
  const { t } = useTranslation()
  const [countRows, setCountRows] = useState<number>(0)
  const organicResultsFilter = useAppSelector<IOrganicResultsFilter>(selectOrganicResultsFilters)
  const organicResultsPagination = useAppSelector<IPagination>(selectOrganicResultsPagination)
  const {
    project,
    engine_id,
    dateRange: date,
    country,
    policy,
    site,
    keyword,
    affiliate,
    show_hidden,
    show_affiliates,
    has_brand,
  } = organicResultsFilter
  const { current, pageSize, total } = organicResultsPagination

  const [queryParams, setQueryParams] = useState<IOrganicParams>({
    page: current,
    'per-page': pageSize,
  })
  const { data, isLoading, isFetching } = useGetOrganicResultsQuery(queryParams as IOrganicParams, {
    skip: !queryParams.project_id,
  })
  const [exportOrganicResults, { isLoading: exportLoading }] = useExportOrganicResultsMutation()
  const [editOrganicSetting] = useEditOrganicSettingsMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!project) return

    setQueryParams({
      ...queryParams,
      project_id: +project,
      engine_id: +engine_id,
      country_id: country ? +country : undefined,
      policy,
      date,
      site,
      keyword,
      affiliate,
      show_hidden,
      show_affiliates,
      has_brand,
    })
  }, [organicResultsFilter])

  useEffect(() => {
    if (data?.data) setCountRows(data.data.total)
  }, [data])

  const columns: ColumnsType<IOrganicModel> = [
    {
      title: t('table.columns.domain'),
      dataIndex: 'domain',
      key: 'domain',
      render: (_: string, { domain, has_brand }: IOrganicModel) => {
        return (
          <div>
            <BpAnchor className='flex-1 leading-5' target='_blank' href={`https://${domain}`}>
              {domain}
            </BpAnchor>
            {has_brand && (
              <span
                className={`inline-block
                  font-semibold
                  uppercase
                  text-[10px]
                  leading-5
                  px-2
                  rounded-md
                  bg-focus-50
                  ml-2
                  text-aqua-300
                  cursor-default`}
              >
                {t('pages.organicResults.hasBrand')}
              </span>
            )}
          </div>
        )
      },
      width: '320px',
    },
    {
      title: t('table.columns.pages'),
      dataIndex: 'pages_count',
      key: 'pages_count',
      sorter: true,
    },
    {
      title: t('table.columns.keywords'),
      dataIndex: 'keywords_count',
      key: 'keywords_count',
      sorter: true,
    },
    {
      title: t('table.columns.affiliates'),
      dataIndex: 'affiliates_count',
      key: 'affiliates_count',
      sorter: true,
    },
    {
      title: t('table.columns.avgPosition'),
      dataIndex: 'position',
      key: 'position',
      render: (_: string, { position, previous_position }: IOrganicModel) => {
        const current = +position.toFixed(1)
        const previous = previous_position !== null && +previous_position.toFixed(1)

        return <PositionCell current={current} previous={previous} />
      },
      sorter: true,
    },
    {
      title: t('table.columns.lastFound'),
      dataIndex: 'last_found',
      key: 'last_found',
      width: '150px',
      sorter: true,
    },
    {
      key: 'hidden_action',
      render: (_: IOrganicModel, { is_hide, domain }: IOrganicModel) => {
        return (
          <IconButton
            size='lg'
            onClick={() => {
              if (!project) return
              editOrganicSetting({
                project_id: +project,
                domain,
                is_hide: !is_hide ? 1 : 0,
              } as IEditSiteSettingsParams)
            }}
          >
            {is_hide ? <IconEye color='#A059FF' /> : <IconEyeOff color='#A059FF' />}
          </IconButton>
        )
      },
      className: 'action',
    },
  ]

  const handleTableChange = (
    { pageSize, current, total }: IBpPaginationProps,
    sorter?: SorterResult<IOrganicModel> | SorterResult<IOrganicModel>[],
  ) => {
    dispatch(
      setOrganicResultsPagination({
        pageSize: pageSize as number,
        current: current as number,
        total: total as number,
      }),
    )
    setQueryParams({
      ...queryParams,
      page: current,
      'per-page': pageSize,
      sort: useSorter(sorter),
    })
  }

  return (
    <section>
      <div className='mb-[16px] flex flex-col md:flex-row justify-between md:items-center gap-[16px]'>
        <h3 className='flex gap-3 items-center font-medium'>
          {t('pages.organicResults.organicResults')}: {countRows}
          <BpTooltip title={t('pages.organicResults.organicResultsTooltip')} />
        </h3>
        <span className='flex items-center gap-[9px] ml-auto mr-2 text-focus-500 text-[15px] leading-[24px]'>
          {t('fields.hasBrand')}
          <Switch
            checked={queryParams?.has_brand}
            onClick={() => dispatch(setHasBrand(!queryParams?.has_brand))}
          ></Switch>
        </span>
        <span className='flex gap-[9px] items-center'>
          <BpButton
            size='sm'
            theme='outline'
            type='button'
            onClick={() => {
              if (onToggleExpandAll) onToggleExpandAll(!isExpandAll)
            }}
            icon={isExpandAll ? <IconMinimize /> : <IconMaximize />}
          ></BpButton>
          <ExportDrop
            onExport={(exportParams) => exportOrganicResults({ ...queryParams, ...exportParams })}
            loading={exportLoading}
            columns={[
              { keys: ['domain'], label: t('table.columns.domain') },
              { keys: ['pages_count'], label: t('table.columns.pages') },
              { keys: ['keywords_count'], label: t('table.columns.keywords') },
              { keys: ['affiliates_count'], label: t('table.columns.affiliates') },
              { keys: ['position'], label: t('table.columns.avgPosition') },
              { keys: ['previous_position'], label: t('table.columns.previousPosition') },
              { keys: ['last_found'], label: t('table.columns.lastFound') },
            ]}
          />
        </span>
      </div>
      <BpTable
        dataSource={data?.data?.items}
        rowKey={(record) => `${record.domain}`}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<IOrganicModel>}
        rowClassName={(e) => (e.is_hide ? 'disabled' : '')}
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => (
            <IconButton onClick={(e) => onExpand(record, e)}>
              <TriangleButton color='#A059FF' position={expanded ? 'top' : 'down'} />
            </IconButton>
          ),
          expandedRowRender: (record: IOrganicModel) => <OrganicResultsExpandRow {...record} />,
          onExpand: (expanded, { domain }) => {
            if (onToggleExpand) onToggleExpand(expanded, `${domain}`)
          },
          expandedRowKeys: isExpandAll
            ? data?.data?.items.map((item) => item.domain)
            : expandedRowKeys,
        }}
        pagination={{
          current,
          pageSize,
          total,
        }}
      />
    </section>
  )
}

export const OrganicResultsTable = withExpanded(Table)
