import { BpInput } from '../../../../shared/components/forms/input/BpInput'
import { BpButton } from '../../../../shared/components/buttons/bp-button/BpButton'
import { useForm } from 'react-hook-form'
import { BpLink } from '../../../../shared/components/text/link/BpLink'
import { FormHTMLAttributes } from 'react'
import { BpAnchor } from '../../../../shared/components/text/anchor/BpAnchor'
import { useTranslation } from 'react-i18next'

export interface ILoginFormData {
  email: string
  password: string
}

export interface ILoginFormProps extends FormHTMLAttributes<HTMLFormElement> {
  submitHandler?: (data: ILoginFormData) => void
  isFetching?: boolean
}

export const LoginForm = ({ submitHandler, isFetching = false, ...formProps }: ILoginFormProps) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<ILoginFormData>({
    mode: 'onChange',
  })

  return (
    <>
      <h2 className='mb-[24px]'>{t('pages.auth.logIn')}</h2>
      <form
        {...formProps}
        className='flex flex-col gap-[16px]'
        onSubmit={handleSubmit((data: ILoginFormData) => {
          if (submitHandler) submitHandler(data)
        })}
      >
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.email')}
          error={errors.email?.message}
          required
          {...register('email', { required: 'Required' })}
        ></BpInput>
        <BpInput
          fieldSize='lg'
          label={t('pages.auth.password')}
          type='password'
          required
          error={errors.password?.message}
          {...register('password', { required: 'Required' })}
        ></BpInput>
        <div className='flex justify-between gap-[16px] items-center'>
          {/* TODO refactoring <BpCheckbox {...register('remember')} label='Remember me'></BpCheckbox> */}
          <span className='flex gap-[16px] text-[14px]'>
            <BpLink to='/auth/reset-password'>{t('pages.auth.resetPassword')}</BpLink>
            {
              {
                bluepear: (
                  <BpAnchor href='mailto:sales@bluepear.co'>
                    {t('pages.auth.contactSupport')}
                  </BpAnchor>
                ),
                robuscan: null,
                default: null,
              }[process.env.REACT_APP_PROJECT ?? 'default']
            }
          </span>
        </div>
        <div className='mt-[16px] flex flex-col'>
          <BpButton
            className={'uppercase'}
            type='submit'
            disabled={!isValid || isFetching}
            size='lg'
          >
            {t('pages.auth.buttons.login')}
          </BpButton>
        </div>
      </form>
    </>
  )
}
