import React, { useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { useExportAdsMutation, useGetAdsQuery } from '../../../../../api/research/Research.api'
import { IAdModel } from '../../../../../api/research/models/Ad.model'
import { BpArticle } from '../../../../../shared/components/cards/article/BpArticle'
import { useNavigate, useParams } from 'react-router-dom'
import { IAdsParams } from '../../../../../api/research/interfaces/AdsParams.interface'
import { IconButton } from '../../../../../shared/components/buttons/icon-button/IconButton'
import { IconArrowBack } from '../../../../../shared/components/icons/IconArrowBack'
import { useAppSelector } from '../../../../../store/hooks/Redux'
import { selectCompetitorsFilter } from '../../store/CompetitorsSlice'
import { TTableResponse } from '../../../../../shared/types/TableResponse.type'
import { BpTable } from '../../../../../shared/components/cards/table/BpTable'
import { IBpPaginationProps } from '../../../../../shared/components/cards/pagination/BpPagination'
import { selectResearchFilters } from '../../store/ResearchSlice'
import { ICompetitorsFilter } from '../../interfaces/CompetitorsFilter.interface'
import { IResearchFilter } from '../../interfaces/ResearchFilter.interface'
import { SorterResult } from 'antd/lib/table/interface'
import { useSorter } from '../../../../../shared/hooks/UseSorter'
import { ExportDrop } from '../../../../../shared/components/cards/export-drop/ExportDrop'
import { useTranslation } from 'react-i18next'

export const ColumnReportTable = () => {
  const { t } = useTranslation()
  const { id, domain, keyword } = useParams()
  const navigate = useNavigate()
  const [countRows, setCountRows] = useState<number>(0)
  const {
    dateRange: { date },
  } = useAppSelector<ICompetitorsFilter>(selectCompetitorsFilter)
  const { country } = useAppSelector<IResearchFilter>(selectResearchFilters)
  const [queryParams, setQueryParams] = useState<IAdsParams>({
    page: 1,
    'per-page': 10,
    project_id: id ? +id : undefined,
    domain,
    date,
    country_id: country ?? [],
    keyword,
  } as IAdsParams)

  const { data, isLoading, isFetching } = useGetAdsQuery(queryParams, {
    skip: id == undefined,
  })

  const [exportFile, { isLoading: exportAdsLoading }] = useExportAdsMutation()

  useEffect(() => {
    if (!data?.data) return
    setCountRows(data.data.total)
  }, [data])

  const columns: ColumnsType<IAdModel> = [
    {
      title: t('table.columns.ad'),
      dataIndex: 'text',
      key: 'text',
      render: (_: string, record: IAdModel) => {
        return <BpArticle title={record.title} text={record.text} link={record.finish_domain} />
      },
      width: 500,
    },
    {
      title: t('table.columns.keyword(s)'),
      dataIndex: 'keywords',
      key: 'keywords',
      width: 250,
    },
    {
      title: t('table.columns.firstFound'),
      dataIndex: 'first_found',
      key: 'first_found',
      width: 150,
    },
    {
      title: t('table.columns.lastFound'),
      dataIndex: 'last_found',
      key: 'last_found',
      width: 150,
      sorter: true,
    },
  ]

  const handleTableChange = (
    { current, pageSize }: IBpPaginationProps,
    sorter?: SorterResult<IAdModel> | SorterResult<IAdModel>[],
  ) => {
    setQueryParams({ ...queryParams, page: current, 'per-page': pageSize, sort: useSorter(sorter) })
  }

  return (
    <section>
      <div className='mb-[16px] flex flex-col md:flex-row gap-[16px] justify-between md:items-center'>
        <span className='flex gap-[16px]'>
          <IconButton size='lg' onClick={() => navigate(-1)}>
            <IconArrowBack color='#00326D' />
          </IconButton>
          <h3 className='flex-1 font-medium'>
            {countRows} {t('pages.advertisingResearch.adsFor')} “{keyword && keyword}
            {domain && domain}”
          </h3>
        </span>
        <ExportDrop
          onExport={(exportParams) => exportFile({ ...queryParams, ...exportParams })}
          loading={exportAdsLoading}
          columns={[
            { label: t('table.columns.ad'), keys: ['text', 'title', 'finish_domain'] },
            { label: t('table.columns.keyword(s)'), keys: ['keywords'] },
            { label: t('table.columns.firstFound'), keys: ['first_found'] },
            { label: t('table.columns.lastFound'), keys: ['last_found'] },
          ]}
        />
      </div>
      <BpTable
        dataSource={data?.data?.items}
        rowKey={({ title, last_found }) => `${title}${last_found}`}
        columns={columns}
        loading={isLoading || isFetching}
        onChange={(pagination, filters, sorter) => handleTableChange(pagination, sorter)}
        data={data as TTableResponse<IAdModel>}
        scroll={{ x: '896px' }}
      ></BpTable>
    </section>
  )
}
