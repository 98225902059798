import { useLazyContactQuery } from '../../../api/site/Site.api'
import { SubmitHandler } from 'react-hook-form'
import { CommonAuthPage } from '../utility/CommonAuthPage'
import { ContactForm, IContactFormData } from './ContactForm'
import { ContactSuccess } from './ContactSuccess'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const ContactPage = () => {
  const { t } = useTranslation()
  const [triggerRequestContact, { isFetching, isLoading, isSuccess }] = useLazyContactQuery()

  const handleSubmit: SubmitHandler<IContactFormData> = (formData: IContactFormData) =>
    triggerRequestContact(formData)

  return (
    <>
      <Helmet>
        <title>Get a free demo and start using our tool today | {t('project.name')}</title>
        <meta
          name='description'
          content='Stay ahead of your competitors and cheating affiliates with our AI powered protection & tracking software'
        />
      </Helmet>
      {!isSuccess && (
        <>
          <CommonAuthPage
            form={<ContactForm submitHandler={handleSubmit} isFetching={isFetching || isLoading} />}
          >
            <h1 className='mb-[48px]'>Get in touch today and request a demo</h1>
            <p className='text-[24px] text-white leading-[36px] mb-[32px]'>
              There’s nothing quite like seeing our AI powered protection tool in action to
              understand exactly how it could transform your business results.
            </p>
          </CommonAuthPage>
          <svg
            className='absolute hidden right-[50px] lg:block top-[121px] xl:left-0 xl:right-0 xl:m-auto'
            width='308'
            height='759'
            viewBox='0 0 308 759'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g clipPath='url(#clip0_18388_3109)'>
              <path
                d='M217.695 244.35C220.333 244.505 227.936 272.551 228.867 275.495C233.056 289.13 238.332 313.302 239.418 318.57C244.539 346.615 230.884 511.943 192.559 736.15L179.68 735.531C171.146 608.165 162.612 480.954 154.078 353.588C155.63 316.556 157.181 279.368 158.733 242.336C158.733 242.491 217.695 244.35 217.695 244.35Z'
                fill='#4463CD'
              />
              <path
                d='M217.695 244.35C220.333 244.505 227.936 272.551 228.867 275.495C233.056 289.13 238.332 313.302 239.418 318.57C244.539 346.615 230.884 511.943 192.559 736.15L179.68 735.531C171.146 608.165 162.612 480.954 154.078 353.588C155.63 316.556 157.181 279.368 158.733 242.336C158.733 242.491 217.695 244.35 217.695 244.35Z'
                fill='#0E63D8'
              />
              <path
                d='M239.418 318.725C238.487 313.456 233.211 289.13 228.866 275.65C227.935 272.551 220.332 244.505 217.695 244.505L158.732 242.646C158.732 242.801 158.732 243.111 158.732 243.266C175.956 243.886 198.92 244.66 198.92 244.66C201.558 244.815 209.161 277.199 210.092 280.763C214.281 296.567 219.557 324.458 220.643 330.656C225.298 359.785 214.747 516.591 184.8 735.84L192.558 736.305C230.884 512.098 244.538 346.77 239.418 318.725Z'
                fill='#0E63D8'
              />
              <path
                d='M97.4438 263.874C97.4438 263.874 49.9637 317.485 68.8937 434.47C87.8236 551.454 115.288 736.305 115.288 736.305L128.166 735.685L154.699 359.476C154.699 359.476 171.302 326.937 172.388 260.62L97.4438 263.874Z'
                fill='#0E63D8'
              />
              <path
                d='M115.287 736.305C115.287 736.305 115.597 741.573 114.201 745.292C112.804 749.011 123.976 749.631 124.131 749.476C124.286 749.321 129.407 745.292 129.407 745.292C129.407 745.292 127.855 740.644 128.01 735.685'
                fill='#0E63D8'
              />
              <path
                d='M192.714 736.305C192.714 736.305 192.403 741.573 193.8 745.292C195.196 749.011 184.024 749.631 183.869 749.476C183.714 749.321 178.594 745.292 178.594 745.292C178.594 745.292 180.145 740.644 179.99 735.685'
                fill='#0E63D8'
              />
              <path
                d='M132.355 753.349C132.045 749.476 130.493 746.687 129.562 745.292C128.165 744.827 126.148 744.207 123.821 744.052C119.631 743.743 116.218 744.672 114.201 745.292C113.27 746.377 111.253 749.321 110.942 753.659C110.787 756.293 111.408 758.462 111.873 759.702C118.545 759.702 125.062 759.702 131.734 759.702C132.2 758.307 132.665 756.138 132.355 753.349Z'
                fill='#0E63D8'
              />
              <path
                d='M124.133 749.63H119.478C114.978 749.63 111.254 753.349 111.254 757.843V759.702H132.511V757.843C132.356 753.194 128.632 749.63 124.133 749.63Z'
                fill='#0E63D8'
              />
              <path
                d='M175.491 753.349C175.801 749.476 177.353 746.687 178.284 745.292C179.681 744.827 181.698 744.207 184.025 744.052C188.215 743.743 191.628 744.672 193.645 745.292C194.576 746.377 196.593 749.321 196.904 753.659C197.059 756.293 196.438 758.462 195.973 759.702C189.301 759.702 182.784 759.702 176.112 759.702C175.646 758.307 175.181 756.138 175.491 753.349Z'
                fill='#0E63D8'
              />
              <path
                d='M183.715 749.63H188.37C192.869 749.63 196.593 753.349 196.593 757.843V759.702H175.336V757.843C175.491 753.194 179.215 749.63 183.715 749.63Z'
                fill='#0E63D8'
              />
              <path
                d='M118.856 96.8416L109.081 99.1657C104.581 100.25 100.702 103.194 98.6848 107.378C91.2369 122.408 73.5483 161.764 66.1004 206.853C66.1004 206.853 52.446 241.716 48.5669 265.113C47.4808 271.466 51.515 277.509 57.8767 278.904L58.6525 279.058L67.652 266.663L99.9261 213.051L118.856 96.8416Z'
                fill='#001C44'
              />
              <path
                d='M113.581 77.4732C111.254 72.9798 104.116 58.1049 108.306 40.1311C113.426 17.6639 134.218 -0.15492 152.372 2.63158e-05C176.578 0.309919 204.352 32.3838 199.387 77.4732C170.837 77.4732 142.132 77.4732 113.581 77.4732Z'
                fill='#3BFFBF'
              />
              <path
                d='M203.731 114.041C203.731 114.041 206.989 190.584 214.747 228.856C214.747 228.856 160.129 246.83 261.296 250.548C269.21 250.858 275.882 244.505 275.882 236.448C245.004 226.997 281.778 221.883 250.9 212.586C242.366 184.231 238.642 136.663 220.333 108.463L203.731 114.041Z'
                fill='#001C44'
              />
              <path
                d='M229.487 277.509C232.28 242.181 232.435 200.965 226.694 155.411C225.142 142.551 223.125 130.155 220.798 118.379C219.091 109.857 213.04 102.729 204.661 100.095C189.765 95.1371 175.025 90.3338 160.129 85.3755L112.494 98.5459C106.597 124.267 101.167 153.552 97.753 185.936C94.3394 216.925 92.1671 245.28 92.3223 270.846C108.614 275.34 129.717 279.678 152.681 281.383C183.248 283.552 209.471 281.073 229.487 277.509Z'
                fill='#001C44'
              />
              <path
                d='M229.488 277.509C232.281 242.181 232.436 200.965 226.695 155.411C224.678 138.832 228.091 121.478 220.488 108.462C218.937 105.828 216.609 103.969 213.816 103.039C202.955 99.4756 191.938 95.7569 181.077 92.1931C173.784 97.7712 171.146 105.364 177.042 115.9C194.576 146.579 185.266 234.279 178.594 282.312C197.99 282.002 215.368 279.988 229.488 277.509Z'
                fill='#001C44'
              />
              <path
                d='M144.768 79.9524L144.457 53.1467L170.99 51.9071L171.301 81.8118C171.921 87.2349 178.438 91.5734 183.714 93.2778L193.024 96.3767C192.093 104.589 183.559 123.492 175.49 124.732C160.284 126.901 141.199 101.025 132.51 92.9679C138.251 91.4184 144.302 85.8404 144.768 79.9524Z'
                fill='#0E63D8'
              />
              <path
                d='M144.147 24.4816C143.216 25.1013 135.923 30.5245 134.061 39.9762C131.579 52.3719 139.337 69.2611 151.905 70.6556C164.008 72.0501 175.18 58.7247 176.111 46.0191C176.886 34.863 169.904 21.6925 159.663 19.8332C152.526 18.4386 146.164 23.087 144.147 24.4816Z'
                fill='#0E63D8'
              />
              <path
                d='M131.58 14.2551C129.252 20.143 142.751 30.0596 143.993 30.9893C155.475 39.3564 173.008 43.5399 177.508 37.497C180.767 33.0036 176.422 23.7068 171.457 18.4386C159.199 5.57807 134.373 7.43743 131.58 14.2551Z'
                fill='#3BFFBF'
              />
              <path
                d='M182.628 43.6949C182.939 40.4411 180.456 37.652 177.353 37.3421C174.094 37.0322 171.301 39.5114 170.991 42.6103C170.681 45.8642 173.163 48.6532 176.267 48.9631C179.525 49.273 182.473 46.9488 182.628 43.6949Z'
                fill='#00326D'
              />
              <path
                d='M147.096 62.7533C146.941 63.8379 149.113 65.0775 150.82 65.3874C153.923 66.0071 157.337 63.8379 158.888 59.9642C149.578 60.584 147.251 61.8236 147.096 62.7533Z'
                fill='#00326D'
              />
              <path
                d='M144.304 173.695C144.304 162.074 153.769 152.622 165.406 152.622H168.044C179.681 152.622 189.146 162.074 189.146 173.695C189.612 174.005 190.698 174.625 192.094 174.625C193.956 174.78 195.508 174.005 195.973 173.695C195.973 158.355 183.405 145.805 168.044 145.805H165.406C150.045 145.805 137.477 158.355 137.477 173.695C137.942 174.005 139.183 174.625 140.735 174.625C142.597 174.625 143.838 174.005 144.304 173.695Z'
                fill='#653087'
              />
              <path
                d='M258.66 308.808H74.7905C69.8253 308.808 65.791 304.78 65.791 299.821V190.119C65.791 185.161 69.8253 181.132 74.7905 181.132H258.815C263.78 181.132 267.814 185.161 267.814 190.119V299.821C267.814 304.625 263.78 308.808 258.66 308.808Z'
                fill='#A059FF'
              />
              <path
                d='M65.6367 254.577C92.1697 260.465 126.616 265.733 166.959 265.733C206.991 265.733 241.282 260.465 267.815 254.577V180.977H65.6367V254.577Z'
                fill='#00326D'
              />
              <path
                d='M269.055 246.675C242.833 253.027 208.076 258.915 166.958 259.07C125.529 259.07 90.6172 253.182 64.3945 246.675V182.837C64.3945 176.794 69.3598 171.836 75.4112 171.836H258.194C264.245 171.836 269.211 176.794 269.211 182.837V246.675H269.055Z'
                fill='#A059FF'
              />
              <path
                d='M160.595 271.776H174.405C174.715 271.776 175.025 271.466 175.025 271.156V247.139C175.025 246.83 174.715 246.52 174.405 246.52H160.595C160.285 246.52 159.975 246.83 159.975 247.139V271.156C159.975 271.466 160.285 271.776 160.595 271.776Z'
                fill='#3BFFBF'
              />
              <path
                d='M159.044 271.776H172.854C173.164 271.776 173.475 271.466 173.475 271.156V247.139C173.475 246.83 173.164 246.52 172.854 246.52H159.044C158.734 246.52 158.424 246.83 158.424 247.139V271.156C158.424 271.466 158.579 271.776 159.044 271.776Z'
                fill='#3BFFBF'
              />
              <path
                d='M51.6694 270.072C51.2039 275.03 54.6175 279.368 59.4276 280.143C62.0654 280.608 65.3238 280.608 68.5822 279.833C73.5475 278.749 77.4266 278.129 79.2885 274.875C80.5298 272.861 80.9953 269.917 79.9092 267.592C79.2885 266.198 77.5817 264.648 76.1853 263.409C74.1681 261.859 66.8754 259.69 59.2724 263.254C56.1691 264.648 52.1349 266.508 51.6694 270.072Z'
                fill='#0E63D8'
              />
              <path
                d='M56.6348 264.803C64.2378 254.422 67.1859 252.873 68.4272 253.337C70.134 254.112 70.2892 260.155 66.5652 263.564C63.7723 266.043 59.8932 266.508 56.6348 264.803Z'
                fill='#0E63D8'
              />
              <path
                d='M278.21 239.702C273.4 227.771 271.072 225.447 269.831 225.602C267.969 225.912 266.262 231.8 269.055 235.983C271.072 239.082 274.641 240.477 278.21 239.702Z'
                fill='#0E63D8'
              />
              <path
                d='M278.985 243.421C277.899 248.224 273.244 251.478 268.434 250.548C265.796 250.083 262.693 249.154 259.745 247.449C255.4 244.97 251.986 243.111 251.055 239.392C250.59 237.068 250.9 234.124 252.762 232.265C253.848 231.18 255.866 230.095 257.728 229.476C260.055 228.701 267.658 228.856 273.865 234.589C276.347 236.913 279.761 239.857 278.985 243.421Z'
                fill='#0E63D8'
              />
            </g>
            <defs>
              <clipPath id='clip0_18388_3109'>
                <rect width='308' height='780' fill='white' />
              </clipPath>
            </defs>
          </svg>
        </>
      )}
      {isSuccess && <ContactSuccess />}
    </>
  )
}
