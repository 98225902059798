import { BpAnchor } from '../../text/anchor/BpAnchor'
import { useTranslation } from 'react-i18next'

export interface IDetailsCardProps {
  countries: string
  cities: string
  engines: string
  devices: string
  search_screen_url: string
  link_screen_url: string
}

export const DetailsCard = ({
  countries,
  cities,
  engines,
  devices,
  search_screen_url,
  link_screen_url,
}: IDetailsCardProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.detailsCard' })

  return (
    <ul className='flex flex-col'>
      {engines && (
        <li className='flex text-[15px] leading-[24px] gap-[5px]'>
          <span className='text-aqua-300'>{t('searchEngine')}:</span>
          <span className='text-focus-600'>{engines}</span>
        </li>
      )}
      {(search_screen_url || link_screen_url) && (
        <li className='flex text-[15px] leading-[24px] gap-[5px]'>
          <span className='text-aqua-300'>{t('screenshots')}:</span>
          <span className='text-focus-600'>
            {search_screen_url && (
              <BpAnchor className={'whitespace-nowrap'} href={search_screen_url} target='_blank'>
                {t('SERP')}
              </BpAnchor>
            )}
            {search_screen_url && link_screen_url && ', '}
            {link_screen_url && (
              <BpAnchor className={'whitespace-nowrap'} href={link_screen_url} target='_blank'>
                {t('landingPage')}
              </BpAnchor>
            )}
          </span>
        </li>
      )}
      <li className='mt-[8px]'>
        <span className='flex text-[13px] text-aqua-300 leading-[16px] gap-[5px]'>
          {(cities || countries) && (
            <span>
              {cities} {countries}
            </span>
          )}
          |{devices && <span>{devices}</span>}
        </span>
      </li>
    </ul>
  )
}
