import { Controller, useForm } from 'react-hook-form'
import { BpInput } from '../../../../../../shared/components/forms/input/BpInput'
import { ForwardedRef, forwardRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export interface IEditAffiliateFormData {
  name: string
  comment: string
  email: string
}

export interface IEditAffiliateFormParams {
  name: string
  comment: string
  email: string
  onSubmit?: (data: IEditAffiliateFormData) => void
  onDirty?: (isDirty: boolean) => void
}

export const EditAffiliateForm = forwardRef(
  (
    { name, comment, email, onSubmit, onDirty }: IEditAffiliateFormParams,
    ref: ForwardedRef<HTMLFormElement>,
  ) => {
    const { t } = useTranslation()
    const {
      control,
      handleSubmit,
      formState: { isDirty },
    } = useForm<IEditAffiliateFormData>({
      mode: 'onChange',
      defaultValues: {
        name,
        comment,
        email,
      },
    })

    useEffect(() => {
      if (onDirty) onDirty(isDirty)
    }, [isDirty])

    return (
      <form
        ref={ref}
        onSubmit={handleSubmit((data: IEditAffiliateFormData) => {
          if (onSubmit) onSubmit(data)
        })}
        className='flex flex-col gap-[16px]'
      >
        <Controller
          name='name'
          control={control}
          render={({ field }) => (
            <BpInput
              {...field}
              defaultValue={name}
              label={t('fields.affiliateId')}
              placeholder={t('fields.enterYourName')}
            />
          )}
        />
        <Controller
          name='comment'
          control={control}
          render={({ field }) => (
            <BpInput
              {...field}
              defaultValue={comment}
              label={t('fields.comment')}
              placeholder={t('fields.enterYourComment')}
            />
          )}
        />
        <Controller
          name='email'
          control={control}
          render={({ field }) => (
            <BpInput
              {...field}
              defaultValue={email}
              label={t('fields.affiliateEmail')}
              placeholder={t('fields.enterAffiliatesEmail')}
            />
          )}
        />
      </form>
    )
  },
)
