import React, {
  ButtonHTMLAttributes,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from 'react'
import './BpButton.css'
import { IIconProps } from '../../../interfaces/IconProps.interface'
import { Preloader } from '../../cards/preloader/Preloader'

export interface IBpButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  size?: 'sm' | 'md' | 'lg'
  icon?: ReactElement<PropsWithChildren<IIconProps>>
  iconPosition?: 'left' | 'right'
  theme?: 'default' | 'outline'
  children?: ReactNode
  active?: boolean
  loading?: boolean
  contentClassName?: string
}

interface IBpIconProps extends React.HTMLAttributes<HTMLElement> {
  iconPosition?: 'left' | 'right'
}

export const BpButton = forwardRef(
  (props: IBpButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      size = 'md',
      disabled = false,
      icon,
      iconPosition = 'left',
      theme = 'default',
      active = false,
      loading = false,
      className,
      contentClassName,
      ...btnProps
    } = props
    return (
      <button
        ref={ref}
        disabled={disabled}
        className={`group relative  bp-btn ${size} ${theme} ${active && 'active'}
          ${loading && '!bg-[#E8EDF2] pointer-events-none'}
          ${className}
        `}
        {...btnProps}
      >
        <div className={`flex align-middle justify-center gap-[8px] ${loading && 'invisible'}`}>
          {icon && iconPosition == 'left' && <IconElem iconPosition='left'>{icon}</IconElem>}
          {children && (
            <span
              className={`
                transition-colors
                duration-150
                font-medium
                ${
                  theme === 'default'
                    ? 'text-white'
                    : 'text-focus-500 group-hover:text-default group-disabled:text-aqua-300'
                }
                ${contentClassName}`}
            >
              {children}
            </span>
          )}
          {icon && iconPosition == 'right' && <IconElem iconPosition='right'>{icon}</IconElem>}
        </div>
        {loading && (
          <div className={'absolute inset-0 m-auto h-[20px] w-[20px] overflow-hidden'}>
            <Preloader />
          </div>
        )}
      </button>
    )
  },
)
const IconElem = ({ children }: IBpIconProps) => (
  <span className={'flex content-center items-center w-[15px]'}>{children}</span>
)
