import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import queryString from 'query-string'
import { TExportAllAdvertisersParams } from '../advertiser-report/types/ExportAllAdvertisersParams.type'
import { TExportAffiliatesParams } from '../ad-overview/types/ExportAffiliatesParams.type'
import { IExportLastAdParams } from './interfaces/ExportLastAdParams.interface'
import { INonCompliantAffiliatesParams } from '../compliance/interfaces/NonCompliantAffiliatesParams.interface'
import { ICommonExport } from '../../shared/interfaces/CommonExport.interface'

export const exportReportsApi = createApi({
  reducerPath: 'exportReports/api',
  baseQuery: fetchBaseQuery({
    baseUrl: '/export-reports/',
    paramsSerializer: (params) => queryString.stringify(params),
  }),
  endpoints: (build) => ({
    exportFile: build.mutation<Blob, TExportAllAdvertisersParams>({
      query(params: TExportAllAdvertisersParams) {
        return {
          url: 'all-advertisers',
          params: params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    exportAffiliates: build.mutation<Blob, TExportAffiliatesParams>({
      query(params: TExportAffiliatesParams) {
        return {
          url: 'affiliates',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    exportLastAd: build.mutation<Blob, IExportLastAdParams>({
      query(params: IExportLastAdParams) {
        return {
          url: 'affiliate-last-ad',
          params,
          responseHandler: async (response) =>
            window.open(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    exportAffiliateAds: build.mutation<Blob, any>({
      query(params: any) {
        return {
          url: 'affiliate-ads',
          params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
    exportNonCompliantAffiliates: build.mutation<
      Blob,
      INonCompliantAffiliatesParams & ICommonExport
    >({
      query(params: INonCompliantAffiliatesParams & ICommonExport) {
        return {
          url: 'non-compliant-affiliates',
          params: params,
          responseHandler: async (response) =>
            window.location.assign(window.URL.createObjectURL(await response.blob())),
          cache: 'no-cache',
        }
      },
    }),
  }),
})

export const {
  useExportFileMutation,
  useExportAffiliatesMutation,
  useExportLastAdMutation,
  useExportAffiliateAdsMutation,
  useExportNonCompliantAffiliatesMutation,
} = exportReportsApi
