import { IIconProps } from '../../interfaces/IconProps.interface'

export const IconSeoSearch = ({ color = 'white', className }: IIconProps) => {
  return (
    <svg
      className={`w-full ${className}`}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.33464 7.33337H5.33464M6.66797 10H5.33464M10.668 4.66671H5.33464M13.3346 7.00004V4.53337C13.3346 3.41327 13.3346 2.85321 13.1166 2.42539C12.9249 2.04907 12.619 1.74311 12.2426 1.55136C11.8148 1.33337 11.2548 1.33337 10.1346 1.33337H5.86797C4.74786 1.33337 4.18781 1.33337 3.75999 1.55136C3.38366 1.74311 3.0777 2.04907 2.88596 2.42539C2.66797 2.85321 2.66797 3.41327 2.66797 4.53337V11.4667C2.66797 12.5868 2.66797 13.1468 2.88596 13.5747C3.0777 13.951 3.38366 14.257 3.75999 14.4487C4.18781 14.6667 4.74786 14.6667 5.86797 14.6667H7.66797M14.668 14.6667L13.668 13.6667M14.3346 12C14.3346 13.2887 13.29 14.3334 12.0013 14.3334C10.7126 14.3334 9.66797 13.2887 9.66797 12C9.66797 10.7114 10.7126 9.66671 12.0013 9.66671C13.29 9.66671 14.3346 10.7114 14.3346 12Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
